import { Card as MUICard, CardContent, CardHeader, CardProps } from "@mui/material";
import React from "react";
import "./Card.scss";

export interface ICardProps extends CardProps {
  label: string;
}

export const Card: React.FC<ICardProps> = (props) => {
  const { children, label, className: inheritedClass } = props;

  const className = (inheritedClass || "") + " card-root";

  return (
    <MUICard {...props} className={className}>
      <CardHeader className={"u-mobile-hide"} title={label} sx={{ ".MuiCardHeader-title": { fontWeight: 600 } }} />
      <CardContent className="card-content-root">{children}</CardContent>
    </MUICard>
  );
};
