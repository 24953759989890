import { Formik } from "formik";
import * as yup from "yup";
import React from "react";
import { AppState, setDeviceGroup, showAppModal, showSnackbar } from "../../../AppState";
import { FormFieldText } from "../../../Components";
import { createGroup } from "../../../Managers/DeviceGroupService";
import { IDeviceGroup } from "../../../Managers/Types";
import { Modal } from "../../../Components/Modal";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  name: yup.string().max(25),
});

export const AddDeviceGroupModal: React.FC = () => {
  const { t } = useTranslation(["dashboard", "common"]);

  const handleSubmit = (values: any) => {
    const deviceGroup: IDeviceGroup = {
      _id: 0,
      name: values.name,
      active_configuration_id: null,
      createdAt: "",
      updatedAt: "",
      LocationId: AppState.selectedLocationId,
      Device_group_alert_configurations: [],
      Devices: [],
    };

    createGroup(deviceGroup)
      .then((d) => {
        setDeviceGroup(d as IDeviceGroup);
        showAppModal(null);
        showSnackbar(t("dashboard:device_group_modal.save_success"), "success");
      })
      .catch((e) => {
        console.error(e);
        showSnackbar(t("dashboard:device_group_modal.save_error"), "error");
      });
  };

  const initialValues = {
    name: "",
  };

  return (
    <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, isValid, submitForm }) => {
        return (
          <Modal
            className="modal-sm"
            title={t("dashboard:device_group_modal.title")}
            buttons={
              <>
                <button type="button" className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                  {t("common:cancel")}
                </button>
                <button type="button" className="btn btn-primary" onClick={submitForm} disabled={!isValid || isSubmitting}>
                  {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {t("dashboard:device_group_modal.add")}
                </button>
              </>
            }>
            <form>
              <FormFieldText
                className="u-full-width"
                required
                name="name"
                label={t("dashboard:device_group_modal.name")}
                placeholder={t("dashboard:device_group_modal.name_placeholder")}
              />
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
};
