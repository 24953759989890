import { useTranslation } from "react-i18next";

export enum RegistrationSteps {
  AccountInfo,
  EmailVerification,
  SelectPlan,
  Confirmation,
}

export const GetStepName = (step: RegistrationSteps): string => {
  const { t } = useTranslation("register");
  switch (step) {
    case RegistrationSteps.AccountInfo:
      return t("register:steps.account.step_title");
    case RegistrationSteps.EmailVerification:
      return t("register:steps.email_verification.title");
    case RegistrationSteps.SelectPlan:
      return t("register:steps.plan.step_title");
    case RegistrationSteps.Confirmation:
      return t("register:steps.confirmation.step_title");
    default:
      throw new Error("Unsupported step");
  }
};

export const GetActiveSteps = () =>
  [RegistrationSteps.AccountInfo, RegistrationSteps.EmailVerification, RegistrationSteps.SelectPlan, RegistrationSteps.Confirmation].map(
    (step) => ({ key: step, label: GetStepName(step) }),
  );
