import { ISystemMessage } from "../../Managers/Types";
import React from "react";
import { ISelectOption, SelectInput, StyledTooltip } from "../../Components";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";

interface ISystemMessageListMobile {
  systemMessages: Array<ISystemMessage>;
  setSelectedSystemMessage: React.Dispatch<React.SetStateAction<ISystemMessage>>;
  handleAdd: () => void;
  addDisabled: boolean;
}

export const SystemMessageListMobile: React.FC<ISystemMessageListMobile> = ({
  systemMessages,
  setSelectedSystemMessage,
  handleAdd,
  addDisabled,
}) => {
  const { t } = useTranslation(["system_message"]);
  const { values: systemMessage } = useFormikContext<ISystemMessage>();

  const systemMessageTypeOptions: ISelectOption[] = systemMessages.map((msg) => ({
    label: msg.name,
    key: String(msg._id),
    value: String(msg._id),
  }));

  const onChange = (selectedOption: string) => {
    const newMsg = systemMessages.find((msg) => String(msg._id) === selectedOption);

    if (newMsg) {
      setSelectedSystemMessage(newMsg);
    }
  };

  return (
    <Stack direction="row" gap="1rem" alignItems="flex-start">
      <SelectInput
        options={systemMessageTypeOptions}
        displayError={false}
        inputClassName="locations-select-mobile"
        onChange={onChange}
        value={String(systemMessage._id)}
      />

      <StyledTooltip title={addDisabled ? "You are already during creation of a new system message" : ""}>
        <button disabled={addDisabled} type="button" className="btn btn-primary" onClick={handleAdd}>
          {t("system_message:button.add")}
        </button>
      </StyledTooltip>
    </Stack>
  );
};
