import React from "react";
import { observer } from "mobx-react-lite";
import { AppState, showAppModal, showSnackbar } from "../../AppState";
import { cancelSubscription } from "../../Managers";
import { CancellationDetails } from "../../Managers/Types";
import { Formik } from "formik";
import { FormFieldSelect, FormFieldTextArea } from "../../Components";
import { ConfirmModal } from "../index";
import { useTranslation } from "react-i18next";

export const ConfirmEndSubscription: React.FC = observer(() => {
  const { t } = useTranslation("billing_info");
  const handleSubmit = async (values: CancelSubscription) => {
    cancelSubscription(AppState.user?.Account?.stripe_subscription_id, values.comment, values.feedback)
      .then(() => {
        showSnackbar(t("billing_info:end_subscription_confirm.cancel_success"), "success");
        if (AppState.user && AppState.user.Account) {
          AppState.user.Account.cancel_at_period_end = true;
        }
        showAppModal(null);
      })
      .catch(() => {
        showSnackbar(t("billing_info:end_subscription_confirm.cancel_error"), "error");
      });
  };

  const feedbackValues = () => {
    return [
      { value: "customer_service", label: t("billing_info:end_subscription_confirm.reasons.customer_service") },
      { value: "low_quality", label: t("billing_info:end_subscription_confirm.reasons.low_quality") },
      { value: "missing_features", label: t("billing_info:end_subscription_confirm.reasons.missing_features") },
      { value: "other", label: t("billing_info:end_subscription_confirm.reasons.other") },
      { value: "switched_service", label: t("billing_info:end_subscription_confirm.reasons.switched_service") },
      { value: "too_complex", label: t("billing_info:end_subscription_confirm.reasons.too_complex") },
      { value: "too_expensive", label: t("billing_info:end_subscription_confirm.reasons.too_expensive") },
      { value: "unused", label: t("billing_info:end_subscription_confirm.reasons.unused") },
    ];
  };

  return (
    <Formik initialValues={{ comment: "", feedback: "other" }} onSubmit={handleSubmit}>
      {({ submitForm, isSubmitting }) => {
        return (
          <ConfirmModal confirmDisabled={isSubmitting} header={t("billing_info:end_subscription_confirm.header")} onConfirm={submitForm}>
            <>
              {t("billing_info:end_subscription_confirm.end_reasons_question")}
              <br />
              <br />
              <FormFieldTextArea name="comment" label={t("billing_info:end_subscription_confirm.comment")} />
              <FormFieldSelect name="feedback" label={t("billing_info:end_subscription_confirm.reason")} options={feedbackValues()} />
            </>
          </ConfirmModal>
        );
      }}
    </Formik>
  );
});

interface CancelSubscription {
  comment?: string;
  feedback: CancellationDetails.Feedback;
}
