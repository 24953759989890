import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface IModalOptions {
  variant?: "message" | "confirm";
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  title: string;
  message: string | any;
}

export interface INodalDialogProps extends IModalOptions {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const AlertServiceContext = React.createContext<(options: IModalOptions) => Promise<void>>(Promise.reject);

export const AlertServiceProvider: React.FC = ({ children }) => {
  const [AlertState, setAlertState] = useState<IModalOptions | null>(null);

  const openAlert = (options: IModalOptions) => {
    setAlertState(options);
    return Promise.resolve();
  };

  return (
    <>
      <AlertServiceContext.Provider value={openAlert} children={children} />

      <Dialog open={Boolean(AlertState)} {...AlertState} />
    </>
  );
};

export const ModalDialog: React.FC<INodalDialogProps> = ({
  open,
  title,
  color = "primary",
  variant = "message",
  message,
  onOk,
  onCancel,
}) => {
  // The server can send us a lot of different types of errors
  const msg = typeof message === "string" ? message : message?.title || String(message);
  const { t } = useTranslation("common");

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        <Typography color={color} style={{ fontSize: 18, fontWeight: 500 }}>
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{msg}</DialogContentText>
      </DialogContent>

      <DialogActions style={{ flexDirection: "row" }}>
        {variant === "confirm" && (
          <>
            <Button onClick={onCancel} sx={{ color: "#444444" }}>
              {t("common:cancel")}
            </Button>
            <Button color={color} onClick={onOk}>
              {t("common:ok")}
            </Button>
          </>
        )}

        {variant === "message" && (
          <Button color={color} onClick={onOk}>
            {t("common:ok")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
