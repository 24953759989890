import React from "react";

interface RichTextPreviewProps {
  content: string;
}

const RichTextPreview: React.FC<RichTextPreviewProps> = ({ content }) => {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </>
  );
};

export default RichTextPreview;
