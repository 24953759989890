import { Button, PaginationItem } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./PaginationButtons.scss";

type PaginationButtonSize = "large" | "medium" | "small";
export interface IPaginationButtonsProps {
  onPageChange: (p: number) => void;
  pageCount: number;
  page?: number;
  size?: PaginationButtonSize;
}

export const PaginationButtons: React.FC<IPaginationButtonsProps> = ({ onPageChange, pageCount, page = 1, size = "large" }) => {
  const [currentPage, setCurrentPage] = useState(page);

  const { t } = useTranslation("common");

  const handlePageChange = (value: number) => {
    setCurrentPage(value);
    onPageChange(value);
  };
  return (
    <div className="pagination-wrapper">
      <Button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        variant="contained"
        className="pagination-button">
        <i className="fa fa-angle-left angle-icon-pagination" />
        <span className="u-mobile-hide" style={{ marginLeft: "2px" }}>
          {t("common:previous")}
        </span>
      </Button>
      <Pagination
        count={pageCount}
        page={currentPage}
        onChange={(_e, value) => handlePageChange(value)}
        color="primary"
        size={size as PaginationButtonSize}
        hidePrevButton
        hideNextButton
        renderItem={(item) => (
          <PaginationItem
            {...item}
            className={classNames("page-button", {
              "non-active-page": item.page !== currentPage,
              "active-page": item.page === currentPage,
            })}
          />
        )}
      />
      <Button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === pageCount}
        variant="contained"
        className="pagination-button">
        <span className="u-mobile-hide" style={{ marginRight: "2px" }}>
          {t("common:next")}
        </span>
        <i className="fa fa-angle-right angle-icon-pagination" />
      </Button>
    </div>
  );
};
