import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Stack } from "@mui/material";
import { uploadDeviceFoodProbeFirmware, uploadDeviceSensorNodeFirmware } from "../../Managers/API";
import { FirmwareUploadForm } from "./FirmwareUploadForm";

export const DeviceFirmwareUpload: React.FC = observer(() => {
  return (
    <Box sx={{ width: "100%" }}>
      <FirmwareUploadForm
        formTitle="firmware_upload:device_sn.form_title"
        uploadFirmwareAsync={uploadDeviceSensorNodeFirmware}
        submitButtonText="firmware_upload:device_sn.submit_button"
      />
      <Stack mt={5}>
        <FirmwareUploadForm
          formTitle="firmware_upload:device_fp.form_title"
          uploadFirmwareAsync={uploadDeviceFoodProbeFirmware}
          submitButtonText="firmware_upload:device_fp.submit_button"
        />
      </Stack>
    </Box>
  );
});
