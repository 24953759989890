import * as yup from "yup";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FormFieldSelect, FormFieldText } from "../../../Components";
import { AppState, showAppModal, showSnackbar } from "../../../AppState";
import { ISensor, IWatchlist } from "../../../Managers/Types";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../Components/Modal";
import { createWatchlist, editWatchlist } from "../../../Managers/WatchlistsService";
import "./AddEditWatchlistModal.scss";
import { AddToWatchlistModal } from "./AddToWatchlistModal";
import { useSensorTypes } from "../../../Managers/AlertService";

interface IEditWatchlistProps {
  watchlist?: IWatchlist | null;
  mode: "new" | "edit";
  sensor?: ISensor | null;
}

const validationSchema = yup.object({
  name: yup.string().required().min(3).max(255),
  unit: yup.string().required(),
  SensorTypeId: yup.number().nullable().required("SensorTypeId is required"),
  UserId: yup.string().required(),
});

export const EditWatchlistModal: React.FC<IEditWatchlistProps> = observer(({ watchlist, sensor, mode }) => {
  const { t } = useTranslation(["dashboard", "common"]);
  const sensorTypes = useSensorTypes();
  const [unitOptions, setUnitOptions] = useState<{ value: string; label: string }[]>([]);

  const sensorTypesOptions = sensorTypes?.data?.map((option) => ({ value: option._id, label: option.name }));

  const defaultSensorOption = sensorTypesOptions?.find((type) => type.label === "Temperature") || sensorTypesOptions?.[0];

  const defaultSensorType =
    (defaultSensorOption &&
      sensorTypes.data?.find((sensorType) => sensorType._id === watchlist?.SensorTypeId ?? defaultSensorOption.value)) ||
    sensorTypes.data?.[0];
  const defaultUnit = defaultSensorType && defaultSensorType.units.find((unit) => unit === watchlist?.unit);

  const initialValues = {
    name: watchlist?.name || "",
    UserId: AppState.user?._id || 0,
    unit: defaultUnit || "",
    SensorTypeId: watchlist?.SensorTypeId || defaultSensorOption?.value || undefined,
  } as IWatchlist;

  useEffect(() => {
    if (sensorTypes?.data) {
      if (initialValues.Sensor_type) {
        setUnitOptions(initialValues.Sensor_type.units.map((option) => ({ value: option, label: option })));
      }
    }
  }, [sensorTypes?.data, initialValues.Sensor_type]);

  const handleAddWatchlist = async (values: IWatchlist) => {
    if (watchlist == null) {
      const watchlist: IWatchlist = {
        ...values,
        UserId: AppState.user?._id,
      };

      try {
        try {
          const r = await createWatchlist(watchlist);
          showSnackbar(t("dashboard:edit_watchlist_modal.create_success"), "success");
          console.log("Create result", r);
        } catch (e) {
          showSnackbar(t("dashboard:edit_watchlist_modal.create_error"), "error");
          console.log("Create error", e);
        }
      } finally {
        sensor ? showAppModal(<AddToWatchlistModal sensor={sensor} />) : showAppModal(null);
      }
    } else {
      values._id = watchlist._id;
      try {
        try {
          const r_1 = await editWatchlist(values);
          showSnackbar(t("dashboard:edit_watchlist_modal.edit_success"), "success");
          console.log("Update result", r_1);
        } catch (e_1) {
          showSnackbar(t("dashboard:edit_watchlist_modal.edit_error"), "error");
          console.log("Update error", e_1);
        }
      } finally {
        return showAppModal(null);
      }
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleAddWatchlist}>
      {({ isSubmitting, submitForm, setFieldValue, setFieldTouched, values, touched, errors }) => {
        const handleSensorTypeChange = (selectedSensorTypeId: number) => {
          const selectedSensorType = sensorTypes?.data?.find((type) => type._id === selectedSensorTypeId);
          if (selectedSensorType) {
            setFieldValue("Sensor_type", selectedSensorType);
            setFieldTouched("Sensor_type");
            setUnitOptions(selectedSensorType.units.map((unit) => ({ value: unit, label: unit })));
            setFieldValue("unit", defaultUnit);
            setFieldTouched("unit");
          } else {
            setUnitOptions([]);
            setFieldValue("unit", "");
            setFieldTouched("unit");
          }
        };

        useEffect(() => {
          if (!initialValues.Sensor_type && defaultSensorType) {
            setFieldValue("sensor_type", defaultSensorType._id);
            setFieldValue("unit", defaultUnit);
            setFieldTouched("Sensor_type");
            setFieldTouched("unit");
            setUnitOptions(defaultSensorType.units.map((unit) => ({ value: unit, label: unit })));
          }
        }, [setFieldValue, defaultSensorType]);

        return (
          <Modal
            className="modal-sm watchlist-modal"
            title={t(`dashboard:edit_watchlist_modal.title_${mode}`)}
            buttons={
              <>
                <button
                  type="button"
                  className="btn btn-info"
                  disabled={isSubmitting}
                  onClick={() => (sensor ? showAppModal(<AddToWatchlistModal sensor={sensor} />) : showAppModal(null))}>
                  {t("common:cancel")}
                </button>

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={isSubmitting}>
                  {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {t("common:save")}
                </button>
              </>
            }>
            <>
              <FormFieldText
                name="name"
                label={t("dashboard:edit_watchlist_modal.name")}
                placeholder={t("dashboard:edit_watchlist_modal.name_placeholder")}
                onChange={(e) => {
                  setFieldValue("name", e.target.value);
                  setFieldTouched("name", true);
                }}
                required
              />
              <div className="row">
                <div className="col-xs-6">
                  <FormFieldSelect
                    style={{ margin: 0 }}
                    className="input-filter-holder"
                    options={sensorTypesOptions || []}
                    name="SensorTypeId"
                    onChange={handleSensorTypeChange}
                    label={t("dashboard:edit_watchlist_modal.sensor_type")}
                  />
                </div>
                <div className="col-xs-6">
                  <FormFieldSelect
                    style={{ margin: 0 }}
                    className="input-filter-holder"
                    options={unitOptions}
                    name="unit"
                    label={t("dashboard:edit_watchlist_modal.unit")}
                  />
                </div>
              </div>
            </>
          </Modal>
        );
      }}
    </Formik>
  );
});
