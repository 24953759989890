import React from "react";
import { observer } from "mobx-react-lite";
import { showAppModal } from "../AppState";
import { Modal } from "../Components/Modal";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface IConfirmModalProps {
  header: string;
  children: React.ReactNode | string;
  confirmDisabled?: boolean;
  confirmText?: string;
  confirmButtonType?: string;
  onCancel?: () => void;
  onConfirm: () => void;
}

export const ConfirmModal: React.FC<IConfirmModalProps> = observer(
  ({ header, children, confirmButtonType = "btn-primary", confirmText, onCancel, onConfirm, confirmDisabled = false }) => {
    const { t } = useTranslation("common");

    return (
      <Modal
        className="modal-sm"
        title={header}
        buttons={
          <>
            <button
              className={classNames("btn", "btn-info")}
              onClick={() => {
                onCancel ? onCancel() : showAppModal(null);
              }}>
              {t("common:cancel")}
            </button>

            <button disabled={confirmDisabled} className={classNames("btn", confirmButtonType ?? "btn-primary")} onClick={onConfirm}>
              {confirmText ?? t("common:confirm")}
            </button>
          </>
        }>
        {children}
      </Modal>
    );
  },
);
