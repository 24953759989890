import React from "react";
import styled from "@emotion/styled";
import classNames from "classnames";
import { Colors } from "../Theme";

interface DropdownMenuProps {
  customStyles?: React.CSSProperties;
}

export const DropdownMenu = styled.div<DropdownMenuProps>`
  &.open {
    right: 0;
    left: auto;
  }

  &.open > * > .dropdown-menu.open,
  &.open > .dropdown-menu.open {
    display: block;
    //min-width: 13.57143em;
    //top: calc(100% + 0.8em);
  }

  right: ${({ customStyles }) => customStyles?.right || "0"};
  left: ${({ customStyles }) => customStyles?.left || "auto"};
  position: absolute;
  top: 100%;
  z-index: 1000;
  //display: none;
  float: left;
  min-width: 190px;
  padding: ${({ customStyles }) => customStyles?.padding || "5px 0"};
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #001321;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  width: ${({ customStyles }) => customStyles?.width || "unset"};

  &::after {
    content: ${({ customStyles }) => customStyles?.content || ""};
    width: 0;
    height: 0;
    border-bottom: 14px solid #001321;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    top: -14px;
    right: 1em;
  }
`;

export const SectionTitle = styled.p`
  padding: 6px 15px;
  color: #00d1a4;
  text-transform: uppercase;
  font-size: 0.85714em;
  margin: 0;
  text-align: left;
  list-style: none;
`;

export const InfoMenuItem = styled.div`
  padding: 6px 30px;
  display: flex;
  flex: 1;
`;

export const MenuItem = styled.button`
  border: none;
  outline: none;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 6px 30px;
  display: flex;
  flex: 1;
  position: relative;
  cursor: pointer;
  background: transparent;

  &:hover {
    background-color: ${Colors.cardBlue};
  }
`;

export const MenuButton: React.FC<{
  className?: string;
  disabled?: boolean;
  label: string;
  onClick: () => void;
  selected?: boolean;
}> = ({ disabled, label, onClick, className = "", selected = false }) => {
  return (
    <MenuItem disabled={disabled} onClick={onClick} className={classNames("dropdown-menu-item", className, selected ? "selected" : "")}>
      {selected ? <i className="fa fa-check u-color-teal" /> : null}
      <span>{label}</span>
    </MenuItem>
  );
};
