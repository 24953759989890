import React from "react";
import { useTranslation } from "react-i18next";
import { deletePreset, usePresets } from "../../Managers";
import { showAppModal, showSnackbar } from "../../AppState";
import { List, ListItem } from "../../Components";
import { TrashIcon } from "../../icon/trash";
import classNames from "classnames";
import { IExportPreset } from "../../Managers/Types";
import { ConfirmModal } from "../../Modals";
import { DeviceReportModal } from "./DeviceReportModal";

export const PresetPicker: React.FC<{
  handleSelectPreset: (preset?: IExportPreset, edit?: boolean) => void;
  preset?: IExportPreset;
}> = ({ preset, handleSelectPreset }) => {
  const { t } = useTranslation(["common", "export"]);
  const presets = usePresets();

  const handleDelete = (presetToDelete: IExportPreset) => {
    if (presetToDelete._id) {
      deletePreset(presetToDelete._id)
        .then(() => {
          if (presetToDelete._id === preset?._id) {
            handleSelectPreset(undefined);
          }
          showSnackbar(t("export:delete_preset_success"), "success");
          showAppModal(<DeviceReportModal />);
        })
        .catch(() => showSnackbar(t("export:delete_preset_error"), "error"));
    }
  };

  const handleDeleteClick = (preset: IExportPreset) => {
    showAppModal(
      <ConfirmModal
        header={t("export:delete_preset")}
        onCancel={() => showAppModal(<DeviceReportModal />)}
        onConfirm={() => handleDelete(preset)}>
        {t("export:delete_preset_content", { presetName: preset.name })}
      </ConfirmModal>,
    );
  };

  const selectPreset = (p: IExportPreset) => {
    const isSelect = p._id !== preset?._id;
    handleSelectPreset(isSelect ? p : undefined);
  };

  return (
    <div>
      <span>{t("export:load_preset")}</span>
      <List className="select-group">
        {(presets.data ?? []).map((p) => (
          <ListItem
            key={p._id}
            className={classNames("select-group-item", { selected: p._id === preset?._id })}
            onClick={() => selectPreset(p)}>
            <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>{p.name}</div>
              <div>
                <button
                  className={classNames("btn btn-plain", { "u-text-teal": p._id !== preset?._id })}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectPreset(p, true);
                  }}>
                  <i className="fa fa-pencil" />
                </button>
                <button
                  onClick={() => handleDeleteClick(p)}
                  className={classNames("btn btn-plain", { "u-text-teal": p._id !== preset?._id })}>
                  <TrashIcon />
                </button>
              </div>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
