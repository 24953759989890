import styled from "@emotion/styled";
import React from "react";

export const Jumbotron = styled.div`
  width: 577px;
  flex: 0 0 420px;
  background-image: radial-gradient(circle at 50% 0, #1565b0, #02386b);
  box-shadow: 0 1px 2px 1px rgb(0 0 0 / 30%);
  border: 1px solid #002038;
  margin: 10vh auto 0;
  padding: 20px 15px 30px;
  font-size: 12px;
`;

export const JumbotronHeader = styled(({ className, imgWidth }: { className?: any; imgWidth?: number }) => (
  <div className={className}>
    <img alt="" src={"/wam-logo.png"} className={"login-logo"} />
  </div>
))`
  padding: 0 1px 49px 0;
  text-align: center;
  margin: 30px 0 0;
`;
