import React from "react";
import { ErrorMessage, useField } from "formik";
import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import classNames from "classnames";
import { IStandardInput } from "./Types";
import { Colors } from "../Theme";

export interface IFormFieldToggle extends Omit<IStandardInput, "onChange"> {
  checked: boolean;
  displayError?: boolean;
  activeLabel: string;
  inactiveLabel?: string;
  onChange: (value: boolean) => void;
}

export interface IToggleInput extends IFormFieldToggle {
  labelClassName?: string;
  setTouched?: () => void;
}

export const ToggleInput = styled((props: IToggleInput) => {
  const {
    activeLabel,
    checked,
    className,
    disabled,
    displayError,
    inactiveLabel,
    labelClassName = "",
    label,
    name,
    readOnly,
    onChange,
    setTouched,
  } = props;
  const id = `${name}_${Math.random().toString(36).substring(2, 9)}`;

  return (
    <div className={className}>
      {label ? (
        <label
          htmlFor={id}
          className={classNames("input-label", {
            readonly: readOnly,
            labelClassName,
          })}>
          {label}
        </label>
      ) : null}
      <ToggleButtonGroup
        className="MuiToggleInput-root"
        size="small"
        exclusive={true}
        color="primary"
        disabled={disabled}
        id={id}
        value={checked}
        onChange={(_e, value) => {
          if (value !== null || !inactiveLabel || !activeLabel) {
            setTouched && setTouched();
            onChange && onChange(value);
          }
        }}>
        {inactiveLabel ? (
          <ToggleButton value={false} aria-label="centered">
            {inactiveLabel}
          </ToggleButton>
        ) : null}
        <ToggleButton value={true} aria-label="centered">
          {activeLabel}
        </ToggleButton>
      </ToggleButtonGroup>
      {displayError ? (
        <div className="toggle-input-error">
          <ErrorMessage name={name} />
        </div>
      ) : null}
    </div>
  );
})(() => ({
  ".MuiToggleButton-root": {
    fontSize: "0.8em",
    "&.MuiToggleButton-primary": {
      borderColor: Colors.lightGray,
      color: Colors.lightGray,

      "&.Mui-selected": {
        backgroundColor: Colors.teal + "66",
        borderColor: Colors.teal,
        color: "#cffced",
      },
    },
  },
}));

export const FormFieldToggle = (props: IToggleInput) => {
  const [, meta] = useField(props.name);

  return <ToggleInput {...props} setTouched={() => (meta.touched = true)} className={meta.touched && Boolean(meta.error) ? "error" : ""} />;
};
