import { Endpoint, invalidateQuery } from "./API";
import { useQuery } from "react-query";
import { LegalType } from "../Enums";
import { ILegal } from "./Types";

const API_PATH = "api/legal";
export const QK_LEGAL = "LEGAL";

export const getAllLegals = () => Endpoint.get<ILegal[]>(API_PATH).then((r) => r.data);

export const getLegal = (country: string, type: LegalType) =>
  Endpoint.get<ILegal>(`${API_PATH}-open/${type}/${country}`).then((r) => r.data);

export const useLegals = () => useQuery(QK_LEGAL, getAllLegals, { placeholderData: [] });
export const useLegal = (country: string, type: LegalType) => useQuery(QK_LEGAL + country + type, () => getLegal(country, type));

export const createLegal = (legal: ILegal) =>
  Endpoint.post(`${API_PATH}`, legal).then((r) => {
    invalidateQuery(QK_LEGAL + legal.country + legal.type);
    return r.data;
  });

export const updateLegal = (legal: ILegal) => {
  const { type, country, ...rest } = legal;
  return Endpoint.patch(`${API_PATH}/${type}/${country}`, rest).then((r) => {
    invalidateQuery(QK_LEGAL + legal.country + legal.type);
    return r.data;
  });
};
