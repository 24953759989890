import React, { useEffect, useState } from "react";
import { Page, RangePicker, SelectInput, useScreenMode, Wrapper } from "../../Components";
import { IAlertHistoryFilters, IDevice, IGateway, ILocation, INotification } from "../../Managers/Types";
import {Checkbox, CircularProgress, Drawer, MenuItem} from "@mui/material";
import "./AlertsHistory.scss";
import { getNameSlug, myOrder } from "../../Managers";
import { useDevicesWithProps } from "../../Managers/DeviceService";
import moment from "moment/moment";
import { isBatteryLowAlert, useSensorTypes } from "../../Managers/AlertService";
import { useNotificationHistory } from "../../Managers/NotificationService";
import { useTranslation } from "react-i18next";
import { PaginationButtons } from "../../Components/PaginationButtons";
import {CloseIcon, FiltersIcon, LeftRailToggleIcon} from "../../icon";
import { AlertHistoryLeftRail } from "./AlertHistoryLeftRail";
import { OrderBy, WindowSize } from "../../Enums";
import { AlertsHistoryTable } from "./AlertHistoryTable";
import { AlertHistoryMobileFiltersModal } from "./modals/AlertHistoryMobileFiltersModal";
import classNames from "classnames";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {AppState} from "../../AppState";

export const AlertsHistory: React.FC = () => {
  const { t } = useTranslation(["alert_history", "sensor_types", "common"]);

  const [searchString, setSearchString] = useState("");
  const [deviceOrder, setDeviceOrder] = useState<OrderBy>(OrderBy.ASC);
  const [startDate, setStartDate] = useState<Date>(moment().subtract(1, "weeks").toDate());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [tabletAlertsHistoryListOpen, setTabletAlertsHistoryListOpen] = useState<boolean>(true);
  const initialFilters: IAlertHistoryFilters = {
    type: "all",
    sensorTypeId: undefined,
    show: 20,
    sortBy: "added",
    startDate: null,
    endDate: null,
  };

  const [selectedDevice, setSelectedDevice] = useState<IDevice | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<IAlertHistoryFilters>({ ...initialFilters });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0);
  const [initialNotifications, setInitialNotifications] = useState<Array<INotification>>([]);
  const [sortedNotifications, setSortedNotifications] = useState<Array<INotification>>([]);
  const [paginatedNotifications, setPaginatedNotifications] = useState<Array<INotification>>([]);
  const [selectedGateways, setSelectedGateways] = useState<IGateway[]>([]);
  const [location, setLocation] = useState<ILocation>({ name: t("common:all_locations") } as ILocation);
  const [sortedDevices, setSortedDevices] = useState<IDevice[]>([]);
  const [showMobileFiltersModal, setShowMobileFiltersModal] = useState(false);

  const devices = useDevicesWithProps(["_id", "name", "serial_number", "is_active", "GatewayId"], true);

  const sensorTypes = useSensorTypes();
  const mode = useScreenMode();

  const notificationQuery = useNotificationHistory(
    moment(startDate).startOf("day").toISOString(true),
    moment(endDate).endOf("day").toISOString(true),
    true,
  );

  useEffect(() => {
    if (notificationQuery.data) {
      setInitialNotifications(notificationQuery.data);
      updateSortedNotifications(notificationQuery.data.slice());
    }
  }, [notificationQuery.dataUpdatedAt]);

  useEffect(() => {
    notificationQuery.refetch();
  }, [startDate, endDate]);

  useEffect(() => {
    updatePaginatedNotifications();
  }, [currentPage, sortedNotifications, selectedFilters.show]);

  const updatePaginatedNotifications = () => {
    const startIndex = (currentPage - 1) * selectedFilters.show;
    const endIndex = Math.min(startIndex + selectedFilters.show, sortedNotifications.length);
    const newPaginatedNotifications = sortedNotifications.slice(startIndex, endIndex);
    setPaginatedNotifications(newPaginatedNotifications);
    const pageCount = Math.ceil(sortedNotifications.length / selectedFilters.show);
    setTotalPages(pageCount);
    const filteredNotifications = newPaginatedNotifications.slice(0, selectedFilters.show);
    setPaginatedNotifications(filteredNotifications);
  };

  useEffect(() => {
    setCurrentPage(1);
    setSortedNotifications(initialNotifications);
    setTotalPages(Math.ceil(initialNotifications.length / selectedFilters.show));
  }, [initialNotifications]);

  useEffect(() => {
    const filtersChanged = Object.keys(selectedFilters).some(
      (key) => selectedFilters[key as keyof IAlertHistoryFilters] !== initialFilters[key as keyof IAlertHistoryFilters],
    );

    if (filtersChanged) {
      console.log("updated selected filters", selectedFilters);
      updateSortedNotifications();
      setCurrentPage(1);
    } else {
      console.log('filters doesnt change')
    }
  }, [selectedFilters]);

  useEffect(() => {
    setCurrentPage(1);
    updateSortedNotifications();
  }, [selectedFilters, startDate, endDate, selectedDevice]);

  useEffect(() => {
    filterDevicesAndNotifications();
  }, [deviceOrder, devices.dataUpdatedAt]);

  const changeLocation = (loc: ILocation) => {
    setSelectedGateways(loc.Gateways ?? []);
    setSelectedDevice(null);
    setSearchString("");
    setLocation(loc);
    filterDevicesAndNotifications(loc.Gateways);
  };

  const selectGateway = (gatewaysToSelect: IGateway[]) => {
    setSelectedGateways(gatewaysToSelect);
    filterDevicesAndNotifications(gatewaysToSelect);
  };

  const filterDevicesAndNotifications = (gateways?: IGateway[]) => {
    if (devices) {
      let filteredDevices = myOrder(devices.data ?? [], "name", deviceOrder);

      if (gateways) {
        filteredDevices = filteredDevices.filter((d) => gateways.some((g) => g._id === d.GatewayId));
      }

      setSortedDevices(filteredDevices);

      const ids = filteredDevices.map((d) => d._id);

      setSortedNotifications(initialNotifications.filter((n) => ids.some((id) => id === n.Sensor?.DeviceId)));
    }
  };
  const updateSortedNotifications = (notifications = initialNotifications) => {
    const isTypeValid = selectedFilters.type !== "";
    const isSensorTypeValid = historySensorTypes.filter(s => s.selected && s.value > -1).length;
    const isShowValid = selectedFilters.show !== undefined && selectedFilters.show > 0;
    const isSortByValid = selectedFilters.sortBy !== "";
    const isStartDateValid = selectedFilters.startDate !== null;
    const isEndDateValid = selectedFilters.endDate !== null;

    setActiveFiltersCount(0);

    if (isTypeValid || isSensorTypeValid || isShowValid || isSortByValid || isStartDateValid || isEndDateValid) {
      let filteredNotifications = [...notifications];
      if (isSortByValid) {
        setActiveFiltersCount((prev) => prev + 1);
        switch (selectedFilters.sortBy) {
          case "added":
            filteredNotifications.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
            break;
          case "asc":
            filteredNotifications.sort((a, b) => {
              const valueA = parseFloat(a.value.value);
              const valueB = parseFloat(b.value.value);
              return valueA - valueB;
            });
            break;
          case "desc":
            filteredNotifications.sort((a, b) => {
              const valueA = parseFloat(a.value.value);
              const valueB = parseFloat(b.value.value);
              return valueB - valueA;
            });
            break;
          default:
            break;
        }
      }

      if (isSensorTypeValid) {
        setActiveFiltersCount((prev) => prev + 1);

        if (!historySensorTypes.find(i => i.value === -1)?.selected) {
          filteredNotifications = filteredNotifications.filter((notification) => {
            return historySensorTypes.filter(v => v.selected).map(v => v.value).includes(notification.Sensor?.Sensor_type._id ?? 0);
          });
        }

      }

      let currentFilteredNotifications: INotification[] = [];
      if (isTypeValid) {
        setActiveFiltersCount((prev) => prev + 1);
        const showAllTypes = historyTypes.find(t => t.key === 'all')?.selected;
        if (!showAllTypes) {
          if (historyTypes.find(t => t.value === 'transmitting')?.selected) {
            currentFilteredNotifications.push(...filteredNotifications.filter((notification) => {
              return notification.Alert?.is_notify_on_non_transmitting;
            }));
          } else if (historyTypes.find(t => t.value === 'battery_alert')?.selected) {
            currentFilteredNotifications.push(...filteredNotifications.filter((notification) => {
              return isBatteryLowAlert(notification.Alert);
            }));
          } else if (historyTypes.find(t => t.value === 'range')?.selected) {
            currentFilteredNotifications.push(...filteredNotifications.filter((notification) => {
              return notification.Alert?.max !== null && notification.Alert?.min !== null;
            }));
          } else if (historyTypes.find(t => t.value === 'max')?.selected) {
            currentFilteredNotifications.push(...filteredNotifications.filter((notification) => {
              return notification.Alert?.max !== null && notification.Alert?.min === null;
            }));
          } else if (historyTypes.find(t => t.value === 'min')?.selected) {
            currentFilteredNotifications.push(...filteredNotifications.filter((notification) => {
              return notification.Alert?.min !== null && notification.Alert?.max === null;
            }));
          } else if (historyTypes.find(t => t.value === 'boolean')?.selected) {
            currentFilteredNotifications.push(...filteredNotifications.filter((notification) => {
              return (
                  notification.Sensor?.Sensor_type?.type.toLowerCase() === "boolean" &&
                  notification.Alert?.min !== null &&
                  notification.Alert?.max !== null
              );
            }));
          }
          filteredNotifications = filteredNotifications.filter(n => currentFilteredNotifications.map(n => n._id).includes(n._id));
        }
      }

      if (selectedDevice) {
        filteredNotifications = filteredNotifications.filter((notification) => {
          return notification.Sensor?.DeviceId === selectedDevice._id;
        });
      }

      setSortedNotifications(filteredNotifications);
      updatePaginatedNotifications();
    }
  };
  const [filtersOpen, setFiltersOpen] = useState(false);

  const [historyTypes, setHistoryTypes] = useState([
    { value: "all", key: "all", selected: true },
    { value: "range", key: "range", selected: false },
    { value: "max", key: "max", selected: false },
    { value: "min", key: "min", selected: false },
    { value: "boolean", key: "boolean", selected: false },
    { value: "battery_alert", key: "battery_alert", selected: false },
    { value: "transmitting", key: "not_transmitting", selected: false },
  ])
  const [historySensorTypes, setHistorySensorTypes] = useState([
    { value: -1, label: t("alert_history:all"), key: undefined, selected: true },
    ...(sensorTypes.data || []).map((type) => ({
      value: type._id,
      label: t(`sensor_types:${getNameSlug(type.name)}`),
      key: type._id.toString(),
      selected: false
    })),
  ])

  useEffect(() => {
    setHistorySensorTypes([
      { value: -1, label: t("alert_history:all"), key: undefined, selected: true },
      ...(sensorTypes.data || []).map((type) => ({
        value: type._id,
        label: t(`sensor_types:${getNameSlug(type.name)}`),
        key: type._id.toString(),
        selected: false
      })),
    ])
  }, [sensorTypes.data]);
  if (notificationQuery.isLoading) {
    return (
      <Page>
        <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Wrapper>
      </Page>
    );
  }


  const changeHistoryTypeFilter = (selectedType: string) => {
    let newTypes = [...historyTypes];
    let selType = newTypes.findIndex(i => i.key === selectedType);
    newTypes[selType].selected = !newTypes[selType].selected
    setHistoryTypes(newTypes)
    updateSortedNotifications();
    setCurrentPage(1);
  }
  const changeHistoryDeviceTypeFilter = (selectedType: number|undefined) => {
    let newTypes = [...historySensorTypes];
    let selType = newTypes.findIndex(i => i.value === selectedType);
    newTypes[selType].selected = !newTypes[selType].selected
    setHistorySensorTypes(newTypes)
    updateSortedNotifications();
    setCurrentPage(1);
  }

  const filters = () => {
    return (
      <div className={classNames("header-row-section filter-container", filtersOpen ? "extended" : "")}>
        <div className="filter-header u-mobile-hide">
          <p>{t("dashboard:filters")}</p>
          <CloseIcon className="close-icon" onClick={() => (setFiltersOpen ? setFiltersOpen(false) : "")} />
        </div>

        <Accordion key={'history-type'} className="filter-accordion">
          <AccordionSummary
              id={'history-type'}
              className="filter-accordion-header"
              aria-controls={'history-type'}
              expandIcon={<i className="fa fa-chevron-down expand-icon" />}>
            {t("alert_history:type")}
          </AccordionSummary>
          <AccordionDetails key={'history-type'}>
            {historyTypes.map((item, i) => <MenuItem
                onClick={() => changeHistoryTypeFilter(item.key)}
                className={"filter-option"}
                key={i + item.value + item.value}
                value={item.value}>
              <Checkbox
                  checked={item.selected}
                  onChange={() => changeHistoryTypeFilter(item.key)}
              />
              {t("alert_history:sensor_type", { context: item.key })}
            </MenuItem>)}
          </AccordionDetails>
        </Accordion>

        {sensorTypes.data  && <Accordion key={'history-sensor-type'} className="filter-accordion">
          <AccordionSummary
              id={'history-sensor-type'}
              className="filter-accordion-header"
              aria-controls={'history-sensor-type'}
              expandIcon={<i className="fa fa-chevron-down expand-icon" />}>
            {t("alert_history:sensor_type")}
          </AccordionSummary>
          <AccordionDetails key={'history-sensor-type'}>
            {historySensorTypes.map((item, i) => <MenuItem
                onClick={() => changeHistoryDeviceTypeFilter(item.value)}
                className={"filter-option"}
                key={i + item.value + item.label}
                value={item.value}>
              <Checkbox
                  checked={item.selected}
                  onChange={() => changeHistoryDeviceTypeFilter(item.value)}
              />
              {item.label}
            </MenuItem>)}
          </AccordionDetails>
        </Accordion>}

        <div className="dropdown-filters" style={{    marginRight: 'unset', width: '100%'}}>
          <SelectInput
              required={false}
              displayError={false}
              className="form-group input-select form-group input-select vertical vertical"
              label={t("alert_history:show")}
              onChange={(selectedOption) => {
                setSelectedFilters((prevFilters) => ({
                  ...prevFilters,
                  show: selectedOption,
                }));
              }}
              options={[
                { value: 10, label: "10", key: "show-10" },
                { value: 20, label: "20", key: "show-20" },
                { value: 30, label: "50", key: "show-50" },
                { value: 100, label: "100", key: "show-100" },
              ]}
              value={selectedFilters.show}
          />
          <SelectInput
              required={false}
              displayError={false}
              className="form-group input-select form-group input-select vertical vertical"
              label={t("common:sort_by")}
              onChange={(selectedOption) => {
                setSelectedFilters((prevFilters) => ({
                  ...prevFilters,
                  sortBy: selectedOption,
                }));
              }}
              options={[
                { value: "added", label: t("alert_history:last_added"), key: "last-added" },
                {
                  value: "desc",
                  label: t("alert_history:alert_value", { context: "desc" }),
                  key: "alert-value-desc",
                },
                { value: "asc", label: t("alert_history:alert_value", { context: "asc" }), key: "alert-value-asc" },
              ]}
              value={selectedFilters.sortBy}
          />
        </div>
      </div>
    );
  };

  return (
    <Page>
      {
        <Wrapper>
          {mode !== WindowSize.MOBILE ? (
            <Drawer
              style={{ zIndex: 0 }}
              variant={mode === WindowSize.DESKTOP ? "permanent" : "temporary"}
              hideBackdrop={mode === WindowSize.DESKTOP}
              onBackdropClick={() => mode === WindowSize.TABLET && setTabletAlertsHistoryListOpen(false)}
              open={tabletAlertsHistoryListOpen || mode === WindowSize.DESKTOP}>
              <AlertHistoryLeftRail
                setSelectedDevice={setSelectedDevice}
                devices={sortedDevices}
                searchString={searchString}
                setSearchString={setSearchString}
                setDeviceOrder={setDeviceOrder}
                deviceOrder={deviceOrder}
                selectedDevice={selectedDevice}
                changeLocation={changeLocation}
                location={location}
                selectedGateways={selectedGateways}
                selectGateways={selectGateway}
              />
            </Drawer>
          ) : null}

          <main className="bapi-main mod-dashboard alerts-history-main">
            <div className="filters-wrapper u-tablet-hide" style={{ justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <span style={{ margin: "auto 24px auto 50px" }}>{selectedDevice?.name || t("alert_history:no_selected_device")}</span>
                <RangePicker
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "40px",
                  }}
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  startDate={startDate}
                />
              </div>

              {filters()}

              <div className="u-flex-center">
                {
                  <div
                    className="filters-mobile-icon"
                    onClick={() => {
                      setFiltersOpen(!filtersOpen);
                    }}>
                    <button className={classNames("btn btn-icon", filtersOpen ? "active" : "")}>
                      <FiltersIcon />
                    </button>
                    <p className="filter-label">{t("dashboard:filters")}</p>
                  </div>
                }
              </div>
            </div>
            <div className="filters-wrapper-mobile-tablet u-desktop-hide">
              <div className="device-name">
                <div className="left-rail-toggle-wrapper alerts-left-rail-icon-wrapper u-tablet-only">
                  <button className="btn btn-icon" onClick={() => setTabletAlertsHistoryListOpen(!tabletAlertsHistoryListOpen)}>
                    <LeftRailToggleIcon />
                  </button>
                </div>
                {selectedDevice?.name || t("alert_history:no_selected_device")}
              </div>

              <div className="filters-mobile-icon" onClick={() => setShowMobileFiltersModal(true)}>
                <button className="btn btn-icon">
                  <FiltersIcon />
                </button>
                <div className="mobile-active-filters-count">({activeFiltersCount})</div>
              </div>
            </div>
            <AlertsHistoryTable onDelete={() => notificationQuery.refetch()} sortedNotifications={paginatedNotifications} />
            {paginatedNotifications.length > 0 && (
              <div className="text-right pagination-text-showing">
                {t("alert_history:shown_alerts", {
                  from: (currentPage - 1) * selectedFilters.show + 1,
                  to: Math.min(currentPage * selectedFilters.show, sortedNotifications.length),
                })}
              </div>
            )}

            {paginatedNotifications?.length > 0 ? <PaginationButtons onPageChange={setCurrentPage} pageCount={totalPages} /> : null}
          </main>

          {showMobileFiltersModal && mode === WindowSize.MOBILE ? (
            <AlertHistoryMobileFiltersModal
              selectedGateways={selectedGateways}
              selectGateways={selectGateway}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setFilters={setSelectedFilters}
              filters={selectedFilters}
              setSearchString={setSearchString}
              searchString={searchString}
              devices={sortedDevices}
              setSelectedDevice={setSelectedDevice}
              changeLocation={changeLocation}
              location={location}
              close={() => setShowMobileFiltersModal(false)}
            />
          ) : null}
        </Wrapper>
      }
    </Page>
  );
};
