import React, { useState } from "react";
import "./EmailVerificationStep.scss";
import { DigitsInput, IFormStepProps, StepperActions } from "../../Components";
import { ResendCode, VerifyEmail } from "../../Managers/TemporaryUserService";
import { Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IEmailVerificationStepProps extends IFormStepProps {
  codeLength: number;
  emailAddress: string;
  verified: boolean;
}

export const EmailVerificationStep: React.FC<IEmailVerificationStepProps> = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [valid, setValid] = useState(props.verified);
  const [error, setError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { t } = useTranslation("register");

  const completeDigitsInput = (code: string) => {
    if (code.length === 6) {
      setDisabled(true);
      setError(false);
      VerifyEmail({ email: props.emailAddress, code })
        .then(() => {
          setDisabled(false);
          setValid(true);
        })
        .catch(() => {
          setDisabled(false);
          setError(true);
        });
    }
  };

  const resendCode = () => {
    setDisabled(true);
    setSnackbarOpen(true);
    ResendCode({
      email: props.emailAddress ?? "",
    }).finally(() => setDisabled(false));
  };

  const handleSubmit = () => {
    if (valid) {
      props.onSubmit();
    } else {
      setError(true);
    }
  };

  return (
    <div className="email-verification-step">
      <div className="code-container">
        <div className="type-large-regular register-form-label">{t("register:steps.email_verification.title")}</div>
        {valid ? (
          <div className="email-verified-message">{t("register:steps.email_verification.success")}</div>
        ) : (
          <>
            <div>
              {t("steps.email_verification.sent_email_info", {
                length: props.codeLength,
                address: props.emailAddress,
              })}
            </div>

            <DigitsInput
              disabled={disabled}
              error={error}
              codeLength={props.codeLength}
              onDigitsChange={completeDigitsInput}
              errorMessage={t("register:steps.email_verification.wrong_code")}
            />

            <div>
              {t("register:steps.email_verification.code_retrieval_info")}
              <span className="resend-code-button" onClick={resendCode}>
                {t("register:steps.email_verification.resend")}
              </span>
            </div>
          </>
        )}
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={t("register:steps.email_verification.code_sent")}
      />
      <StepperActions onClickBack={props.onClickBack} onClickNext={handleSubmit} />
    </div>
  );
};
