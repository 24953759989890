import React from "react";
import { observer } from "mobx-react-lite";

export const SearchRow: React.FC<{
  value: string;
  onChange: (value: string) => void;
  open: boolean;
  onClose: () => void;
  className?: string;
}> = observer(({ value, open, onClose, onChange, className }) => {
  return open ? (
    <div style={{ padding: "0 16px 16px", display: "flex", gap: 16 }} className={className}>
      <div className="input-holder" style={{ flex: 1 }}>
        <input type="text" className="input input-default" value={value} onChange={(e) => onChange(e.target.value)} />
        <i className="fa fa-search input-holder-icon" />
      </div>
      <button
        className="btn u-text-teal btn-icon"
        onClick={() => {
          onClose();
          onChange("");
        }}>
        <i className="fa fa-times" />
      </button>
    </div>
  ) : null;
});
