import { ISystemMessage } from "../../Managers/Types";
import React from "react";
import { useScreenMode } from "../../Components";
import { WindowSize } from "../../Enums";
import { SystemMessageListMobile } from "./SystemMessageListMobile";
import { SystemMessageList } from "./SystemMessageList";
import { useFormikContext } from "formik";

interface ISystemMessageList {
  systemMessages: Array<ISystemMessage>;
  setSelectedSystemMessage: React.Dispatch<React.SetStateAction<ISystemMessage>>;
  handleAdd: () => void;
}

export const SystemMessageListController: React.FC<ISystemMessageList> = ({ systemMessages, setSelectedSystemMessage, handleAdd }) => {
  const mode = useScreenMode();

  const { values: systemMessage } = useFormikContext<ISystemMessage>();

  const addDisabled = !systemMessage._id;

  if (mode === WindowSize.MOBILE) {
    return (
      <SystemMessageListMobile
        systemMessages={systemMessages}
        setSelectedSystemMessage={setSelectedSystemMessage}
        handleAdd={handleAdd}
        addDisabled={addDisabled}
      />
    );
  }

  return (
    <SystemMessageList
      systemMessages={systemMessages}
      setSelectedSystemMessage={setSelectedSystemMessage}
      handleAdd={handleAdd}
      addDisabled={addDisabled}
    />
  );
};
