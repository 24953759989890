import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import "./HeaderRow.scss";
import { useTranslation } from "react-i18next";

export interface IHeaderRowProps {
  info: { name: string | React.ReactNode; count?: number; is_offline?: boolean | null; width?: string };
  infoControl?: JSX.Element;
  infoSub?: JSX.Element;
  mainRight?: JSX.Element;
  control?: JSX.Element;
  fullWidth?: boolean;
  infoLeftSideControl?: JSX.Element;
  className?: string;
}

export const HeaderRow: React.FC<IHeaderRowProps> = observer(
  ({ info, infoControl, infoSub, mainRight, control, fullWidth = true, infoLeftSideControl, className }) => {
    const { t } = useTranslation("common");
    return (
      <header className={classNames("header-row", className || "")}>
        <section className={classNames("header-row-section", { "full-width": fullWidth })}>
          <div style={{ width: info.width }}>
            <div className="header-row-info">
              {infoLeftSideControl}

              <h1 className={classNames("header-row-title", { offline: info.is_offline })}>{info.name}</h1>
              {info.count !== undefined ? <span className="type-small-regular u-color-light-blue">({info.count}) </span> : <></>}

              {infoControl}
            </div>
            {info.is_offline ? <div className="subtext">- {t("common:offline")} -</div> : <></>}
            {infoSub}
          </div>

          {mainRight}
        </section>

        {control}
      </header>
    );
  },
);
