import { Endpoint, invalidateQuery } from "./API";
import { SubscriptionItem, SubscriptionOption } from "./Types";
import { useQuery } from "react-query";

const PATH = "api/plans";
const QK_SUBSCRIPTION_ITEMS = ["SUBSCRIPTION_ITEMS"];

export const getPlans = () => Endpoint.get<Array<SubscriptionItem>>(PATH).then((r) => r.data);

export interface ISubscriptionItemSaveRequest extends Omit<SubscriptionItem, "SubscriptionOptions"> {}

export const saveItem = (item: ISubscriptionItemSaveRequest) => Endpoint.put(`${PATH}/${item.id}`, item);
export const updateOption = (planId: number, option: SubscriptionOption) =>
  Endpoint.put(`${PATH}/${planId}/options/${option.id}`, option).then((r) => {
    invalidateQuery(QK_SUBSCRIPTION_ITEMS);
    return r.data;
  });
export const createOption = (planId: number, option: SubscriptionOption) =>
  Endpoint.post(`${PATH}/${planId}/options`, option).then((r) => {
    invalidateQuery(QK_SUBSCRIPTION_ITEMS);
    return r.data;
  });
export const useSubscriptionItems = () => useQuery(QK_SUBSCRIPTION_ITEMS, getPlans);
