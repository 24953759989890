import {Endpoint, invalidateQuery} from './API';
import {IAlert} from './Types';

// TODO: Invalidate DEVICES query
export const addAlertsToDevice = (deviceId: number, alerts: any[]) =>
  Endpoint.post(`api/devices/${deviceId}/alerts`, alerts).then((r) => {
    invalidateQuery(['DEVICES', deviceId]);
    return r.data;
  });

// TODO: Invalidate SENSORS query
// export const addAlertsToSensor = (sensor: ISensor, alerts: any[]) =>
//   Endpoint.post(`api/sensors/${sensor._id}/alerts`, alerts).then((r) => r.data);

export const deleteDeviceAlerts = (deviceId: number) =>
  Endpoint.delete(`api/devices/${deviceId}/alerts`).then((r) => {
    invalidateQuery(['DEVICES', deviceId]);
    return r.data;
  });

export const deleteSensorAlerts = (deviceId: number, sensorId: number) =>
  Endpoint.delete(`api/sensors/${sensorId}/alerts`).then((r) => {
    invalidateQuery(["DEVICES", deviceId]);
    return r.data;
  });

export const deleteAlert = (sensorId: number, alertId: number, deviceId?: number) =>
  Endpoint.delete(`api/sensors/${sensorId}/alerts/${alertId}`).then((r) => {
    deviceId && invalidateQuery(['DEVICES', deviceId]);
    return r.data;
  });

export const updateAlert = (alerts: IAlert[]) =>
  Endpoint.put(`api/sensors/${alerts[0].Sensor_alerts.SensorId}/alerts`, alerts).then((r) => r.data);
