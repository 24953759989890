import { UpdatePlanModal } from "../UpdatePlanModal";
import { Modal } from "../../Components/Modal";
import { Tab, TabContext, TabList, TabPanel } from "../../Components";
import React, { useState } from "react";
import { deleteUser, useUsers } from "../../Managers/UsersService";
import { IDevice, ISubscription, IUser, IPlanProps } from "../../Managers/Types";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import { AppState, setSelectedLocation, showAppModal, showSnackbar } from "../../AppState";
import { removeDevice, useDevicesWithProps } from "../../Managers/DeviceService";
import { getAccountSubscription } from "../../Managers";
import { CalculatePrice } from "../../Managers/PaymentOptionsService";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

const USER_TAB = "users";
const DEVICE_TAB = "devices";

export const DeleteEntriesModal: React.FC<{
  props: IPlanProps;
  devicesToDelete?: number;
  usersToDelete?: number;
}> = ({ props, devicesToDelete = 0, usersToDelete = 0 }) => {
  const [tab, setTab] = useState(usersToDelete ? USER_TAB : DEVICE_TAB);
  const [selectedDevices, setSelectedDevices] = useState<IDevice[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
  const [displayDeviceError, setDisplayDeviceError] = useState(false);
  const [displayUserError, setDisplayUserError] = useState(false);

  const users = useUsers();
  const devices = useDevicesWithProps(["_id", "name", "serial_number"]);

  const { t } = useTranslation(["billing_info", "common"]);

  if (!users.data || !devices.data) {
    return (
      <Modal title={t("billing_info:remove_excessive_entries.title")}>
        <CircularProgress />
      </Modal>
    );
  }

  const toggleUser = (user: IUser) => {
    const userSelectedIndex = selectedUsers.findIndex((sUser) => sUser._id === user._id);
    let selectedUsersCopy = [...selectedUsers];
    if (userSelectedIndex > -1) {
      selectedUsersCopy.splice(userSelectedIndex, 1);
    } else {
      selectedUsersCopy.push(user);
    }

    if (displayUserError && selectedUsersCopy.length >= usersToDelete) {
      setDisplayUserError(false);
    }

    setSelectedUsers(selectedUsersCopy);
  };

  const toggleDevice = (device: IDevice) => {
    const deviceSelectedIndex = selectedDevices.findIndex((sDevice) => sDevice._id === device._id);
    let selectedDevicesCopy = [...selectedDevices];
    if (deviceSelectedIndex > -1) {
      selectedDevicesCopy.splice(deviceSelectedIndex, 1);
    } else {
      selectedDevicesCopy.push(device);
    }

    if (displayDeviceError && selectedDevicesCopy.length >= devicesToDelete) {
      setDisplayDeviceError(false);
    }

    setSelectedDevices(selectedDevicesCopy);
  };

  const calculatePrice = (values: IPlanProps) =>
    CalculatePrice(values, AppState.user?.country_code ?? "US", null ?? "USD").then((value) => {
      return value;
    });

  const removeEntries = () => {
    const notEnoughDevicesSelected = selectedDevices.length < devicesToDelete;
    const notEnoughUsersSelected = selectedUsers.length < usersToDelete;

    if (notEnoughDevicesSelected || notEnoughUsersSelected) {
      setDisplayUserError(notEnoughUsersSelected);
      setDisplayDeviceError(notEnoughDevicesSelected);
      return;
    }

    const promises = [...selectedUsers.map((user) => deleteUser(user._id)), ...selectedDevices.map((device) => removeDevice(device))];

    Promise.allSettled(promises)
      .then(() => {
        if (AppState.selectedLocation) {
          setSelectedLocation(AppState.selectedLocation);
        }
        showSnackbar(t("billing_info:remove_excessive_entries.remove_success"), "success");
        getAccountSubscription(AppState.user?.AccountId ?? 0).then((data) => goBackToUpdatePlan(data.subscription));
      })
      .catch(() => showSnackbar(t("billing_info:remove_excessive_entries.remove_error"), "error"));
  };

  const goBackToUpdatePlan = (accountSubscription?: ISubscription[]) =>
    showAppModal(
      <UpdatePlanModal
        storedProps={props}
        currentDevicesCount={accountSubscription?.find((sub) => sub.plan === "numberOfDevices")?.used ?? devices.data?.length}
        currentUsersCount={accountSubscription?.find((sub) => sub.plan === "numberOfUsers")?.used ?? users.data?.length}
        calculateTotal={calculatePrice}
      />,
    );

  return (
    <Modal
      title={t("billing_info:remove_excessive_entries.title")}
      buttons={
        <>
          <button className="btn btn-secondary" onClick={() => goBackToUpdatePlan()}>
            {t("common:cancel")}
          </button>
          <button className="btn btn-primary" onClick={removeEntries}>
            {t("common:delete")}
          </button>
        </>
      }>
      <TabContext value={tab}>
        <TabList onChange={(_e, val) => setTab(val)}>
          <Tab
            label={<span className={displayUserError ? "u-text-error" : ""}>{t("billing_info:remove_excessive_entries.users")}</span>}
            className="col-sm-6"
            value={USER_TAB}
          />
          <Tab
            label={<span className={displayDeviceError ? "u-text-error" : ""}>{t("billing_info:remove_excessive_entries.devices")}</span>}
            className="col-sm-6"
            value={DEVICE_TAB}
          />
        </TabList>
        <TabPanel value={USER_TAB}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t("billing_info:remove_excessive_entries.first_name")}</TableCell>
                <TableCell>{t("billing_info:remove_excessive_entries.last_name")}</TableCell>
                <TableCell>{t("billing_info:remove_excessive_entries.email")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.data
                .filter((user) => user.AccountId === AppState.user?.AccountId)
                .map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>
                      <label className="checkbox" title="">
                        <input
                          disabled={user._id === AppState.user?._id}
                          checked={selectedUsers.some((sUser) => sUser._id === user._id)}
                          type="checkbox"
                          className="input-checkbox"
                          id={"" + user._id}
                          onChange={() => toggleUser(user)}
                        />
                        <span />
                      </label>
                    </TableCell>
                    <TableCell>{user.first_name}</TableCell>
                    <TableCell>{user.last_name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div style={{ textAlign: "end", marginTop: "1em" }}>
            {t("billing_info:remove_excessive_entries.selected_users_info", {
              count: selectedUsers.length,
              usersToDelete,
            })}
          </div>
        </TabPanel>
        <TabPanel value={DEVICE_TAB}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t("billing_info:remove_excessive_entries.name")}</TableCell>
                <TableCell>{t("billing_info:remove_excessive_entries.serial_number")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices?.data.map((device) => (
                <TableRow key={device._id}>
                  <TableCell>
                    <label className="checkbox" title="">
                      <input
                        checked={selectedDevices.some((sDevice) => sDevice._id === device._id)}
                        type="checkbox"
                        className="input-checkbox"
                        id={"" + device._id}
                        onChange={() => toggleDevice(device)}
                      />
                      <span />
                    </label>
                  </TableCell>
                  <TableCell>{device.name}</TableCell>
                  <TableCell>{device.serial_number}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div style={{ textAlign: "end", marginTop: "1em" }}>
            {t("billing_info:remove_excessive_entries.selected_devices_info", {
              count: selectedDevices.length,
              devicesToDelete,
            })}
          </div>
        </TabPanel>
      </TabContext>
    </Modal>
  );
};
