import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { AppState, showAppModal } from "../AppState";
import { isUserRoleAllowed, UserRoles } from "../Enums";
import { CurrenciesModal, ManageHelpModal } from "../Modals";
import { DefaultAlertValues } from "../Views/Alerts/DefaultAlertValues/DefaultAlertValues";
import { URLS } from "../Views/URLS";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ManageSystemMessageModal } from "../Modals/ManageSystemMessageModal/ManageSystemMessageModal";
import { CountriesModal } from "../Modals/CountriesModal";

export const ManagementMenu: React.FC = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "management_menu"]);
    const [showUploadSubmenu, setShowUploadSubmenu] = useState(false);

  const menuItems: Array<{
    action?: () => void;
    title: string;
    hide?: boolean;
    open?: boolean;
    subMenuItems?: Array<{ action?: () => void; title: string }>;
  }> = [
    {
      title: t("common:accounts"),
      action: () => navigate({ pathname: URLS.Accounts }),
    },
    {
      title: t("common:subscription_management"),
      action: () => navigate({ pathname: URLS.SubscriptionManagement }),
    },
    {
      title: t("management_menu:system_messages"),
      action: () => showAppModal(<ManageSystemMessageModal />),
      hide: !isUserRoleAllowed(UserRoles.APP_ADMIN),
    },
    {
      title: t("management_menu:default_alarm_values"),
      action: () => showAppModal(<DefaultAlertValues />),
    },
    {
      title: t("management_menu:manage_help_tabs"),
      action: () => showAppModal(<ManageHelpModal />),
    },
    {
      title: t("management_menu:manage_translations"),
      action: () => navigate(URLS.Translations),
    },
    {
      title: t("management_menu:manage_currencies"),
      action: () => showAppModal(<CurrenciesModal />),
    },
    {
      title: t("management_menu:manage_countries"),
      action: () => showAppModal(<CountriesModal />),
    },
    {
      title: t("management_menu:manage_legals"),
      action: () =>
        navigate({
          pathname: URLS.LegalManagement,
          search: createSearchParams({
            country: AppState.user?.country_code ?? "US",
          }).toString(),
        }),
    },
    {
      title: t("management_menu:manage_firmware_upload"),
      action: () => setShowUploadSubmenu(!showUploadSubmenu),
      open: showUploadSubmenu,
      subMenuItems: [
        {
          title: t("management_menu:gateway_firmware_upload"),
          action: () => navigate({
            pathname: URLS.FirmwareUpload,
            search: createSearchParams({
              type: 'gateway',
            }).toString(),
          }),
        },
        {
          title: t("management_menu:device_firmware_upload"),
          action: () => navigate({
            pathname: URLS.FirmwareUpload,
            search: createSearchParams({
              type: 'device',
            }).toString(),
          }),
        },
      ],
    },

  ];

  return (
    <section role="menu" className="dropdown-menu dropdown-menu-right">
      <ul style={{ paddingLeft: 0 }}>
        {menuItems.map((item) =>
          item.hide ? null : (
            <React.Fragment key={item.title}>
              <li
                role="menuitem"
                className={`dropdown-menu-item ${item.action ? "" : "non-click"} ${
                  item.subMenuItems ? "dropdown-menu-item-expandable" : ""
                }`}
                onClick={item.action}>
                {item.title}
                {item.subMenuItems && (
                  <i
                    className={classNames("fa fa-chevron-down", { opened: item.open })}
                    style={{ marginLeft: "10px", transition: "transform 0.3s" }}
                  />
                )}
              </li>
              {item.subMenuItems && item.open && (
                <ul className="dropdown-menu-submenu">
                  {item.subMenuItems.map((subItem) => (
                    <li
                      key={subItem.title}
                      role="menuitem"
                      className={`dropdown-menu-submenu-item ${subItem.action ? "" : "non-click"}`}
                      onClick={subItem.action}>
                      {subItem.title}
                    </li>
                  ))}
                </ul>
              )}
            </React.Fragment>
          ),
        )}
      </ul>
    </section>
  );
});
