import React, { useEffect } from "react";
import { AppState, showAppModal } from "../AppState";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { BillingInfo } from "../Modals";
import { URLS } from "./URLS";
import { ModalOverlay } from "../Components";

export const SubscriptionExpired: React.FC = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!AppState.isAuthenticated) {
      showAppModal(null);
      navigate(URLS.Login, { replace: true });
    }

    if (AppState.user?.has_active_subscription) {
      navigate(URLS.Dashboard, { replace: true });
    }

    if (!AppState.appModal) {
      showAppModal(<BillingInfo expiredMode={true} />);
    }
  }, [navigate]);

  return AppState.appModal ? <ModalOverlay>{AppState.appModal}</ModalOverlay> : <></>;
});
