import React from "react";
import { useField, useFormikContext } from "formik";
import classNames from "classnames";
import { InputLabel } from "./InputLabel";
import { IStandardInput } from "./Types";
import { useTranslation } from "react-i18next";
import "./FileInput.scss";

export interface IExtendedInputProps extends IStandardInput {
  labelClassName?: string;
  setTouched?: () => void;
  hasError?: string;
  fileName?: string;
  accept?: string;
}

export const FileInput: React.FC<IExtendedInputProps> = ({
  accept,
  className = "",
  name = "",
  label,
  disabled,
  onChange,
  required,
  setTouched,
  labelClassName,
  hasError,
  displayError,
  fileName,
}) => {
  const { t } = useTranslation("common");
  return (
    <div className={classNames("form-group", className)}>
      {label ? <InputLabel name={name} label={label} required={required} /> : null}
      <div className={classNames("input-holder mod-block file-input", labelClassName)}>
        <input
          hidden
          id={name}
          disabled={disabled}
          required={required}
          type="file"
          onChange={(e) => {
            setTouched && setTouched();
            onChange?.(e.target.files?.[0]);
          }}
          className={"input input-line"}
          name={name}
          accept={accept}
        />
        <label className={classNames("file-input-label", labelClassName)} htmlFor={name}>
          {!fileName ? (
            <div>
              <i className="fa fa-cloud-upload" aria-hidden="true" />
              <span>{t("common:choose_file")}</span>
            </div>
          ) : (
            <div>
              <span>{fileName}</span>
              <button
                className="btn btn-circle"
                style={{ zIndex: "20" }}
                onClick={(e) => {
                  e.preventDefault();
                  onChange?.(undefined);
                }}>
                <i className="fa fa-close" />
              </button>
            </div>
          )}
          {displayError ? <span className="u-color-alert">{hasError ?? ""}</span> : null}
        </label>
      </div>
    </div>
  );
};

export const FormFieldFile: React.FC<IStandardInput & { accept?: string }> = (props) => {
  const [field, meta] = useField(props.name ?? "");
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <FileInput
      {...props}
      onChange={(value) => {
        props.onChange && props.onChange(value);
        return setFieldValue(props.name, value);
      }}
      fileName={field.value?.name}
      hasError={meta.error}
      setTouched={() => setFieldTouched(props.name, true)}
      labelClassName={meta.touched && Boolean(meta.error) ? "error" : ""}
    />
  );
};
