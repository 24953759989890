import React, { useState } from "react";
import { CheckboxInput } from "../../Components";
import { useTranslation } from "react-i18next";
import { ReportMode } from "./ReportModalState";

export interface IReportOptions {
  include_graph?: boolean;
  include_alert_history?: boolean;
  include_alert_protocol?: boolean;
  include_alert_notes?: boolean;
}

interface IReportOptionsProps {
  options: IReportOptions;
  changeOptions: (options: IReportOptions) => void;
  mode: ReportMode;
}

export const ReportOptions: React.FC<IReportOptionsProps> = ({ options, changeOptions, mode }) => {
  const [selectedOptions, setSelectedOptions] = useState(options);
  const { t } = useTranslation("export");

  const handleSelect = (prop: keyof IReportOptions) => {
    const newOptions = { ...selectedOptions, [prop]: !selectedOptions[prop] };

    if ((prop === "include_alert_protocol" || prop === "include_alert_notes") && newOptions[prop] && !newOptions.include_alert_history) {
      newOptions.include_alert_history = true;
    } else if (prop === "include_alert_history" && !newOptions[prop]) {
      newOptions.include_alert_notes = false;
      newOptions.include_alert_protocol = false;
    }

    setSelectedOptions(newOptions);
    changeOptions(newOptions);
  };

  return (
    <div>
      <label className="input-label">{t("export:additional_options")}</label>
      {(["include_alert_history", "include_alert_protocol", "include_alert_notes", "include_graph"] as Array<keyof IReportOptions>).map(
        (prop) => (
          <div key={prop}>
            <CheckboxInput
              label={t(`export:${prop.slice(8)}`)}
              onChange={() => handleSelect(prop)}
              checked={options[prop] ?? false}
              name={prop}
            />
          </div>
        ),
      )}
    </div>
  );
};
