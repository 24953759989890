import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { ReportMode } from "./ReportModalState";

interface IReportTypePickerProps {
  mode?: ReportMode;
  changeType: (type: ReportMode) => void;
  presetEdit?: boolean;
}

export const ReportTypePicker: React.FC<IReportTypePickerProps> = ({ mode, changeType, presetEdit = false }) => {
  const { t } = useTranslation("export");
  const modes = Object.values(ReportMode);

  return (
    <FormControl>
      <RadioGroup value={mode ?? modes[0]} onChange={(e) => changeType(e.target.value as ReportMode)}>
        {modes.map((m) => (
          <FormControlLabel
            value={m}
            control={<Radio disabled={presetEdit ? m === ReportMode.TEMPLATE : false} />}
            label={t("export:type", { context: m.toLowerCase() })}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
