import React from "react";
import { observer } from "mobx-react-lite";
import { ILineChartValue, ISetPoint, TimeSeriesLineChart } from "../../Components";
import { useTranslation } from "react-i18next";

interface IGraphController {
  data: ILineChartValue[];
  isLoading: boolean;
  atLeastOneSensorAvailable: boolean;
  setPoints: ISetPoint[];
}

export const GraphController: React.FC<IGraphController> = observer(({ data, isLoading, atLeastOneSensorAvailable, setPoints }) => {
  const { t } = useTranslation(["watchlists"]);

  const noDataAvailable = <div className="watchlist-no-graph-data">{t("watchlists:no_data_available")}</div>;

  if (!atLeastOneSensorAvailable) {
    return noDataAvailable;
  }

  if (isLoading) {
    return (
      <div className="watchlist-graph-loader-wrapper">
        <div className="watchlist-loader">
          <i className="fa fa-spin fa-circle-o-notch" />
        </div>
      </div>
    );
  }

  if (data.length > 0) {
    return <TimeSeriesLineChart className="watchlist-graph" data={data} setPoints={setPoints} />;
  }

  return noDataAvailable;
});
