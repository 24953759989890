import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Stack } from "@mui/material";
import { uploadGatewayBleFirmware, uploadGatewayUcFirmware } from "../../Managers/API";
import { FirmwareUploadForm } from "./FirmwareUploadForm";

export const GatewayFirmwareUpload: React.FC = observer(() => {
  return (
    <Box sx={{ width: "100%" }}>
      <FirmwareUploadForm
        formTitle="firmware_upload:gateway_uc.form_title"
        uploadFirmwareAsync={uploadGatewayUcFirmware}
        submitButtonText="firmware_upload:gateway_uc.submit_button"
      />
      <Stack mt={5}>
        <FirmwareUploadForm
          formTitle="firmware_upload:gateway_ble.form_title"
          uploadFirmwareAsync={uploadGatewayBleFirmware}
          submitButtonText="firmware_upload:gateway_ble.submit_button"
        />
      </Stack>
    </Box>
  );
});
