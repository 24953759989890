import React from "react";
import { useTranslation } from "react-i18next";
import { INotification } from "../../../Managers/Types";

interface INotesStep {
  notification: INotification;
  alertNotes: string;
  setAlertNotes: React.Dispatch<React.SetStateAction<string>>;
}

export const NotesStep: React.FC<INotesStep> = ({ notification, alertNotes, setAlertNotes }) => {
  const { t } = useTranslation(["alert_resolve", "alerts"]);

  return (
    <>
      <div>
        <label className="input-label" htmlFor="protocol-info">
          {t("alert_resolve:alert_protocol")}:
        </label>
        <textarea name="protocol-info" rows={8} className=" input-textarea disabled input">
          {notification.Alert.protocol || "No protocol provided."}
        </textarea>
      </div>
      <div>
        <label htmlFor="notes" className="input-label">
          {t("alert_resolve:notes")}:
        </label>
        <textarea
          name="notes"
          rows={8}
          cols={80}
          className="input input-textarea"
          placeholder={t("alert_resolve:notes_placeholder")}
          value={alertNotes}
          onChange={(e) => setAlertNotes(e.target.value)}
        />
      </div>
    </>
  );
};
