import React from 'react';
import styled from '@emotion/styled';

const BaseMenuItem: React.FC<{type?: string; label?: string; className?: any; onClick: any}> = ({type, label, children, ...props}) => {
  return (
    <li {...props} aria-label={label || ''}>
      {children}
    </li>
  );
};

export const _menuitem = styled(BaseMenuItem)`
  //margin: 10px;
`;
