import { AppState } from "../../AppState";
import { LinkDeviceToGateway, WelcomeInstructions } from "./Widgets";
import classNames from "classnames";
import React from "react";
import { observer } from "mobx-react-lite";
import { IDevice, IDeviceGroup, IGateway } from "../../Managers/Types";
import { DashboardContent } from "./DashboardContent";
import { useTranslation } from "react-i18next";
import { DeviceSortEnum, OrderBy } from "../../Enums";

interface IDashboardContentController {
  gateways: IGateway[];
  mode: "gateways" | "groups";
  deviceList: IDevice[];
  searchString: string;
  selectedGroup: IDeviceGroup | null | undefined;
  forceRefreshDevices: (newSortDirection?: OrderBy, newSortProperty?: DeviceSortEnum) => void;
  loadMoreDevices: () => void;
  showLoadButton: boolean;
}

export const DashboardContentController: React.FC<IDashboardContentController> = observer(
  ({ gateways, mode, deviceList, searchString, selectedGroup, forceRefreshDevices, loadMoreDevices, showLoadButton }) => {
    const { t } = useTranslation(["dashboard"]);

    if (AppState.selectedLocationId === -1) {
      return <i className="fa fa-spin fa-spinner" />;
    }

    if (!gateways.length && mode === "gateways") {
      return <WelcomeInstructions />;
    }

    if (mode === "groups" && !AppState.selectedLocation?.Device_groups.length) {
      return <div className={classNames("dashboard-content setup-container main-panel extended")}>{t("dashboard:no_groups")}</div>;
    }

    if (gateways.length && !deviceList.length && !selectedGroup && mode === "gateways") {
      return <LinkDeviceToGateway />;
    }

    return (
      <DashboardContent
        deviceList={deviceList}
        searchString={searchString}
        forceRefreshDevices={forceRefreshDevices}
        loadMoreDevices={loadMoreDevices}
        showLoadButton={showLoadButton}
      />
    );
  },
);
