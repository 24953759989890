import { Endpoint } from "./API";
import { ISystemMessage } from "./Types";

const API_PATH = "api/system_messages";
export const QK_SYSTEM_MESSAGES = ["SYSTEM_MESSAGES"];

export const getAllSystemMessages = () => Endpoint.get<ISystemMessage[]>(`${API_PATH}/`).then((r) => r.data);

export const updateSystemMessage = (systemMessage: ISystemMessage) =>
  Endpoint.put<ISystemMessage>(`${API_PATH}/${systemMessage._id}`, systemMessage).then((r) => r.data);

export const createSystemMessage = (systemMessage: ISystemMessage) =>
  Endpoint.post<ISystemMessage>(`${API_PATH}/`, systemMessage).then((r) => r.data);

export const deleteSystemMessage = (id: number) => Endpoint.delete(`${API_PATH}/${id}`).then((r) => r.data);

export const sendNotificationEmails = (id: number) => Endpoint.get(`${API_PATH}/${id}/sendEmails`).then((r) => r.data);
export const resetSystemMessageClosedFlag = (id: number) => Endpoint.put(`${API_PATH}/${id}/reset`).then((r) => r.data);
export const closeNotification = (id: number) => Endpoint.put(`${API_PATH}/${id}/close`).then((r) => r.data);
