import { measurementTransform } from "../../../Managers/MeasurementService";
import { unitsTransform } from "../../../Managers/UnitsService";
import { getDisplayAlertName, isBatteryLowAlert } from "../../../Managers/AlertService";
import { alertConditionTransform } from "../../../Managers/AlertConditionService";
import { formatDateCustom } from "../../../Managers";
import { AppState, getUserDateFormat } from "../../../AppState";
import React from "react";
import { INotification } from "../../../Managers/Types";
import { useTranslation } from "react-i18next";
import { useUsers } from "../../../Managers/UsersService";

interface IAsideInfo {
  notification: INotification;
}

export const AsideInfo: React.FC<IAsideInfo> = ({ notification }) => {
  const { t } = useTranslation(["alert_resolve"]);

  const usersQuery = useUsers();

  const getUserName = (id: number) => {
    const user = AppState.user?._id === id ? AppState.user : (usersQuery.data || []).find((user) => user._id === id);
    return user ? (user.first_name ? user.first_name + " " : "") + (user.last_name ? user.last_name : "") : "";
  };

  return (
    <aside className="info">
      <div className="info-alert">
        <p id="alert-value">
          {notification.Sensor != null ? (
            <>
              <span className="alert-value">
                {measurementTransform(notification.value.value, {
                  unit: notification.Sensor?.default_unit,
                  empirical: notification.Sensor?.is_imperial,
                  type: notification.Sensor?.Sensor_type.type,
                })}
                <span className="u-degree">
                  {unitsTransform(notification.Sensor?.default_unit, [
                    notification.Sensor?.default_unit,
                    notification.Sensor?.is_imperial,
                    notification.Sensor?.Sensor_type.type,
                  ])}
                </span>
              </span>
            </>
          ) : notification.Device !== null ? (
            <>
              <span className="alert-value">
                {notification.value.value}
                <span className="u-degree">{isBatteryLowAlert(notification.Alert) && "V"}</span>
              </span>
            </>
          ) : null}

          <span>
            {!notification.is_snoozed ? <i className="icon icon-alarm" /> : <></>}
            <br className="br-on-mobile" />
            {notification.is_snoozed ? <i className="icon icon-alarm_no" /> : <></>} {getDisplayAlertName(notification.Alert)}
          </span>
        </p>

        <p id="alert-condition">
          <label className="input-label">{"alert condition"}: </label>
          <br className="br-on-mobile" />
          {alertConditionTransform(notification.Alert)}
        </p>
        {/* TODO: Moment changed all their formatting tokens, we need to review every one of these */}
        <p id="datetime" className="u-mobile-hide">
          {formatDateCustom(notification.createdAt, `${getUserDateFormat()} - H:mm a`)}
        </p>
        <p id="datetime-mobile" className="u-mobile-only">
          {formatDateCustom(notification.createdAt, `${getUserDateFormat()} - H:mm a`).replace("-", "\n")}
        </p>
      </div>
      <div className="info-device">
        <p id="info-device-device">
          <label className="input-label">{t("alert_resolve:device")}: </label>
          <br className="br-on-mobile" />
          {notification.Sensor?.Device?.name || notification.Device?.name || ""}
        </p>
        {!notification.is_resolved ? (
          <p>
            <label className="input-label">{t("alert_resolve:placement")}: </label>
            <br className="br-on-mobile" />
            {notification.Sensor?.Device?.location_note || notification.Device?.location_note || "--"}
          </p>
        ) : (
          <p>
            <label className="input-label">{t("alert_resolve:user")}: </label>
            <br className="br-on-mobile" />
            {/* The old code altered notification to add a user object. We prefer not to mutate server-provided data. */}
            {getUserName(notification.UserId)}
          </p>
        )}

        <p id="info-device-serial-num">
          <label className="input-label">{t("alert_resolve:serial_num")}: </label>
          <br className="br-on-mobile" />
          {notification.Sensor?.Device?.serial_number || notification.Device?.serial_number || ""}
        </p>
      </div>
    </aside>
  );
};
