import React from "react";
import { Button } from "@mui/material";
import { showAppModal } from "../AppState";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useScreenMode } from "../Components";
import { WindowSize } from "../Enums";

export interface IModalProps {
  buttons?: React.ReactNode;
  bodyClassName?: string;
  footerClassName?: string;
  children?: React.ReactNode;
  className?: string;
  title?: string | React.ReactNode;
  titleCloseButton?: React.ReactNode;
}

export const Modal: React.FC<IModalProps> = ({
  buttons,
  bodyClassName = "",
  footerClassName = "",
  children,
  className,
  title,
  titleCloseButton,
}) => {
  const { t } = useTranslation("common");
  const mode = useScreenMode();

  return (
    <div className={classNames("modal fade in")} tabIndex={-1} role="dialog">
      <div className={classNames("modal-dialog", className ?? (mode !== WindowSize.MOBILE ? "modal-lg" : ""))}>
        <div className={classNames("modal-content")}>
          {title || titleCloseButton ? (
            <div className={classNames("modal-header")} style={{ display: "flex" }}>
              <h4 className="modal-title">{title}</h4>
              {titleCloseButton}
            </div>
          ) : null}
          <div className={classNames("modal-body", bodyClassName)}>{children}</div>
          <div className={classNames("modal-footer", footerClassName)}>
            {buttons ?? (
              <Button variant="contained" onClick={() => showAppModal(null)}>
                {t("common:ok")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
