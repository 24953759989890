import React, { useRef, useState } from "react";
import "./DigitsInput.scss";
import { useTranslation } from "react-i18next";

interface IDigitsInputProps {
  codeLength: number;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  onDigitsChange: (digits: string) => void;
}

const MOCK_VALUE = "  ";

export const DigitsInput: React.FC<IDigitsInputProps> = (props) => {
  const { codeLength, disabled, error: validationError, errorMessage, onDigitsChange } = props;
  const [value, setValue] = useState("");
  const ref = useRef<HTMLInputElement>(null);
  const dummyArray = Array.from(Array(codeLength)).map((_, i) => i);

  const { t } = useTranslation("common");

  const modifyValue = (value: string, join = MOCK_VALUE) => {
    return value
      .split("")
      .filter((letter) => /[0-9]/.test(letter))
      .join(join);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = modifyValue(event.target.value);

    setValue(newValue);

    const digitValue = modifyValue(event.target.value, "").toString();

    onDigitsChange(digitValue);
  };

  const calculateLeft = (index: number) => {
    const spaceWidth = 10;
    const multiplierIndex = index + 1;
    return `calc(${multiplierIndex * 18}px + 5px + ${index * spaceWidth * 2}px)`;
  };

  const handleCopy = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.clipboardData.setData("text/plain", modifyValue(value, ""));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const selection = ref.current?.selectionEnd;

    if (selection === undefined || selection === null) {
      return;
    }

    if (event.key === "ArrowLeft" && selection % 3 === 0) {
      ref.current?.setSelectionRange(selection - 1, selection - 1);
    } else if (event.key === "ArrowRight" && selection % 3 === 1) {
      ref.current?.setSelectionRange(selection + 1, selection + 1);
    } else if (event.key === "Backspace") {
      event.preventDefault();
      const newValue = value.slice(0, selection - 3) + value.slice(selection);
      setValue(newValue);
      setTimeout(() => ref.current?.setSelectionRange(selection - 3, selection - 3));
    } else if (event.key === "Delete") {
      event.preventDefault();
      const newValue = value.slice(0, selection) + value.slice(selection + 3);
      setValue(newValue);
      setTimeout(() => ref.current?.setSelectionRange(selection, selection));
    }
  };

  return (
    <div className={`digits-input${validationError ? " error" : ""}`} style={{ width: `${codeLength * 2 * 18 + 4}px` }}>
      <input
        value={value}
        ref={ref}
        disabled={disabled}
        onKeyDown={handleKeyDown}
        maxLength={codeLength * 3 - 2}
        onChange={handleChange}
        onCopy={handleCopy}
        className="input input-line"
      />
      {dummyArray.map((i) => (
        <div key={i} className="input-cover" style={{ left: calculateLeft(i) }} />
      ))}
      {validationError && <div className="email-verification-error">{errorMessage || t("common:wrong_code")}</div>}
    </div>
  );
};
