import {
  TabContext as MuiTabContext,
  TabContextProps,
  TabList as MuiTabList,
  TabListProps,
  TabPanel as MuiTabPanel,
  TabPanelProps
} from "@mui/lab";
import { Tab as MuiTab, TabProps } from "@mui/material";

export const Tab: React.FC<TabProps> = (props) => {
  return <MuiTab {...props}/>;
};

export const TabContext: React.FC<TabContextProps> = props => {
  return <MuiTabContext {...props}/>;
};

export const TabList: React.FC<TabListProps> = props => {
  return <MuiTabList {...props}/>;
};

export const TabPanel: React.FC<TabPanelProps> = props => {
  return <MuiTabPanel {...props}/>;
};