import { IHelpTab } from "./Types";
import { Endpoint, invalidateQuery } from "./API";
import { useQuery } from "react-query";

const API_PATH = "api/help_tabs";
export const QK_HELP_TABS = "HELP_TABS";

export const addHelpTab = (helpTab: IHelpTab) =>
  Endpoint.post(`${API_PATH}`, helpTab, { headers: { "Content-Type": "multipart/form-data" } }).then((r) => {
    invalidateQuery(QK_HELP_TABS);
    return r.data;
  });

export const deleteHelpTab = (helpTab: IHelpTab) =>
  Endpoint.delete(`${API_PATH}/${helpTab._id}`).then((r) => {
    invalidateQuery(QK_HELP_TABS);
    return r.data;
  });

export const updateHelpTab = (helpTab: IHelpTab) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return Endpoint.put(`${API_PATH}/${helpTab._id}`, helpTab, config).then((r) => {
    invalidateQuery(QK_HELP_TABS);
    return r.data;
  });
};

export const getAllHelpTabsToManage = () =>
  Endpoint.get(`${API_PATH}`).then((r) => {
    invalidateQuery(QK_HELP_TABS);
    return r.data;
  });

export const getHelpTabs = () => Endpoint.get(`${API_PATH}/getAll`).then((r) => r.data);

export const useHelpTabs = () => useQuery(QK_HELP_TABS, () => getHelpTabs(), { placeholderData: [] });

export const getHelpTab = (helpTab: any) =>
  Endpoint.get(`${API_PATH}/${helpTab._id}`).then((r) => {
    invalidateQuery(QK_HELP_TABS);
    return r.data;
  });

export const downloadHelpTabFile = (fileName: string) =>
  Endpoint.get(`${API_PATH}/download/${fileName}`, { responseType: "blob" }).then((r) => {
    const url = window.URL.createObjectURL(new Blob([r.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
