import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { Modal } from "../../../Components/Modal";
import { showAppModal, showSnackbar } from "../../../AppState";
import React from "react";
import { saveLanguage } from "../../../Managers/LanguageService";
import { Column, FormFieldText, Row } from "../../../Components";
import { ValidationMessages } from "../../../Enums";

export const AddLanguageModal: React.FC<{ existingLanguages: Array<string> }> = ({ existingLanguages }) => {
  const { t } = useTranslation("translations");

  const validationSchema = yup.object({
    code: yup
      .string()
      .max(5, t("common:max_length", { value: 5 }))
      .test("already-exists", t("translations:add_language.already_exists"), (value) => !existingLanguages.some((l) => l === value)),
    name: yup.string().required(t(ValidationMessages.REQUIRED)),
  });

  const initialValues = { name: "", code: "" };

  const save = (values: { name: string; code: string }) =>
    saveLanguage(values.code, values.name)
      .then(() => {
        showSnackbar(t("translations:add_language.save_success"), "success");
        showAppModal(null);
      })
      .catch(() => showSnackbar(t("translations:add_language.save_error"), "error"));

  return (
    <Formik initialValues={initialValues} onSubmit={save} validationSchema={validationSchema}>
      {({ submitForm, isSubmitting }) => (
        <Modal
          title={t("translations:add_language.title")}
          buttons={
            <>
              <button className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                {t("common:cancel")}
              </button>
              <button className="btn btn-primary" disabled={isSubmitting} onClick={submitForm}>
                {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                {t("common:save_changes")}
              </button>
            </>
          }>
          <Row>
            <Column className="col-sm-6">
              <FormFieldText className="u-full-width" displayError={true} label={t("translations:add_language.code")} name="code" />
            </Column>
            <Column className="col-sm-6">
              <FormFieldText className="u-full-width" displayError={true} label={t("translations:add_language.name")} name="name" />
            </Column>
          </Row>
        </Modal>
      )}
    </Formik>
  );
};
