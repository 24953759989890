import React from 'react';
import {useField} from 'formik';
import classNames from 'classnames';
import {IStandardInput} from './Types';

export interface IRadioInputOption {
  value: any;
  label: any;
}

export interface IRadioInput extends IStandardInput {
  options: IRadioInputOption[];
}

export const FormFieldRadio: React.FC<IRadioInput> = ({className = '', readOnly = false, options, name, label, disabled}) => {
  const [field] = useField(name);

  // We may have many check boxes with the same field name. They need separate IDs.
  const id = `${name}_${Math.random().toString(36).substring(2, 9)}`;

  return (
    <div className={classNames('form-group', className)}>
      <label htmlFor={id} className="input-label u-display-block">
        {label}
      </label>
      <div className="btn-group btn-group-toggle" data-toggle="buttons">
        {options.map((option) => (
          <label className={classNames('btn btn-primary', {active: field.value === option.value})} key={option.value}>
            <input
              type="radio"
              value={option.value}
              id={id}
              name={name}
              disabled={disabled}
              onChange={field.onChange}
              onBlur={field.onBlur}
              readOnly={readOnly}
            />{' '}
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};
