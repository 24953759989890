import * as Measurements from "./MeasurementService";

export const transform = (originalValue: string | null | undefined, args?: any[]): string => {
  let value = originalValue ? originalValue.toLowerCase() : "";
  let isEmpirical = false;
  let convertToHumidityForWai418 = false;
  let convertToTemperatureForWai418 = false;

  if (args && args.length >= 2) {
    isEmpirical = args[1] ?? false;
    const sensorType = args[2] ? args[2].toLowerCase() : "range";
    convertToHumidityForWai418 = args[3] ?? false;
    convertToTemperatureForWai418 = args[4] ?? false;

    if (sensorType === "boolean") {
      value = "";
    } else if (sensorType === "range") {
      const type = Measurements.getType(args[0]?.toLowerCase());
      let unit = Measurements.getUnit(type, Number(args[1]));

      if (type.includes("temperature")) {
        value = isEmpirical ? "degf" : "degc";
      } else {
        value = unit ? unit : value;
      }
    }

    if ((value === "kpa" || value === "kilopascal") && (args[1] === true || args[1] === 1)) {
      value = "Hg";
    }
  }

  if (value === "celcius" || value === "degc" || (convertToTemperatureForWai418 && !isEmpirical)) {
    return "°C";
  } else if (value === "fahrenheit" || value === "degf" || (convertToTemperatureForWai418 && isEmpirical)) {
    return "°F";
  } else if (value === "rh" || value === "%rh" || convertToHumidityForWai418) {
    return "%RH";
  } else if (value === "volts") {
    return "v";
  } else if (value === "Hg") {
    return "inHg";
  } else if (value === "kilopascal") {
    return "kpa";
  } else if (value === "lux") {
    return "lx";
  } else if (value === "percentage") {
    return "%";
  } else if (value === "decibels") {
    return "dBM";
  } else if (value === "milliamp") {
    return "mA";
  } else if (value === "parts per million") {
    return "ppm";
  }
  return originalValue || "";
};

export const fullUnitName = (value: string): string => {
  if (value === "°C" || value === "degc") {
    return "celcius";
  } else if (value === "°F" || value === "degf") {
    return "fahrenheit";
  } else if (value === "%RH" || value === "%rh" || value === "rh" || value === "RH") {
    return "RH";
  } else if (value === "v") {
    return "Volts";
  } else if (value === "inHg") {
    return "Hg";
  } else if (value === "kpa") {
    return "kilopascal";
  } else if (value === "lx") {
    return "lux";
  } else if (value === "%") {
    return "percentage";
  } else if (value === "dBm") {
    return "Decibels";
  } else if (value === "mA") {
    return "milliamp";
  } else if (value === "ppm" || "PPM") {
    return "Parts per million";
  } else if (value === "pa" || "PA") {
    return "Pascals";
  }
  return value || "";
};

export const isImperialUnit = (unit: string): boolean => {
  const imperialUnits = ["fahrenheit", "degf", "°f", "inHg", "psi", "ft", "yd", "mi", "oz", "lb"];

  return imperialUnits.includes(unit.toLowerCase());
};

export const unitsTransform = (originalValue: string | null | undefined, args?: any[]): string => transform(originalValue, args);
