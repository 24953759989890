import { ISystemMessage } from "../../Managers/Types";
import React from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { MODAL_BORDER_WIDTH, MODAL_CONTENT_HEIGHT, MODAL_CONTENT_PADDING } from "./consts";

interface ISystemMessageList {
  systemMessages: Array<ISystemMessage>;
  setSelectedSystemMessage: React.Dispatch<React.SetStateAction<ISystemMessage>>;
  handleAdd: () => void;
  addDisabled: boolean;
}

export const SystemMessageList: React.FC<ISystemMessageList> = ({ systemMessages, setSelectedSystemMessage, handleAdd, addDisabled }) => {
  const { t } = useTranslation(["system_message"]);
  const { values: systemMessage } = useFormikContext<ISystemMessage>();

  const handleSystemMessageSelect = (msg: ISystemMessage) => {
    setSelectedSystemMessage(msg);
  };

  return (
    <Box
      className="left-rail left-panel"
      maxHeight={`calc(${MODAL_CONTENT_HEIGHT} + 2*${MODAL_CONTENT_PADDING} + 2*${MODAL_BORDER_WIDTH})`}>
      <div className="left-rail-body">
        <nav className="left-rail-nav">
          <ul className="left-rail-nav-group">
            {systemMessages.map((msg) => (
              <li
                key={msg._id}
                className={classNames("left-rail-nav-item", {
                  active: msg._id === systemMessage._id,
                })}>
                <div onClick={() => handleSystemMessageSelect(msg)}>{msg.name}</div>
              </li>
            ))}
          </ul>
          <button disabled={addDisabled} type="button" className="btn btn-primary" onClick={handleAdd}>
            {t("system_message:button.add")}
          </button>
        </nav>
      </div>
    </Box>
  );
};
