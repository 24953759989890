import { Endpoint, invalidateQuery } from "./API";
import { ISensor, IWatchlist } from "./Types";

const API_PATH = "api/watchlist";
const API_PATH_WATCHLIST_SENSOR = "api/watchlist_sensor";
const QK_WATCHLISTS = ["WATCHLISTS"];
export const colors = ["#00d1a4", "#fa2fb5", "#06bdf7", "#ffa216"];

export const getWatchlists = () =>
  Endpoint.get<IWatchlist[]>(`${API_PATH}`).then((res) => {
    return res.data.map((watchlist) => {
      watchlist.WatchlistSensors?.forEach((sensor, index) => {
        sensor.color = colors[index % colors.length];
      });
      return watchlist;
    }) as IWatchlist[];
  });

export const createWatchlist = (newWatchlist: IWatchlist) =>
  Endpoint.post(`${API_PATH}/`, newWatchlist).then((r) => {
    invalidateQuery(QK_WATCHLISTS);
    return r.data;
  });

export const editWatchlist = (watchlist: IWatchlist) =>
  Endpoint.put(`${API_PATH}/${watchlist._id}`, watchlist).then((r) => {
    invalidateQuery(QK_WATCHLISTS);
    return r.data;
  });

export const destroyWatchlist = (watchlistId: number) =>
  Endpoint.delete(`${API_PATH}/${watchlistId}`).then((r) => {
    invalidateQuery(QK_WATCHLISTS);
    return r.data;
  });

export const addSensorToWatchlists = (sensor: ISensor, watchlists: IWatchlist[]) => {
  const requests = watchlists.map((watchlist) =>
    Endpoint.post(`${API_PATH_WATCHLIST_SENSOR}/add-sensor`, { WatchlistId: watchlist._id, SensorId: sensor._id }),
  );

  return Promise.all(requests)
    .then((responses) => {
      invalidateQuery(QK_WATCHLISTS);
      return responses.map((response) => response.data);
    })
    .catch((error) => {
      console.error("Error adding sensor to watchlists:", error);
      throw error;
    });
};

export const removeSensorFromWatchlist = (sensor: ISensor, watchlistId: number) => {
  return Endpoint.delete(`${API_PATH_WATCHLIST_SENSOR}/remove-sensor`, {
    data: { WatchlistId: watchlistId, SensorId: sensor._id },
  })
    .then((response) => {
      invalidateQuery(QK_WATCHLISTS);
      return response.data;
    })
    .catch((error) => {
      console.error("Error removing sensor from watchlist:", error);
      throw error;
    });
};

/**
 * Get all sensors associated with a specific watchlist by WatchlistId
 * @param watchlistId - ID of the watchlist
 * @returns A Promise that resolves with the list of sensors
 */
export const getSensorsByWatchlistId = (watchlistId: number) => {
  return Endpoint.get<ISensor[]>(`${API_PATH_WATCHLIST_SENSOR}/watchlist/${watchlistId}`)
    .then((res) => {
      return res.data as ISensor[];
    })
    .catch((error) => {
      console.error("Error fetching sensors for watchlist:", error);
      throw error;
    });
};

/**
 * Get all watchlists associated with a specific sensor by sensorId
 * @param sensorId - ID of the sensor
 * @returns A Promise that resolves with the list of watchlists
 */
export const getWatchlistsBySensorId = (sensorId: number) => {
  return Endpoint.get<IWatchlist[]>(`${API_PATH_WATCHLIST_SENSOR}/sensor/${sensorId}`)
    .then((res) => {
      return res.data as IWatchlist[];
    })
    .catch((error) => {
      console.error("Error fetching watchlists for sensor:", error);
      throw error;
    });
};
