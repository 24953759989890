import { Dispatch, SetStateAction, useEffect, useState } from "react";

export enum LocalStorageKey {
  DASHBOARD_FILTERS = "dashboardFilters",
  DASHBOARD_SORT = "dashboardSort",
}

export default function useLocalStorage<T>(
  storageKey: LocalStorageKey,
  defaultValue: T,
): {
  storedValue: T;
  setStoredValue: Dispatch<SetStateAction<T>>;
} {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = window.localStorage.getItem(storageKey);
    return item ? JSON.parse(item) : defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(storageKey, JSON.stringify(storedValue));
  }, [storageKey, storedValue]);

  return { storedValue, setStoredValue };
}
