import * as yup from "yup";
import { Formik } from "formik";
import React from "react";
import { observer } from "mobx-react-lite";
import { IDeviceGroup, ISensorHasAlerts } from "../../../Managers/Types";
import { createGroupConfig } from "../../../Managers/DeviceGroupService";
import { useDevices } from "../../../Managers/DeviceService";
import { FormFieldText } from "../../../Components";
import { showAppModal, showSnackbar } from "../../../AppState";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../Components/Modal";

interface ICreateModalProps {
  selectedGroup: IDeviceGroup;
}

const validationSchema = yup.object({
  newGroupName: yup.string().required(),
});

export const GroupConfigCreateModal: React.FC<ICreateModalProps> = observer(({ selectedGroup }) => {
  const devices = useDevices(false, false);
  const { t } = useTranslation(["dashboard", "common"]);

  const groupDevices = (devices.data || []).filter((device) => device.DeviceGroupId === selectedGroup._id);

  const _getAllSensorAlerts = () => {
    const sensorAlerts = [] as ISensorHasAlerts[];

    groupDevices.forEach((device) => {
      device.Sensors.forEach((sensor) => {
        sensor.Alerts.forEach((alert) => {
          sensorAlerts.push(alert.Sensor_alerts);
        });
      });
    });

    return sensorAlerts;
  };

  const handleSubmit = (values: any) => {
    let groupConfig = {
      name: values.newGroupName,
      sensor_alerts: _getAllSensorAlerts(),
    };

    // TODO: need to validate this when this is ready
    return createGroupConfig(selectedGroup, groupConfig)
      .then((r) => {
        console.log("Created group config", r);
        showAppModal(null);
        showSnackbar(t("dashboard:group_config.create_success"), "success");
      })
      .catch((e) => {
        console.log("Error creating group config", e);
        showSnackbar(t("dashboard:group_config.create_error"), "error");
      });
  };

  const initialValues = { newGroupName: "" };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, isValid, submitForm }) => (
        <Modal
          className="modal-sm"
          title={t("dashboard:group_config.new")}
          buttons={
            <>
              <button className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                {t("common:cancel")}
              </button>
              <button className="btn btn-primary" disabled={isSubmitting || !isValid} onClick={submitForm}>
                {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                {t("dashboard:group_config.create_configuration")}
              </button>
            </>
          }>
          <FormFieldText
            name="newGroupName"
            label={t("dashboard:group_config.name")}
            placeholder={t("dashboard:group_config.name_placeholder")}
          />

          <p className="u-text-small">{t("dashboard:group_config.snapshot_info")}</p>

          <p className="u-text-small">{t("dashboard:group_config.multiple_configuration_info")}</p>
        </Modal>
      )}
    </Formik>
  );
});
