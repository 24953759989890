import React, { useCallback, useEffect, useState } from "react";
import {
  CountrySelect,
  FormFieldCheckbox,
  FormFieldText,
  HeaderRow,
  InputLabel,
  LeftRail,
  Page,
  PermissionBlocker,
  Row,
  SelectInput,
  StyledTooltip,
  ToggleInput,
  Wrapper,
} from "../../Components";
import { FormikProps, useFormikContext, withFormik } from "formik";
import { SubscriptionItem, SubscriptionOption } from "../../Managers/Types";
import classNames from "classnames";
import * as yup from "yup";
import { SortableTableRow, Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import { Box, Button, Chip, CircularProgress, Drawer } from "@mui/material";
import { showAppModal, showSnackbar } from "../../AppState";
import { ConfirmModal } from "../../Modals";
import "./SubscriptionManagement.scss";
import { createOption, saveItem, updateOption, useSubscriptionItems } from "../../Managers/SubscriptionManagementService";
import { useBlocker, useNavigate } from "react-router-dom";
import { cloneDeep, isEqual } from "lodash";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { isUserRoleAllowed, SubscriptionOptionType, UserRoles, ValidationMessages } from "../../Enums";
import { getNameSlug, ICountry } from "../../Managers";
import { CurrencySelect } from "../../Components/CurrencySelect";
import { getCurrenciesForDropdown } from "../../Managers/PaymentOptionsService";
import { ICurrency } from "../../Managers/CurrencyService";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled/macro";
import AccordionRow from "../../Components/AccordionRow";
import { AddOptionModal, INewOption } from "./AddOptionModal";
import { DrawerIcon } from "../../icon";
import { useCountries } from "../../Managers/API";

export const SubscriptionManagement: React.FC = () => {
  const items = useSubscriptionItems();
  const navigate = useNavigate();
  const [item, setItem] = useState<SubscriptionItem>();
  const [tab, setTab] = useState(items.data?.[0]?.slug);
  const [dirty, setDirty] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation(["subscription_management", "countries"]);

  const [itemCopy, setItemCopy] = useState<SubscriptionItem>();

  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return dirty && currentLocation.pathname !== nextLocation.pathname;
  });

  useEffect(() => {
    if (!isUserRoleAllowed(UserRoles.APP_ADMIN)) {
      navigate("/");
    }
  }, [navigate]);

  const MobileHeaderRow = styled.div({});

  const DesktopTabletHeaderRow = styled.div({});

  const showUnloadModal = useCallback(
    (callback: () => void, cancelCallback?: () => void) => {
      showAppModal(
        <ConfirmModal
          header={t("subscription_management:unsaved_changes.title")}
          children={<>{t("subscription_management:unsaved_changes.content")}</>}
          onCancel={() => {
            showAppModal(null);
            blocker.reset && blocker.reset();

            if (cancelCallback) {
              cancelCallback();
            }
          }}
          onConfirm={() => {
            showAppModal(null);
            callback();
          }}
        />,
      );
    },
    [blocker],
  );

  useEffect(() => {
    const selectedItem = items.data?.find((p) => p.slug === tab);
    if (selectedItem) {
      setItem(selectedItem);
      setItemCopy(cloneDeep(selectedItem));
    }
  }, [tab, items.data]);

  useEffect(() => {
    if (blocker?.state === "blocked") {
      showUnloadModal(() => !!blocker.proceed && blocker?.proceed());
    }
  }, [blocker, blocker?.state, showUnloadModal]);

  const validationSchema = yup.object({
    // active: yup.boolean(), // commented out due to hide toggle
    description: yup.string().required(t(ValidationMessages.REQUIRED)),
    name: yup.string().required(t(ValidationMessages.REQUIRED)),
    SubscriptionOptions: yup.array(
      yup.object({
        order: yup.number(),
        active: yup.boolean(),
        default: yup.boolean(),
        defaultPrice: yup
          .number()
          .required(t(ValidationMessages.REQUIRED))
          .min(0, t("common:formik_errors.min", { value: 0 })),
        description: yup.string().required(ValidationMessages.REQUIRED),
        name: yup.string().required(ValidationMessages.REQUIRED),
        value: yup
          .number()
          .required(ValidationMessages.REQUIRED)
          .min(0, t("common:formik_errors.min", { value: 0 }))
          .integer(t(ValidationMessages.INTEGER)),
        hidde: yup.boolean(),
      }),
    ),
  });

  if (!tab && items.isSuccess) {
    setTab(items.data?.[0].slug);
  }

  if (items.isLoading || !item) {
    return (
      <Page>
        <Wrapper style={{ alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Wrapper>
      </Page>
    );
  }

  const switchTab = (slug: string) => {
    if (dirty) {
      showUnloadModal(() => setTab(slug));
    } else {
      setTab(slug);
    }
  };

  const integratePlanWithStripe = (item: SubscriptionItem) => {
    saveItem(item)
      .then((r) => {
        item.stripe_id = r.data.stripe_id;
        showSnackbar(t("subscription_management:stripe_integration_success"), "success");
      })
      .catch(() => showSnackbar(t("subscription_management:stripe_integration_error"), "error"));
  };

  const submitForm = async (o: SubscriptionItem) => {
    if (!itemCopy || !o) {
      return;
    }

    const { SubscriptionOptions: optionsNew, ...restNew } = { ...o };
    const { SubscriptionOptions: optionsOld, ...restOld } = { ...itemCopy };

    if (!isEqual(restNew, restOld)) {
      await saveItem(restNew).catch(() => showSnackbar(t("subscription_management:update_plan_error"), "error"));
    }

    await Promise.all(
      optionsNew.map((option) => {
        if (
          option.id &&
          !isEqual(
            option,
            optionsOld.find((o) => o.id === option.id),
          )
        ) {
          return updateOption(item.id, option)
            .then(() => showSnackbar(`Options updated successfully`, "success"))
            .catch(() =>
              showSnackbar(
                t("subscription_management:update_option_error", {
                  name: option.name,
                }),
                "error",
              ),
            );
        } else if (!option.id) {
          return createOption(item.id, option)
            .then(() => showSnackbar("New option created successfully", "success"))
            .catch(() =>
              showSnackbar(
                t("subscription_management:create_option_error", {
                  name: option.name,
                }),
                "error",
              ),
            );
        }
        return undefined;
      }),
    )
      .then(async () => {
        setDirty(false);
        showSnackbar(t("subscription_management:update_plan_success"), "success");
      })
      .catch((err) => showSnackbar(err.response?.data?.error, "error"));
  };

  return (
    <Page>
      {item ? (
        <Wrapper className="subscription-management-wrapper">
          <LeftRail
            className="u-desktop-only"
            header={<h1 className="left-rail-title">{t("subscription_management:items")}</h1>}
            body={
              <ul className="left-rail-nav-group">
                {items.data
                  ?.sort((a, b) => a.order - b.order)
                  ?.map((i) => (
                    <li className={classNames("left-rail-nav-item", { active: tab === i.slug })} key={i.slug}>
                      <div className="subscription-plan-nav-item" onClick={() => switchTab(i.slug)}>
                        {t(`subscription_management:${getNameSlug(i.name)}`)}
                        {dirty && i.slug === tab ? (
                          <StyledTooltip title={t("subscription_management:unsaved_changes.title")}>
                            <i className="fa fa-exclamation-triangle u-color-warning" aria-hidden="true" />
                          </StyledTooltip>
                        ) : null}
                      </div>
                    </li>
                  ))}
              </ul>
            }
          />
          <Drawer onBackdropClick={() => setDrawerOpen(false)} open={drawerOpen} className="u-tablet-only">
            <div className="tablet-drawer-content">
              <div className="tablet-drawer-header">
                <h1 className="left-rail-title">{t("subscription_management:items")}</h1>
              </div>
              <ul className="left-rail-nav-group">
                {items.data
                  ?.sort((a, b) => a.order - b.order)
                  ?.map((i) => (
                    <li className={classNames("left-rail-nav-item", { active: tab === i.slug })} key={i.slug}>
                      <div
                        className="subscription-plan-nav-item"
                        onClick={() => {
                          switchTab(i.slug);
                          setDrawerOpen(false);
                        }}>
                        {t(`subscription_management:${getNameSlug(i.name)}`)}
                        {dirty && i.slug === tab ? (
                          <StyledTooltip title={t("subscription_management:unsaved_changes.title")}>
                            <i className="fa fa-exclamation-triangle u-color-warning" aria-hidden="true" />
                          </StyledTooltip>
                        ) : null}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </Drawer>

          <main className="bapi-main mod-dashboard subscription-management-main">
            <DesktopTabletHeaderRow>
              <HeaderRow
                info={{
                  name: (
                    <>
                      <button onClick={() => setDrawerOpen(true)} className="btn btn-icon u-tablet-only">
                        <DrawerIcon />
                      </button>
                      <span>{t(`subscription_management:${getNameSlug(item.name)}`)}</span>
                      <PermissionBlocker role={UserRoles.APP_ADMIN}>
                        <span className="u-text-small">
                          {" "}
                          {t("subscription_management:stripe_id", {
                            id: item.stripe_id ?? "⚠️ " + t("subscription_management:missing_stripe_id"),
                          })}
                        </span>
                        {!item.stripe_id && isUserRoleAllowed(UserRoles.APP_ADMIN) && (
                          <Button onClick={() => integratePlanWithStripe(item)} variant="contained">
                            {t("subscription_management:integrate_with_stripe")}
                          </Button>
                        )}
                      </PermissionBlocker>
                      {dirty ? (
                        <Chip
                          className="u-text-uppercase"
                          color="warning"
                          label={t("subscription_management:unsaved_changes.title")}
                          size="small"
                        />
                      ) : null}
                    </>
                  ),
                }}
              />
            </DesktopTabletHeaderRow>
            <MobileHeaderRow className="u-mobile-only">
              <div className="mobile-subscription-management-header">
                {items.data && items.data.length > 1 ? (
                  <SelectInput
                    value={tab}
                    menuItemClass="dark"
                    inputClassName="dark"
                    label={t("subscription_management:items")}
                    onChange={(slug) => {
                      switchTab(slug);
                    }}
                    options={items.data.map((item) => ({
                      value: item.slug,
                      label: item.name,
                      key: item.slug,
                    }))}
                  />
                ) : (
                  <></>
                )}
              </div>
            </MobileHeaderRow>
            <SubscriptionForm
              validationSchema={validationSchema}
              showUnloadModal={showUnloadModal}
              handleSubmit={submitForm}
              item={item}
              onDirtyChange={setDirty}
              setSnackbarSuccessMessage={(message: string) => showSnackbar(message, "success")}
            />
          </main>
        </Wrapper>
      ) : null}
    </Page>
  );
};

interface ISubscriptionFormWrapperProps {
  onDirtyChange: (dirty: boolean) => void;
  setSnackbarSuccessMessage: (snackbarSuccessMessage: string) => void;
  showUnloadModal: (callback: () => void, cancelCallback?: () => void) => void;
}

const SubscriptionFormWrapper = (props: ISubscriptionFormWrapperProps & FormikProps<SubscriptionItem>) => {
  const [newOptionsIds, setNewOptionsIds] = useState<number[]>([]);
  const countryList = useCountries();
  const countries = countryList.data ?? [];
  const [country, setCountry] = useState<ICountry>(countries.find((c) => c.code === "US")!);
  const [currency, setCurrency] = useState<ICurrency>();
  const [allCurrencies, setAllCurrencies] = useState<ICurrency[]>([]);

  const { showUnloadModal, onDirtyChange, setFieldValue, setSnackbarSuccessMessage, values, submitForm } = props;
  const { t } = useTranslation(["subscription_management", "common"]);
  const { dirty, resetForm } = useFormikContext();

  useEffect(() => {
    onDirtyChange(dirty);
  }, [dirty, onDirtyChange]);

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const currencies = await getCurrenciesForDropdown();
        setAllCurrencies(currencies);

        const defaultCurrency = currencies.find((c) => c.iso_code === "USD")!;
        setCurrency(defaultCurrency);
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };

    fetchCurrencies();
  }, []);

  const getSortedItems = () => values.SubscriptionOptions.sort((a, b) => a.order - b.order);

  const filterIfSMSOption = (items: SubscriptionOption[]) => {
    if (!currency) {
      return [];
    }

    const filteredItems = items.filter((item) => item.CurrencyId === currency._id);

    if (values.optionType !== SubscriptionOptionType.sms) {
      return filteredItems;
    }

    return filteredItems.filter((i) =>
      values.optionType === SubscriptionOptionType.sms ? i.country_code === (country?.code ?? "US") : true,
    );
  };

  const integrateOptionWithStripe = (option: SubscriptionOption) => {
    updateOption(option.SubscriptionPlanId, option).then((r) => {
      option.stripe_id = r.stripe_id;
      setSnackbarSuccessMessage(t("subscription_management:stripe_integration_success"));
    });
  };

  const handleDrop = (event: DragEndEvent) => {
    const { over, active } = event;
    if (over && active.id !== over?.id) {
      const sortedItems = getSortedItems();
      const activeIndex = sortedItems.findIndex(({ slug }) => slug === active.id);
      const overIndex = sortedItems.findIndex(({ slug }) => slug === over.id);

      const orderArray = sortedItems.map((i) => i.order);

      const newOptionArray = arrayMove(sortedItems, activeIndex, overIndex);
      newOptionArray.forEach((o, i) => (o.order = orderArray[i]));

      setFieldValue("SubscriptionOptions", newOptionArray).then();
    }
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text).then(() => {
        setSnackbarSuccessMessage(t("subscription_management:stripe_id_copy"));
      });
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const hasStripeIdAtIndex = (options: SubscriptionOption[], index: number): boolean => {
    if (options && index >= 0 && index < options.length) {
      const subscription = options[index];
      return !!subscription?.stripe_id;
    }
    return false;
  };

  const setCountryIfNoChanges = (c: ICountry) => {
    if (dirty) {
      return showUnloadModal(
        () => {
          resetForm();
          setCountry(c);
        },
        () => setCountry({ ...country }),
      );
    }

    resetForm();
    setCountry(c);
  };

  const setCurrencyOnChange = (c: ICurrency) => {
    if (dirty) {
      return showUnloadModal(
        () => {
          resetForm();
          setCurrency(c);
        },
        () => setCurrency({ ...currency! }),
      );
    }

    resetForm();
    setCurrency(c);
  };

  const getCurrencyForOption = (option: SubscriptionOption, signOnly = false) => {
    let currency = null;
    for (const key in allCurrencies) {
      if (allCurrencies.hasOwnProperty(key)) {
        if (allCurrencies[key]._id === option.CurrencyId) {
          currency = allCurrencies[key];
          break;
        }
      }
    }

    if (!currency) {
      return "MISSING!";
    }

    if (signOnly) {
      return currency.sign;
    }

    return (
      <>
        {currency.iso_code} ({currency.sign})
      </>
    );
  };

  const isOptionNew = (optionIndex: number) => {
    return newOptionsIds.includes(optionIndex);
  };

  const MobileButtons = styled.div({
    display: "flex",
    gap: 16,
  });

  return (
    <form key={countries.length + `${currency}`}>
      <section className="main-panel">
        <Box>
          <div className={"u-mobile-only mobile-chip-padding"}>
            {dirty ? (
              <Chip
                className="u-text-uppercase"
                style={{ fontWeight: "bold" }}
                color="warning"
                label={t("subscription_management:unsaved_changes.title")}
                size="small"
              />
            ) : null}
          </div>
          <div className={"u-mobile-only"}>
            <div className={"subscription-plan-name"}>{values.name}</div>
            <div className={"stripe-id-mobile"}>
              {t("subscription_management:stripe_id", {
                id: values.stripe_id ?? "⚠️ " + t("subscription_management:missing_stripe_id"),
              })}
            </div>
          </div>
          <div className="input-label input-label-properties-mobile-tablet">{t("subscription_management:item_properties")}</div>
          <Row className="subscription-item-props u-mobile-hide">
            <FormFieldText
              className="col-sm-3 tablet-padding"
              name="name"
              displayError={true}
              label={t("subscription_management:item.name")}
              type="text"
            />
            <FormFieldText
              style={{ width: isUserRoleAllowed(UserRoles.ADMIN) ? "25%" : "45%" }}
              className="col-sm-3"
              name="description"
              displayError={true}
              label={t("subscription_management:item.description")}
              type="textarea"
            />
            <ToggleInput
              className="active-toggle hidden"
              onChange={(value) => setFieldValue("active", value)}
              name="active"
              activeLabel={t("subscription_management:item.active")}
              inactiveLabel={t("subscription_management:item.inactive")}
              checked={values.active}
            />
            {values.optionType === SubscriptionOptionType.sms ? (
              <div>
                <label className="input-label u-display-block">{t("subscription_management:item.selected_country")}</label>
                <CountrySelect
                  formikControl={false}
                  value={country}
                  name="country"
                  initialValue={country?.code ?? "US"}
                  selectedCountryLabel="name"
                  onChange={(c: ICountry) => {
                    setCountryIfNoChanges(c);
                  }}
                />
              </div>
            ) : null}

            <div>
              <label className="input-label u-display-block currency-select">{t("subscription_management:item.currency")}</label>
              <CurrencySelect
                className={"currency-select"}
                formikControl={false}
                name="currency"
                currencyList={allCurrencies}
                value={currency}
                onChange={(c: ICurrency) => setCurrencyOnChange(c)}
              />
            </div>
          </Row>
          <Row className="subscription-item-props u-mobile-only">
            <FormFieldText name="name" displayError={true} label={t("subscription_management:item.name")} type="text" />
            <FormFieldText name="description" displayError={true} label={t("subscription_management:item.description")} type="textarea" />
            {values.optionType === SubscriptionOptionType.sms ? (
              <div className="select-left-padding">
                <label className="input-label u-display-block">{t("subscription_management:item.selected_country")}</label>
                <CountrySelect
                  formikControl={false}
                  value={country}
                  name="country"
                  initialValue={country?.code ?? "US"}
                  selectedCountryLabel="name"
                  onChange={(c: ICountry) => {
                    setCountryIfNoChanges(c);
                  }}
                />
              </div>
            ) : null}

            <div className="select-left-padding">
              <label className="input-label u-display-block currency-select">{t("subscription_management:currency")}</label>
              <CurrencySelect
                className={"currency-select"}
                formikControl={false}
                name="currency"
                currencyList={allCurrencies}
                value={currency}
                onChange={(c: ICurrency) => setCurrencyOnChange(c)}
              />
            </div>
          </Row>
          <>
            <DndContext onDragEnd={handleDrop}>
              <SortableContext items={filterIfSMSOption(getSortedItems()).map((o) => o.slug)}>
                <div className="input-label input-label-mobile-tablet-margin">
                  {t("subscription_management:item.options", { count: !values.singleOptionPlan ? 2 : 1 })}
                </div>
                <Table className="full-width u-mobile-hide u-tablet-hide" style={{ marginTop: "1em" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell width={values.optionType === SubscriptionOptionType.sms ? "15%" : "20%"}>
                        {t("subscription_management:item.name")}
                      </TableCell>
                      <TableCell width={"20%"}>{t("subscription_management:option.description")}</TableCell>
                      <TableCell width={120}>{t("subscription_management:option.stripe_id")}</TableCell>
                      <TableCell width={200}>
                        {t("subscription_management:option.price")} ({currency?.sign})
                      </TableCell>
                      <TableCell width={200}>
                        {t("subscription_management:option.type", {
                          context: values.optionType === SubscriptionOptionType.transmission ? "time" : "amount",
                        })}
                      </TableCell>
                      <TableCell width={100}>{t("subscription_management:currency")}</TableCell>
                      <TableCell width={100}>
                        <InputLabel
                          style={{ display: "flex" }}
                          name="hidden"
                          label={t("subscription_management:option.hidden")}
                          tooltip={t("subscription_management:option.hidden_description")}></InputLabel>
                      </TableCell>
                      <TableCell width={160}></TableCell>

                      {values.SubscriptionOptions.length > 1 ? <TableCell width={50}></TableCell> : null}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterIfSMSOption(getSortedItems()).map((option) => {
                      const optionIndex = values.SubscriptionOptions.findIndex((o) => o.id === option.id);
                      const isSortable =
                        !values.singleOptionPlan &&
                        !newOptionsIds.includes(optionIndex) &&
                        hasStripeIdAtIndex(values.SubscriptionOptions, optionIndex);
                      return values.optionType === SubscriptionOptionType.sms && option.country_code !== country?.code ? null : (
                        <SortableTableRow sortable={isSortable} id={option.slug} key={`option-${option.id}`}>
                          <TableCell>
                            <FormFieldText name={`SubscriptionOptions[${optionIndex}].name`} displayError={true} type="text" />
                          </TableCell>
                          <TableCell>
                            <FormFieldText name={`SubscriptionOptions[${optionIndex}].description`} displayError={true} type="text" />
                          </TableCell>
                          <TableCell>
                            <div style={{ textTransform: "none", display: "flex", flexDirection: "row" }}>
                              {option.stripe_id ? (
                                <StyledTooltip title={option.stripe_id}>
                                  <Button
                                    variant="outlined"
                                    className="copy-button btn-secondary"
                                    onClick={async () => copyToClipboard(option.stripe_id)}>
                                    <i className="fa fa-copy" /> {t("common:copy").toUpperCase()}
                                  </Button>
                                </StyledTooltip>
                              ) : (
                                "⚠️ empty"
                              )}
                            </div>
                            {!option.stripe_id && isUserRoleAllowed(UserRoles.APP_ADMIN) && !dirty && (
                              <Button onClick={() => integrateOptionWithStripe(option)} variant="contained">
                                <span>{t("subscription_management:option.integrate_with_stripe")}</span>
                              </Button>
                            )}
                          </TableCell>
                          <TableCell>
                            <FormFieldText name={`SubscriptionOptions[${optionIndex}].defaultPrice`} displayError={true} type="number" />
                          </TableCell>
                          <TableCell>
                            <FormFieldText name={`SubscriptionOptions[${optionIndex}].value`} displayError={true} type="number" />
                          </TableCell>
                          <TableCell>{isOptionNew(optionIndex) ? currency?.iso_code : getCurrencyForOption(option)}</TableCell>
                          <TableCell align="center">
                            <FormFieldCheckbox
                              className="checkbox-toggle-hide"
                              onChange={(e) => {
                                setFieldValue(`SubscriptionOptions[${optionIndex}].hidden`, e.target.checked);
                              }}
                              name={`SubscriptionOptions[${optionIndex}].hidden`}
                              checked={!!values.SubscriptionOptions?.[optionIndex]?.hidden}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <ToggleInput
                              className="active-toggle"
                              onChange={(value) => setFieldValue(`SubscriptionOptions[${optionIndex}].active`, value)}
                              name={`SubscriptionOptions[${optionIndex}].active`}
                              activeLabel={t("subscription_management:option.enabled")}
                              inactiveLabel={t("subscription_management:option.disabled")}
                              checked={!!values.SubscriptionOptions?.[optionIndex]?.active}
                            />
                          </TableCell>
                          {newOptionsIds.includes(optionIndex) ? <TableCell></TableCell> : null}
                        </SortableTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </SortableContext>
            </DndContext>

            <div className="tablet-view-container u-tablet-only">
              {filterIfSMSOption(getSortedItems()).map((option) => {
                const optionIndex = values.SubscriptionOptions.findIndex((opt) => opt.id === option.id);
                return (
                  <div key={option.id} className="tablet-view-item">
                    <div className="tablet-row">
                      <div className="tablet-column">
                        <label className="input-label u-display-block">{t("subscription_management:item.name")}</label>
                        <FormFieldText name={`SubscriptionOptions[${optionIndex}].name`} displayError={true} type="text" />
                      </div>
                      <div className="tablet-column">
                        <label className="input-label u-display-block">{t("subscription_management:option.description")}</label>
                        <FormFieldText name={`SubscriptionOptions[${optionIndex}].description`} displayError={true} type="text" />
                      </div>
                    </div>
                    <div className="tablet-row tablet-row-second">
                      <div className="tablet-column-grid">
                        <div>
                          <label className="input-label u-display-block">
                            {t("subscription_management:option.type", {
                              context: values.optionType === SubscriptionOptionType.transmission ? "time" : "amount",
                            })}
                          </label>
                          <FormFieldText name={`SubscriptionOptions[${optionIndex}].value`} displayError={true} type="number" />
                        </div>

                        <div>
                          <label className="input-label u-display-block">
                            {t("subscription_management:option.price")} ({currency?.sign})
                          </label>
                          <FormFieldText name={`SubscriptionOptions[${optionIndex}].defaultPrice`} displayError={true} type="number" />
                        </div>
                      </div>
                      <div className="tablet-column tablet-column-stripe">
                        <label className="input-label u-display-block">{t("subscription_management:option.stripe_id")}</label>
                        <Button variant="text" className="copy-button" onClick={async () => copyToClipboard(option.stripe_id)}>
                          <i className="fa fa-copy" /> {t("common:copy").toUpperCase()}
                        </Button>
                      </div>
                      <div className="tablet-column hidden-tablet-column">
                        <StyledTooltip title={t("subscription_management:option.hidden.description")}>
                          <>
                            <FormFieldCheckbox
                              className="checkbox-toggle-hide"
                              onChange={(e) => {
                                setFieldValue(`SubscriptionOptions[${optionIndex}].hidden`, e.target.checked);
                              }}
                              label={t("subscription_management:option.hidden")}
                              name={`SubscriptionOptions[${optionIndex}].hidden`}
                              checked={!!values.SubscriptionOptions?.[optionIndex]?.hidden}
                            />
                            <i className="fa fa-info-circle" />
                          </>
                        </StyledTooltip>
                      </div>
                      <div className="tablet-column toggle-column">
                        <ToggleInput
                          className="active-toggle"
                          onChange={(value) => setFieldValue(`SubscriptionOptions[${optionIndex}].active`, value)}
                          name={`SubscriptionOptions[${optionIndex}].active`}
                          activeLabel={t("subscription_management:option.enabled")}
                          inactiveLabel={t("subscription_management:option.disabled")}
                          checked={!!values.SubscriptionOptions?.[optionIndex]?.active}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mobile-view-container u-mobile-only">
              {filterIfSMSOption(getSortedItems()).map((option) => {
                const optionIndex = values.SubscriptionOptions.findIndex((opt) => opt.id === option.id);
                return (
                  <AccordionRow
                    key={option.id}
                    title={
                      <div className="accordion-header-subs-management">
                        <label className="input-label u-display-block">{t("subscription_management:item.name")}</label>
                        <FormFieldText name={`SubscriptionOptions[${optionIndex}].name`} displayError={true} type="text" />
                      </div>
                    }
                    body={
                      <>
                        <div key={option.id} className="mobile-view-item">
                          <div className="mobile-row">
                            <label className="input-label u-display-block">{t("subscription_management:option.description")}</label>
                            <FormFieldText name={`SubscriptionOptions[${optionIndex}].description`} displayError={true} type="text" />
                          </div>
                          <div className="mobile-row mobile-row-numbers">
                            <div className="mobile-column">
                              <label className="input-label u-display-block">{t("subscription_management:option.stripe_id")}</label>
                              <Button variant="text" className="copy-button" onClick={async () => copyToClipboard(option.stripe_id)}>
                                <i className="fa fa-copy" /> {t("common:copy").toUpperCase()}
                              </Button>
                            </div>
                            <div className="mobile-column">
                              <label className="input-label u-display-block">
                                {t("subscription_management:option.price")} ({currency?.sign})
                              </label>
                              <FormFieldText name={`SubscriptionOptions[${optionIndex}].defaultPrice`} displayError={true} type="number" />
                            </div>
                            <div className="mobile-column">
                              <label className="input-label u-display-block">
                                {t("subscription_management:option.type", {
                                  context: values.optionType === SubscriptionOptionType.transmission ? "time" : "amount",
                                })}
                              </label>
                              <FormFieldText name={`SubscriptionOptions[${optionIndex}].value`} displayError={true} type="number" />
                            </div>
                          </div>
                          <div className="mobile-row">
                            <ToggleInput
                              className="active-toggle"
                              onChange={(value) => setFieldValue(`SubscriptionOptions[${optionIndex}].active`, value)}
                              name={`SubscriptionOptions[${optionIndex}].active`}
                              activeLabel={t("subscription_management:option.enabled")}
                              inactiveLabel={t("subscription_management:option.disabled")}
                              checked={!!values.SubscriptionOptions?.[optionIndex]?.active}
                            />
                          </div>
                          <div className="mobile-row">
                            <FormFieldCheckbox
                              className="checkbox-toggle-hide"
                              label={t("subscription_management:option.hidden")}
                              onChange={(e) => {
                                setFieldValue(`SubscriptionOptions[${optionIndex}].hidden`, e.target.checked);
                              }}
                              name={`SubscriptionOptions[${optionIndex}].hidden`}
                              checked={!!values.SubscriptionOptions?.[optionIndex]?.hidden}
                            />
                          </div>
                        </div>
                      </>
                    }></AccordionRow>
                );
              })}
              {filterIfSMSOption(getSortedItems()).length === 0 && (
                <div className="mobile-view-no-options">{t("subscription_management:option.no_options")}</div>
              )}
            </div>

            <Button
              className="subscription-management-button u-desktop-only"
              variant="outlined"
              onClick={() => {
                const newIndex = values.SubscriptionOptions.length;
                setFieldValue(`SubscriptionOptions[${values.SubscriptionOptions.length}]`, {
                  name: "",
                  description: "",
                  defaultPrice: "",
                  value: "",
                  active: false,
                  default: false,
                  country_code: country?.code ?? "US",
                  CurrencyId: currency?._id,
                  hidden: false,
                });
                setNewOptionsIds([...newOptionsIds, newIndex]);
              }}>
              + {t("subscription_management:option.add")}
            </Button>
            <div className="text-right u-desktop-only">
              <Button
                variant="contained"
                onClick={() => {
                  submitForm().then();
                  setNewOptionsIds([]);
                }}>
                {t("common:save")}
              </Button>
            </div>

            <MobileButtons className={"u-desktop-hide mobile-buttons"}>
              <Button
                className="subscription-management-button u-tablet-only"
                variant="outlined"
                onClick={() => {
                  const newIndex = values.SubscriptionOptions.length;
                  setFieldValue(`SubscriptionOptions[${values.SubscriptionOptions.length}]`, {
                    name: "",
                    description: "",
                    defaultPrice: "",
                    value: "",
                    active: false,
                    default: false,
                    country_code: country?.code ?? "US",
                    CurrencyId: currency?._id,
                    hidden: false,
                  });
                  setNewOptionsIds([...newOptionsIds, newIndex]);
                }}>
                + {t("subscription_management:option.add")}
              </Button>
              <Button
                className="subscription-management-button u-mobile-only"
                variant="outlined"
                onClick={() => {
                  showAppModal(
                    <AddOptionModal
                      currency={currency}
                      optionType={values.optionType}
                      copyToClipboard={() => copyToClipboard}
                      onAdd={(addingValues: INewOption) => {
                        const newIndex = values.SubscriptionOptions.length;
                        setFieldValue(`SubscriptionOptions[${values.SubscriptionOptions.length}]`, {
                          ...addingValues,
                          country_code: country?.code ?? "US",
                          CurrencyId: currency?._id,
                        });
                        setNewOptionsIds([...newOptionsIds, newIndex]);
                      }}
                    />,
                  );
                }}>
                + {t("subscription_management:option.add")}
              </Button>
              <Button
                className="subscription-management-button"
                variant="contained"
                onClick={() => {
                  submitForm().then();
                  setNewOptionsIds([]);
                }}>
                {t("common:save")}
              </Button>
            </MobileButtons>
          </>
        </Box>
      </section>
    </form>
  );
};

interface ISubscriptionFormProps extends ISubscriptionFormWrapperProps {
  handleSubmit: (item: SubscriptionItem) => void;
  item: SubscriptionItem;
  validationSchema: any;
}

const SubscriptionForm = withFormik<ISubscriptionFormProps, SubscriptionItem>({
  handleSubmit: (formValues, formikBag) =>
    formikBag.props.handleSubmit({
      ...formValues,
      SubscriptionOptions: [...formValues.SubscriptionOptions],
    }),
  mapPropsToValues: (props) => ({ ...props.item }),
  validationSchema: (props: ISubscriptionFormProps) => props.validationSchema,
  validateOnMount: true,
  enableReinitialize: true,
})(SubscriptionFormWrapper);
