import React from "react";
import { ErrorMessage, useField } from "formik";
import { Switch } from "@mui/material";
import classNames from "classnames";
import { IStandardInput } from "./Types";
import { observer } from "mobx-react-lite";

export interface ISwitchInput extends IStandardInput {
  checked: boolean;
  displayError?: boolean;
  inactiveLabel?: string;
}

export const SwitchInput: React.FC<ISwitchInput & { error?: boolean }> = ({
  className = "",
  checked,
  displayError = false,
  readOnly = false,
  name,
  inactiveLabel,
  label,
  onChange,
  disabled,
  error,
}) => {
  const id = `${name}_${Math.random().toString(36).substring(2, 9)}`;

  return (
    <div className={className}>
      <Switch disabled={disabled} id={id} checked={checked} onChange={onChange} />
      <label htmlFor={id} className={classNames({ readonly: readOnly, error })}>
        {inactiveLabel && !checked ? inactiveLabel : label}
      </label>
      {displayError ? (
        <div className="switch-input-error">
          <ErrorMessage name={name} />
        </div>
      ) : null}
    </div>
  );
};

export const SwitchFormField: React.FC<ISwitchInput> = observer(({ checked, name, onChange, ...rest }) => {
  const [field, meta] = useField(name);

  return (
    <SwitchInput
      {...rest}
      checked={checked}
      name={name}
      error={meta.touched && Boolean(meta.error)}
      onChange={(e) => {
        field.onChange(e);
        meta.touched = true;
        onChange && onChange(e);
      }}
    />
  );
});
