import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { downloadHelpTabFile, useHelpTabs } from "../../Managers/HelpTabsService";
import classNames from "classnames";
import { IHelpTab } from "../../Managers/Types";
import { showAppModal } from "../../AppState";
import HelpTabModal from "../../Modals/HelpTabModal";
import ClickAwayListener from "react-click-away-listener";

export const HelpDropdown: React.FC<{
  header?: React.ReactNode;
  className?: string;
  hide?: () => void;
}> = observer(({ hide, className, header }) => {
  const [helpTabs, setHelpTabs] = useState<IHelpTab[]>([]);
  const [menuItems, setMenuItems] = useState<
    Array<{
      action?: () => void;
      title: string;
      hide?: boolean;
      id?: number;
    }>
  >([]);

  const helpTabsQuery = useHelpTabs();

  useEffect(() => {
    setHelpTabs(helpTabsQuery.data ?? []);
  }, [helpTabsQuery.dataUpdatedAt]);

  useEffect(() => {
    if (helpTabs.length > 0) {
      const sortedTabs = [...helpTabs].sort((a, b) => a.order - b.order);
      const tabs: any[] = [];
      sortedTabs.forEach((tab) => {
        if (tab.active) {
          tabs.push({
            id: tab._id,
            title: tab.name,
            action: () => {
              try {
                if (tab.only_download) {
                  downloadHelpTabFile(tab.file_name);
                } else {
                  showAppModal(
                    <HelpTabModal
                      title={tab.name}
                      children={
                        <>
                          <div dangerouslySetInnerHTML={{ __html: tab.content }}></div>
                        </>
                      }
                    />,
                  );
                }
              } catch (error) {
                console.error("Error occurred while handling action:", error);
              }
            },
          });
        }
      });
      setMenuItems(tabs);
    }
  }, [helpTabs]);

  return (
    <ClickAwayListener onClickAway={() => hide && hide()}>
      <section role="menu" className={classNames("dropdown-menu dropdown-menu-right", className)} style={{ width: "190px" }}>
        {header}
        <ul style={{ paddingLeft: 0 }}>
          {menuItems.map((item) =>
            item.hide ? null : (
              <li key={item.id} role="menuitem" className={`dropdown-menu-item ${item.action ? "" : "non-click"}`} onClick={item.action}>
                {item.title}
              </li>
            ),
          )}
        </ul>
      </section>
    </ClickAwayListener>
  );
});
