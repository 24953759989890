import { Modal } from "../../../Components/Modal";
import { IFirmware } from "../../../Managers/Types";
import { AppState, showAppModal } from "../../../AppState";
import { markFirmwareChangesRead } from "../../../Managers/AccountsService";
import { useTranslation } from "react-i18next";
import React from "react";

interface IFirmwareChangelogModalProps {
  firmwares: IFirmware[];
}

export const FirmwareChangelogModal: React.FC<IFirmwareChangelogModalProps> = ({ firmwares }) => {
  const { t } = useTranslation("firmware_changelog");
  const acknowledgeFirmwareChanges = () => {
    if (!AppState.user) {
      return;
    }

    markFirmwareChangesRead(AppState.user.AccountId, true).then(() => {
      if (AppState.user?.Account) {
        AppState.user.Account.firmware_changes_read = true;
      }
      showAppModal(null);
    });
  };

  return (
    <Modal
      buttons={
        <button className="btn btn-secondary" onClick={acknowledgeFirmwareChanges}>
          {t("firmware_changelog:acknowledge")}
        </button>
      }
      title={t("firmware_changelog:whats_new")}>
      {firmwares.map((f) => (
        <>
          <h3>{t("firmware_changelog:title", { context: f.FirmwareTypeId })}</h3>
          <div
            style={{ height: "unset" }}
            className="ql-content-display"
            dangerouslySetInnerHTML={{ __html: `${f.changelog ?? ""}` }}></div>
        </>
      ))}
    </Modal>
  );
};
