import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface ILoginStep {
  identifier: string;
  setIdentifier: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
}

export const LoginStep: React.FC<ILoginStep> = ({ identifier, setIdentifier, password, setPassword }) => {
  const { t } = useTranslation(["alert_resolve", "alerts"]);

  return (
    <div>
      <br />
      <p className="input-label">{t("alert_resolve:shared_user_sign_in_info")}</p>
      <p className="input-label">{t("alert_resolve:fill_in_credentials")}</p>
      <div className="row">
        <div className="col-sm-6">
          <label htmlFor="username" className="input-label">
            {t("alert_resolve:username")}
          </label>
          <div className="input-holder mod-block">
            <input type="text" className="input input-line" required value={identifier} onChange={(e) => setIdentifier(e.target.value)} />
          </div>
        </div>
        <div className="col-sm-6">
          <label htmlFor="password" className="input-label">
            {t("alert_resolve:password")}
          </label>
          {/* TODO: The old system referred to 'expression' in the template but that was never defined in the code. The term
											    was not even used anywhere in the code base (per git grep) except this one line. Angular would have defaulted it
											    to undefined so error would never have been set. What do we wnt? */}
          {/*<div className={classNames('input-holder mod-block',{'error': expression})}>*/}
          <div className={classNames("input-holder mod-block", { error: undefined })}>
            <input type="password" className="input input-line" required value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
        </div>
      </div>
    </div>
  );
};
