import React from "react";
import { ILineChartValue, ISetPoint, TimeSeriesLineChart } from "../../../Components";
import { CircularProgress } from "@mui/material";

interface IGraphStep {
  graphData: ILineChartValue[];
  setPoints: ISetPoint[];
  isLoading: boolean;
}

export const GraphStep: React.FC<IGraphStep> = ({ graphData, setPoints, isLoading }) => {
  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  if (!graphData.length) {
    return <div />;
  }

  return (
    <div>
      <TimeSeriesLineChart data={graphData} setPoints={setPoints} />
    </div>
  );
};
