import { Endpoint, invalidateQuery } from "./API";
import { useQuery } from "react-query";
import { ITranslation } from "./Types";

const API_PATH = "api/manage-translations";
export const QK_TRANSLATIONS = "TRANSLATIONS";

export const getTranslations = (language: string) =>
  Endpoint.get<ITranslation[]>(`${API_PATH}/${language}`).then((response) => response.data);

export const useAppTranslations = (language: string) => useQuery(`${QK_TRANSLATIONS}-${language}`, () => getTranslations(language));

export const saveTranslations = (language: string, translations: ITranslation[]) =>
  Endpoint.post(`${API_PATH}/${language}`, translations).then((res) => {
    invalidateQuery(`${QK_TRANSLATIONS}-${language}`);
    return res.data;
  });
