import React from "react";
import { t } from "i18next";

export class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log("ErrorBoundary caught", error);
    console.log("errorInfo", errorInfo);
    // TODO: Log to Bugsnag or similar
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>{t("common:rendering_error")}</h1>;
    }

    return this.props.children;
  }
}
