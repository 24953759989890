import React, { useEffect, useState } from "react";
import { IDevice, IGateway, ILocation, ISensor } from "../../Managers/Types";
import { CheckboxInput, SelectInput } from "../../Components";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import { IChartDataProps } from "./DeviceReportModal";
import "./DeviceReportModal.scss";

interface IReportSensorPickerProps {
  sensors?: IChartDataProps[];
  changeSensors: (sensors: IChartDataProps[]) => void;
  location: ILocation;
  devices: IDevice[];
}

export const ReportSensorPicker: React.FC<IReportSensorPickerProps> = ({ devices = [], location, sensors = [], changeSensors }) => {
  const [gateway, setGateway] = useState<IGateway>();
  const [filteredDevices, setFilteredDevices] = useState<IDevice[]>(devices);
  const [device, setDevice] = useState<IDevice>();
  const [availableSensors, setAvailableSensors] = useState<ISensor[]>([]);
  const [selectedSensors, setSelectedSensors] = useState<IChartDataProps[]>(sensors);

  const { t } = useTranslation(["common", "export"]);

  useEffect(() => {
    setFilteredDevices(
      devices
        .filter((d) => (gateway ? d.GatewayId === gateway._id : location.Gateways?.map((g) => g._id).includes(d.GatewayId)))
        .sort((d1, d2) => (d1.name > d2.name ? 1 : -1)),
    );
  }, [devices, gateway]);

  useEffect(() => {
    const newAvailableSensors = filteredDevices
      .slice()
      .map((d) => d.Sensors.map((s) => ({ ...s, Device: d })))
      .flat();
    if (device) {
      setAvailableSensors(newAvailableSensors.filter((s) => s.Device._id === device._id));
    } else {
      setAvailableSensors(newAvailableSensors);
    }
  }, [filteredDevices, device]);

  const handleSelectSensor = (sensor: ISensor) => {
    const modifiedSensors = selectedSensors.slice();
    const index = modifiedSensors.findIndex((s) => s.sensor._id === sensor._id);
    if (index > -1) {
      modifiedSensors.splice(index, 1);
    } else {
      modifiedSensors.push({ sensor });
    }
    setSelectedSensors(modifiedSensors);
    changeSensors(modifiedSensors);
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-6">
          <label className="input-label">{t("common:gateway")}</label>
          <SelectInput
            onChange={setGateway}
            value={gateway}
            displayEmpty={true}
            options={[
              { value: undefined, label: t("common:all") },
              ...(location.Gateways ?? []).map((g) => ({
                value: g,
                label: g.name,
              })),
            ]}
          />
        </div>
        <div className="col-sm-6">
          <label className="input-label">{t("common:device")}</label>
          <SelectInput
            onChange={setDevice}
            value={device}
            displayEmpty={true}
            options={[
              { value: undefined, label: t("common:all") },
              ...(filteredDevices ?? []).map((d) => ({
                value: d,
                label: d.name,
              })),
            ]}
          />
        </div>
      </div>
      <div className="report-sensor-custom-scrollbar" style={{ overflowY: "auto", maxHeight: "50vh" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("export:device_name")}</TableCell>
              <TableCell>{t("export:device_type")}</TableCell>
              <TableCell>{t("export:name")}</TableCell>
              <TableCell>{t("export:last_active")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {availableSensors?.map((sensor) => (
              <TableRow key={sensor._id}>
                <TableCell>
                  <CheckboxInput
                    onChange={() => handleSelectSensor(sensor)}
                    checked={selectedSensors.some((s) => s.sensor._id === sensor._id) ?? false}
                    name={sensor._id.toString()}
                  />
                </TableCell>
                <TableCell>{sensor.Device?.name}</TableCell>
                <TableCell>{sensor.Device?.Device_type?.name}</TableCell>
                <TableCell>{sensor.Sensor_type.name}</TableCell>
                <TableCell>{sensor.last_report_time}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
