import * as yup from "yup";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppState, fetchSystemMessagesData, refreshAppState, showAppModal, showSnackbar } from "../../AppState";
import { CountrySelect, FormFieldText, useScreenMode } from "../../Components";
import { isUserRoleAllowed, UserRoles, ValidationMessages, WindowSize } from "../../Enums";
import { Modal } from "../../Components/Modal";
import { ISystemMessage } from "../../Managers/Types";
import { createSystemMessage, deleteSystemMessage, updateSystemMessage } from "../../Managers/ManageSystemMessageService";
import "./ManageSystemMessageModal.scss";
import { useTranslation } from "react-i18next";
import { RichEditor } from "../../Components/RichEditor";
import { Box, Stack } from "@mui/material";
import { ICountry } from "../../Managers";
import classNames from "classnames";
import { SystemMessagePermissions } from "./SystemMessagePermissions";
import { SystemMessageListController } from "./SystemMessageListController";
import { SendEmailsButton } from "./SendEmailsButton";
import { LastInvalidationSection } from "./LastInvalidationSection";
import { ConfirmModal } from "../ConfirmModal";
import useFirstRender from "../../hooks/useFirstRender";
import { MODAL_CONTENT_HEIGHT } from "./consts";

const getDefaultSystemMessage = () => ({
  message: "",
  active: false,
  name: "New system message",
  last_invalidation_date: null,
  Permissions: [],
});

const validationSchema = yup.object({
  message: yup.string().required(ValidationMessages.REQUIRED).min(1, "Message cannot be empty"), // TODO trans, does not work
  name: yup.string().required(ValidationMessages.REQUIRED).min(1, "Name cannot be empty"), // TODO trans, does not work
  active: yup.boolean(),
});

export const ManageSystemMessageModal: React.FC = observer(() => {
  const systemMessages = AppState.systemMessages;

  const [selectedSystemMessage, setSelectedSystemMessage] = useState<ISystemMessage>(systemMessages?.[0]);

  const firstRender = useFirstRender();

  const handleAdd = () => {
    const unsavedNewSystemMessage = systemMessages.find((msg) => !msg._id);

    if (unsavedNewSystemMessage) {
      setSelectedSystemMessage(unsavedNewSystemMessage);
      return;
    }

    const newSystemMessage: ISystemMessage = getDefaultSystemMessage();
    systemMessages.push(newSystemMessage);
    setSelectedSystemMessage(newSystemMessage);
  };

  useEffect(() => {
    if (!firstRender) {
      return;
    }

    if (!systemMessages || systemMessages.length === 0) {
      handleAdd();
    }
  }, []);

  const { t } = useTranslation(["common", "system_message"]);

  const quillRef = useRef();

  const mode = useScreenMode();

  if (!AppState.user || !systemMessages || !selectedSystemMessage) {
    return <></>;
  }

  const handleSubmit = (values: ISystemMessage) => {
    if (!AppState.user || !isUserRoleAllowed(UserRoles.APP_ADMIN)) {
      return;
    }

    const updatingValues = { ...selectedSystemMessage, ...values };
    (selectedSystemMessage?._id ? updateSystemMessage(updatingValues) : createSystemMessage(updatingValues))
      .then((r) => {
        fetchSystemMessagesData();
        console.log("updateSystemMessage result", r);
        showAppModal(null);
        showSnackbar(t("system_message:update_success"), "success");
        return refreshAppState();
      })
      .catch((error) => {
        console.log("Update System Message error", error);
        showSnackbar(t("system_message:update_error"), "error");
      });
  };

  const handleDelete = (systemMessage: ISystemMessage) => {
    const onConfirm = () => {
      deleteSystemMessage(systemMessage._id!).then(() => {
        fetchSystemMessagesData();
        showAppModal(null);
        return refreshAppState();
      });
    };

    showAppModal(
      <ConfirmModal
        header={t("system_message:delete_dialog.title")}
        children={<p>{t("system_message:delete_dialog.body", { name: systemMessage.name })}</p>}
        confirmText={t("common:delete")}
        confirmButtonType="btn-danger"
        onConfirm={onConfirm}
      />,
    );
  };

  return (
    <Formik enableReinitialize={true} initialValues={selectedSystemMessage} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting, isValid, values, submitForm, initialValues, setFieldValue, setFieldTouched }) => (
        <Modal
          className="manage-system-messages-modal modal-lg"
          title={t("system_message:title")}
          buttons={
            <>
              {values._id && (
                <button className="btn btn-danger" onClick={() => handleDelete(values)}>
                  {t("common:delete")}
                </button>
              )}

              <button className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                {t("common:cancel")}
              </button>

              <button className="btn btn-primary" disabled={!isValid || isSubmitting} onClick={submitForm}>
                {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                {t("common:save_changes")}
              </button>
            </>
          }>
          <form>
            <SystemMessageListController
              systemMessages={systemMessages}
              setSelectedSystemMessage={setSelectedSystemMessage}
              handleAdd={handleAdd}
            />

            <Stack
              component="div"
              className={classNames("right-panel main")}
              height={mode === WindowSize.DESKTOP ? MODAL_CONTENT_HEIGHT : undefined}>
              <Box component="label" className="input-label row" mt="1rem">
                {t(`system_message:name`)}
              </Box>
              <FormFieldText type="text" name="name" />

              <div className="row message-edition-row">
                <RichEditor
                  defaultValue={initialValues.message}
                  className="message-notification"
                  ref={quillRef}
                  readonly={false}
                  onTextChange={(value) => setFieldValue("message", value)}
                />
              </div>

              <SystemMessagePermissions systemMessage={values} setFieldValue={setFieldValue} />

              <LastInvalidationSection systemMessage={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />

              <Box component="label" className="input-label row" mt="1rem">
                {t(`system_message:country`)}
              </Box>
              <CountrySelect
                name="Country"
                hidePhones={true}
                value={values.Country}
                initialValue={initialValues.Country?.code}
                formikControl={true}
                selectedCountryLabel="name"
                showAll={true}
                onChange={(value: ICountry) => {
                  values.Country = value;
                  values.CountryId = value?._id;
                }}
              />

              <SendEmailsButton selectedSystemMessage={values} />
            </Stack>
          </form>
        </Modal>
      )}
    </Formik>
  );
});
