import React, { useEffect, useState } from "react";
import { IPlanProps } from "../Managers/Types";
import { getCurrencySign } from "../AppState";
import { useTranslation } from "react-i18next";

export const TotalPrice: React.FC<{
  calculateTotal: (values: IPlanProps) => Promise<number | null>;
  values: IPlanProps;
  forceCurrencySign?: string;
}> = ({ calculateTotal, values, forceCurrencySign }) => {
  const [total, setTotal] = useState(100);
  const currencySign = forceCurrencySign || getCurrencySign();

  useEffect(() => {
    if (!Object.values(values).some((value) => value === undefined || value === null || value === "")) {
      calculateTotal(values).then((value) => {
        if (value) {
          setTotal(value);
        }
      });
    }
  }, [calculateTotal, values]);

  const { t } = useTranslation("common");

  return (
    <div className="pricing-info-total">
      {t("common:total")}
      <br />
      <span className="pricing-info-total-number">
        {currencySign}
        {total.toFixed(2)}
      </span>
      /{t("common:monthly")}
    </div>
  );
};
