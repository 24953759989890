import moment from "moment";
import { useQuery } from "react-query";
import {INotification, INotificationDisplay, ISystemNotification, IUser} from "./Types";
import {deleteSystemNotification, Endpoint, invalidateQuery} from "./API";
import {AppState, refreshAppState, showAppModal} from "../AppState";
import React from "react";
import { BillingInfo } from "../Modals";
import { NotificationTypes } from "../Enums/NotificationTypes";
import { Colors } from "../Theme";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { isUserRoleAllowed, PermissionEnum, UserRoles } from "../Enums";

const PATH = "api/alert_notifications";
const QK_NOTIFICATIONS = "NOTIFICATIONS";

export const getNotifications = (startDate: string, endDate: string, resolved: boolean) =>
  Endpoint.get<INotification[]>("/api/alert_notifications", {
    params: {
      startDate,
      endDate,
      resolved,
    },
  }).then((r) => r.data);

export const getNotificationsHistory = (startDate: string, endDate: string, resolved?: boolean) =>
  Endpoint.get<INotification[]>("/api/alert_notifications", {
    params: {
      devices: true,
      startDate,
      endDate,
      resolved,
      fetch_all: true,
    },
  }).then((r) => r.data);

export const useNotificationHistory = (startDate: string, endDate: string, resolved?: boolean) =>
  useQuery(
    [
      QK_NOTIFICATIONS + "history" + startDate + endDate,
      {
        startDate,
        endDate,
      },
    ],
    () => getNotificationsHistory(startDate, endDate, resolved),
  );

export const useNotifications = (startDate: string, endDate: string, resolved = false) =>
  useQuery(
    [
      QK_NOTIFICATIONS + startDate + endDate + resolved,
      {
        startDate,
        endDate,
        resolved,
      },
    ],
    () => getNotifications(startDate, endDate, resolved),
    {
      refetchInterval: 60000,
    },
  );

// get last 24 hours resolved notifications
// and all unresolved notifications
export const getLatestNotifications = () => getNotifications(moment().subtract(24, "hours").toISOString(), moment().toISOString(), false);

export const useLatestNotifications = () => useQuery(QK_NOTIFICATIONS, getLatestNotifications, { refetchInterval: 30000 });

export const updateNotification = (notification: INotification, identifier: any, password: any) =>
  Endpoint.put(`${PATH}/${notification._id}`, {
    notification,
    identifier,
    password,
  }).then((r) => {
    invalidateQuery(QK_NOTIFICATIONS);
    return r.data;
  });

export const deleteNotification = (notification: INotification) =>
  Endpoint.delete(`${PATH}/${notification._id}`).then((r) => {
    invalidateQuery(QK_NOTIFICATIONS);
    return r.data;
  });

export const deleteNotifications = (notifications: INotification[]) =>
  Promise.all(
    notifications.map((notification) =>
      Endpoint.delete(`${PATH}/${notification._id}`).then((res) => {
        invalidateQuery(QK_NOTIFICATIONS);
        return res.data;
      }),
    ),
  );

export const useLatestSystemNotifications = () => {
  const user = AppState.user;
  const notifications: Array<INotificationDisplay> = [];
  if (isUserRoleAllowed(UserRoles.APP_ADMIN) || user?.Account?.is_demo) {
    return notifications;
  }

  checkSubscriptionExpiring(user, notifications);
  checkSmsUsageCloseToLimit(user, notifications);
  checkNumberOfDevicesCloseToLimit(user, notifications);
  checkUserCreationCloseToLimit(user, notifications);
  checkTheLastAccountPayment(user, notifications);
  addSystemNotifications(user, notifications);

  return notifications;
};

const checkSubscriptionExpiring = (user: IUser | null, notifications: Array<INotificationDisplay>) => {
  if (user && user.expiration_date && user.Account?.unpaid) {
    const currentTime = new Date().valueOf();
    const expirationTime = new Date(user.expiration_date).valueOf();
    const timeUntilExpiration = expirationTime - currentTime;
    const millisecondsInOneDay = 86400000;
    const daysUntilExpiration = Math.ceil(timeUntilExpiration / millisecondsInOneDay);
    if (daysUntilExpiration <= 7 && daysUntilExpiration >= 0) {
      const type = daysUntilExpiration === 0 ? NotificationTypes.SUBSCRIPTION_EXPIRE_TODAY : NotificationTypes.SUBSCRIPTION_NEAR_TO_EXPIRE;
      notifications.push({
        message: t("notification_dropdown:notification.subscription_expire", { count: daysUntilExpiration }),
        button: {
          action: () => showAppModal(<BillingInfo />),
          label: t("notification_dropdown:notification.subscription_renew"),
        },
        type,
        read: user?.Account.notifications_read?.includes(type),
      });
    }
  }
};

const checkSmsUsageCloseToLimit = (user: IUser | null, notifications: Array<INotificationDisplay>) => {
  if (user?.Limits?.usage?.SMS && user?.Limits?.limits?.SMS) {
    const smsUsageCloseToLimit = user.Limits.usage.SMS >= 0.9 * user.Limits.limits.SMS;
    if (smsUsageCloseToLimit) {
      const limitReached = user?.Limits.usage.SMS === user?.Limits.limits.SMS;
      const context = limitReached ? "reached" : "warn";
      const type = limitReached ? NotificationTypes.SMS_LIMIT_OFF : NotificationTypes.SMS_LIMIT_NEAR_EXCEED;
      notifications.push({
        message: t("notification_dropdown:notification.sms_limit", {
          context,
          current: user?.Limits.usage.SMS,
          limit: user?.Limits.limits.SMS,
        }),
        button: {
          action: () => showAppModal(<BillingInfo />),
          label: t("notification_dropdown:notification.upgrade_plan"),
        },
        type,
        read: user?.Account?.notifications_read?.includes(type) ?? false,
      });
    }
  }
};

const checkNumberOfDevicesCloseToLimit = (user: IUser | null, notifications: Array<INotificationDisplay>) => {
  if (user?.Limits?.usage?.DEVICES && user?.Limits?.limits?.DEVICES) {
    const numberOfDevicesCloseToLimit = user.Limits.usage.DEVICES >= 0.9 * user.Limits.limits.DEVICES;
    if (numberOfDevicesCloseToLimit) {
      let type: number, context: string;
      if (user.Limits.usage.DEVICES === user.Limits.limits.DEVICES) {
        context = "reached";
        type = NotificationTypes.DEVICES_LIMIT_OFF;
      } else if (user?.Limits.usage.DEVICES > user?.Limits.limits.DEVICES) {
        context = "exceeded";
        type = NotificationTypes.DEVICE_LIMIT_EXCEED;
      } else {
        context = "warn";
        type = NotificationTypes.DEVICES_LIMIT_NEAR_EXCEED;
      }

      notifications.push({
        message: t("notification_dropdown:notification.devices_limit", {
          context,
          current: user?.Limits.usage.DEVICES,
          limit: user?.Limits.limits.DEVICES,
        }),
        button: {
          action: () => showAppModal(<BillingInfo />),
          label: t("notification_dropdown:notification.upgrade_plan"),
        },
        type,
        read: user?.Account?.notifications_read?.includes(type) ?? false,
      });
    }
  }
};

const checkUserCreationCloseToLimit = (user: IUser | null, notifications: Array<INotificationDisplay>) => {
  if (user?.Limits?.usage?.USERS && user?.Limits?.limits?.USERS) {
    const userCreationCloseToLimit = user.Limits.usage.USERS >= 0.9 * user.Limits.limits.USERS;
    if (userCreationCloseToLimit) {
      const limitReached = user?.Limits.usage.USERS === user?.Limits.limits.USERS;
      const context = limitReached ? "reached" : "warn";
      const type = limitReached ? NotificationTypes.USER_LIMIT_OFF : NotificationTypes.USER_LIMIT_NEAR_EXCEED;

      notifications.push({
        message: t("notification_dropdown:notification.users_limit", {
          context,
          current: user?.Limits.usage.USERS,
          limit: user?.Limits.limits.USERS,
        }),
        button: {
          action: () => showAppModal(<BillingInfo />),
          label: t("notification_dropdown:notification.upgrade_plan"),
        },
        type,
        read: user?.Account?.notifications_read?.includes(type) ?? false,
      });
    }
  }
};
const checkTheLastAccountPayment = (user: IUser | null, notifications: Array<INotificationDisplay>) => {
  if (user?.Permissions.some((p) => [PermissionEnum.BILLING_ACCESS, PermissionEnum.SUBSCRIPTION_ACCESS].includes(p.name))) {
    if (user?.Account?.unpaid) {
      let daysLeftToUseTheApp = moment.duration(moment(new Date(user?.Account?.first_unpaid_check)).add(10, "day").diff(new Date()));

      let message = (
        <Trans
          t={t}
          count={daysLeftToUseTheApp.get("days")}
          components={{
            warn: <span style={{ color: Colors.alert, fontWeight: "bold" }}></span>,
            days: <span style={{ color: Colors.teal }}></span>,
          }}
          i18nKey="notification.last_payment"
          ns="notification_dropdown"
        />
      );
      notifications.push({
        message,
        button: {
          action: () => (window.location.href = user?.Account?.unpaid_url?.url ?? "/"),
          label: t("notification_dropdown:notification.pay"),
        },
        type: NotificationTypes.PAYMENT_UNSUCCESSFUL,
        read: user?.Account?.notifications_read?.includes(NotificationTypes.PAYMENT_UNSUCCESSFUL) ?? false,
      });
    }
  }
};

const addSystemNotifications = (user: IUser | null, notifications: Array<INotificationDisplay>) => {
  if (!user?.Account?.System_notifications) {
    return;
  }

  user.Account.System_notifications.forEach((notification: ISystemNotification) => {
    notifications.push({
      message: t(notification.text),
      button: {
        action: () => deleteSystemNotification(notification._id).then(
            () => refreshAppState() // TODO slow-query, executes 800ms on my pc; to test on staging
        ),
        label: t("notification_dropdown:notification.okay"),
      },
      type: NotificationTypes.SYSTEM_NOTIFICATION,
      read: false,
    });
  });
};
