import classNames from 'classnames';
import {observer} from 'mobx-react-lite';
import React, {useState, useRef, useEffect} from 'react';
import './InputRange.scss';

interface IInputRangeProps {
  min: number;
  max: number;
  valueMin?: number | null | undefined;
  valueMax?: number | null | undefined;
  type: string;
  readonly: boolean;
  onMinValueChange: (value: number) => void;
  onMaxValueChange: (value: number) => void;
}

export const InputRange: React.FC<IInputRangeProps> = observer(
  ({min, max, valueMin, valueMax, type, readonly = false, onMaxValueChange, onMinValueChange}) => {
    const [changing, setChanging] = useState<string | null>(null);
    const [minBtnMaxPosition, setMinBtnMaxPosition] = useState(0);
    const [maxBtnMaxPosition, setMaxBtnMaxPosition] = useState(0);
    const [bounding, setBounding] = useState<DOMRect | null>(null);
    const el = useRef<HTMLDivElement>(null);
    const minBtn = useRef<HTMLButtonElement>(null);
    const maxBtn = useRef<HTMLButtonElement>(null);

    useEffect(() => {
      //console.log('Initializin slider', {min, max, valueMin, valueMax, type});
      if (type === 'range') {
        valueChange(null, 'min');
        valueChange(null, 'max');
      } else if (type === 'min') {
        valueChange(null, 'min');
      } else if (type === 'max') {
        valueChange(null, 'max');
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mouseDown = (button: string) => {
      setChanging(button);
      _updateMinMaxBtnAttributes();
    };

    const mouseUp = () => {
      if (changing) {
        if (changing === 'min') {
          onMinValueChange(+(valueMin || 0));
        } else if (changing === 'max') {
          onMaxValueChange(+(valueMax || 0));
        }

        setChanging('');
        _updateMinMaxBtnAttributes();
      }
    };

    const mouseMove = (event: any) => {
      let position: number;

      if (!bounding || !minBtn.current || !maxBtn.current) {
        return;
      }

      if (bounding && changing === 'min') {
        position = Math.min(Math.max(event.x - bounding.left, 0), minBtnMaxPosition);
        minBtn.current.style.left = position + 'px';

        valueMin = Math.max(min, _calcValueOnPosition(position));
      } else if (bounding && changing === 'max') {
        position = Math.min(Math.max(bounding.right - event.x, 0), maxBtnMaxPosition);
        maxBtn.current.style.right = position + 'px';

        valueMax = Math.min(
          _calcValueOnPosition(bounding.width - position - maxBtn.current?.clientWidth - minBtn.current.clientWidth),
          max,
        );
      }
    };

    const valueChange = (event: any, button: string) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (!minBtn.current || !maxBtn.current) {
        return 0;
      }

      let bounds = bounding || _updateMinMaxBtnAttributes();
      if (!bounds) {
        return 0;
      }

      if (button === 'min') {
        let position = _calcBtnPossition(+(valueMin || 0));
        minBtn.current.style.left = Math.min(position, minBtnMaxPosition) + 'px';
        onMinValueChange(+(valueMin || 0));
      }

      if (button === 'max') {
        let position = _getSliderWidth() - _calcBtnPossition(+(valueMax || 0));
        maxBtn.current.style.right = Math.min(position, maxBtnMaxPosition) + 'px';
        onMaxValueChange(+(valueMax || max));
      }
    };

    const _getSliderWidth = () => {
      if (!bounding || !minBtn.current || !maxBtn.current) {
        return 0;
      }

      return bounding.width - minBtn.current.clientWidth - maxBtn.current.clientWidth;
    };

    const _calcBtnPossition = (value: number) => {
      if (value === undefined) {
        return NaN;
      }

      // value should not be smaller than the min value or bigger than the max value
      value = Math.max(min, value);
      value = Math.min(max, value);

      const range = max - min;
      return range > 0 ? ((value - min) / range) * _getSliderWidth() : NaN;
    };

    const _calcValueOnPosition = (position: number) => {
      return Math.round((position / _getSliderWidth()) * (max - min) + min);
    };

    const _updateMinMaxBtnAttributes = () => {
      if (!maxBtn.current || !minBtn.current) {
        return null;
      }

      const maxBtnBounding = maxBtn.current.getBoundingClientRect();
      const minBtnBounding = minBtn.current.getBoundingClientRect();

      const rect = el.current?.querySelector('.input-range-slider')?.getBoundingClientRect() || null;
      setBounding(rect);

      if (rect) {
        setMinBtnMaxPosition(Math.min(Math.abs(maxBtnBounding.left - rect.left - maxBtnBounding.width), _getSliderWidth()));
        setMaxBtnMaxPosition(Math.min(Math.abs(rect.right - minBtnBounding.right - minBtnBounding.width), _getSliderWidth()));
      }

      return rect;
    };

    return (
      <div className={classNames('input-range {type}', {readonly: readonly})} onMouseUp={mouseUp} ref={el}>
        <div className="input-range-field input-holder min">
          {readonly ? <span>{valueMin}</span> : <></>}
          {!readonly ? (
            <input
              type="text"
              className="input input-default"
              name="range-min"
              value={+(valueMin || 0)}
              onChange={(e) => {
                valueChange(e, 'min');
              }}
            />
          ) : (
            <></>
          )}
          <label htmlFor="range-min" className="input-label u-display-block">
            Min
          </label>
        </div>

        <div className="input-range-slider" onMouseMove={mouseMove}>
          <button
            className={classNames('input-range-button min', {readonly: readonly})}
            onMouseDown={() => mouseDown('min')}
            disabled={readonly}
            ref={minBtn}>
            <span className="sr-only">Set min value</span>
          </button>
          <button
            className={classNames('input-range-button max', {readonly: readonly})}
            onMouseDown={() => mouseDown('max')}
            disabled={readonly}
            ref={maxBtn}>
            <span className="sr-only">Set max value</span>
          </button>
        </div>

        <div className="input-range-field input-holder max">
          {readonly ? <span>{valueMax}</span> : <></>}
          {!readonly ? (
            <input
              type="text"
              className="input input-default"
              name="range-max"
              value={+(valueMax || 0)}
              onInput={(e) => valueChange(e, 'max')}
            />
          ) : (
            <></>
          )}
          <label htmlFor="range-max" className="input-label u-display-block">
            Max
          </label>
        </div>
      </div>
    );
  },
);
