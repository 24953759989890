import React, { useEffect, useState } from "react";
import { RangePicker } from "../../Components";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { ReportMode } from "./ReportModalState";

interface IReportRangePickerProps {
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  mode: ReportMode;
}

export const ReportRangePicker: React.FC<IReportRangePickerProps> = ({ mode, startDate, endDate, ...rest }) => {
  const [error, setError] = useState("");
  const { t } = useTranslation("export");

  useEffect(() => {
    if (mode === ReportMode.POINTS_OF_INTEREST && moment(endDate).diff(moment(startDate), "day") > 1) {
      setError(t("export:range_error_day"));
    } else {
      setError("");
    }
  }, [mode, startDate, endDate]);

  return (
    <div>
      <RangePicker startDate={startDate} endDate={endDate} className="vertical u-flex-strech" hasTimeSelector={true} {...rest} />
      {error ? <div className="u-color-alert">{error}</div> : null}
    </div>
  );
};
