import { useTranslation } from "react-i18next";

export enum UpdatePlanSteps {
  UpdatePlan,
  UpdatePlanConfirmationStep,
}

export const GetStepNameUpdatePlan = (step: UpdatePlanSteps, updateByAdmin?: boolean): string => {
  const { t } = useTranslation("update_plan_modal");
  switch (step) {
    case UpdatePlanSteps.UpdatePlan:
      return updateByAdmin ? t("update_plan_modal:update_plan_by_admin") : t("update_plan_modal:update_plan");
    case UpdatePlanSteps.UpdatePlanConfirmationStep:
      return t("update_plan_modal:confirmation");
    default:
      throw new Error("Unsupported step");
  }
};

export const GetActiveStepsUpdatePlan = (updateByAdmin?: boolean) =>
  [UpdatePlanSteps.UpdatePlan, UpdatePlanSteps.UpdatePlanConfirmationStep].map((step) => ({
    key: step,
    label: GetStepNameUpdatePlan(step, updateByAdmin),
  }));
