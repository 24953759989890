import React from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useLocation } from "react-router-dom";
import { AppState } from "../AppState";
import { URLS } from "./URLS";
import { isUserPermissionAllowed, isUserRoleAllowed } from "../Enums";

export const AuthView: React.FC<{ element: JSX.Element; permission?: string; role?: string }> = observer(
  ({ element, permission, role }) => {
    const location = useLocation();

    if (!AppState.isAuthenticated) {
      return <Navigate to="/auth" state={{ from: location }} replace />;
    } else if (!AppState.user?.is_policy_accepted || !AppState.user?.is_terms_accepted || !AppState.user?.is_marketing_shown) {
      return <Navigate to={URLS.TermsAndPolicy} state={{ from: location }} replace />;
    } else if (!AppState.user?.has_active_subscription) {
      return <Navigate to={URLS.SubscriptionIsExpired} state={{ from: location }} replace />;
    } else if ((permission && !isUserPermissionAllowed(permission)) || (role && !isUserRoleAllowed(role))) {
      return <Navigate to={URLS.Dashboard} state={{ from: location }} replace />;
    }
    return element;
  },
);
