import { IAccountProps } from "../../Views/Login/AccountStep";
import { Column, List, ListItem, Row, StepperActions } from "../../Components";
import React, { useEffect, useState } from "react";
import { IOptionPrice, IPaymentOptions, ISelectedPlanOption, IPlanProps, IUser } from "../../Managers/Types";
import "./UpdatePlanConfirmationStep.scss";
import { GetPaymentOptions } from "../../Managers/PaymentOptionsService";
import { AppState, getCurrencySign, refreshAppState, showAppModal, showSnackbar } from "../../AppState";
import { getFirstPaymentLink, updateSubscription } from "../../Managers";
import { useTranslation } from "react-i18next";
import { IAccountInfo } from "../../Views/Accounts/Types";

interface IConfirmationStepProps {
  accountDetails?: IAccountProps;
  planDetails?: IPlanProps;
  price: number;
  isConfirming: boolean;
  firstCheckout: boolean;
  options?: IPaymentOptions;
  onClickBack: () => void;
  updateByAdmin?: boolean;
  updatingAccountInfo?: IAccountInfo;
}

export const UpdatePlanConfirmationStep: React.FC<IConfirmationStepProps> = ({
  onClickBack,
  planDetails,
  price,
  options,
  firstCheckout,
  isConfirming,
  updateByAdmin,
  updatingAccountInfo,
}) => {
  const { numberOfDevices, numberOfUsers, smsNotifications, transmissionRate } = planDetails ?? {};

  const [usersOption, setUsersOption] = useState<IOptionPrice>();
  const [transmissionOption, setTransmissionOption] = useState<IOptionPrice>();
  const [smsOption, setSmsOption] = useState<IOptionPrice>();
  const [planOptions, setPlanOptions] = useState<IPaymentOptions>();

  const { t } = useTranslation(["billing_info", "common"]);

  useEffect(() => {
    const userCountryCode = updateByAdmin ? AppState?.selectedAccount?.country : AppState?.user?.country_code ?? "US";
    const currencyCode = updateByAdmin
      ? AppState.selectedAccount?.Currency?.iso_code
      : AppState?.user?.Account?.Currency?.iso_code ?? "USD";
    GetPaymentOptions(userCountryCode, currencyCode, updateByAdmin).then((res) => setPlanOptions(res.data));
  }, [planDetails]);

  useEffect(() => {
    setUsersOption(planOptions?.numberOfUsers.options.find((o) => o.id === numberOfUsers));
    setTransmissionOption(planOptions?.transmissionRate.options.find((o) => o.id === transmissionRate));
    setSmsOption(planOptions?.smsNotifications?.options.find((o) => o.id === smsNotifications));
  }, [numberOfUsers, planOptions, smsNotifications, transmissionRate]);

  const confirmFirstCheckout = (values: ISelectedPlanOption[], redirectToDashboard: boolean, updateByAdmin?: boolean) => {
    const email = updateByAdmin ? updatingAccountInfo?.email ?? "" : AppState?.user?.email ?? "";
    getFirstPaymentLink(
      {
        email: email,
        password: "",
      },
      values,
      redirectToDashboard,
    ).then((link) => {
      if (link && !updateByAdmin) {
        window.location.href = link;
      }

      if (updateByAdmin) {
        showSnackbar(t("billing_info:update_plan.update_by_admin_success"), "success");
        showAppModal(null);
      }
    });
  };

  const submitUpdatePlan = () => {
    const selectedPlanOptions = [
      { id: planDetails?.basePriceOptionId ?? 0, quantity: 1 },
      { id: planDetails?.numberOfDevicesOptionId ?? 0, quantity: planDetails?.numberOfDevices ?? 0 },
      { id: planDetails?.numberOfUsers ?? 0, quantity: 1 },
      { id: planDetails?.smsNotifications ?? 0, quantity: 1 },
      { id: planDetails?.transmissionRate ?? 0, quantity: planDetails?.numberOfDevices ?? 1 },
    ];
    const firstCheckoutAfterDemoSwitch = updateByAdmin
      ? AppState.selectedAccount?.stripe_subscription_id === null || (AppState.selectedAccount?.blocked ?? false)
      : AppState.user?.Account?.stripe_subscription_id === null || (AppState.user?.Account?.blocked ?? false);
    if (firstCheckout || firstCheckoutAfterDemoSwitch) {
      confirmFirstCheckout(selectedPlanOptions, firstCheckoutAfterDemoSwitch, updateByAdmin);
    } else {
      const subscriptionId = updateByAdmin
        ? AppState.selectedAccount?.stripe_subscription_id
        : AppState.user?.Account?.stripe_subscription_id;
      const userId = updateByAdmin ? AppState.selectedAccount?.AdminId : AppState.user?._id;
      updateSubscription(subscriptionId, selectedPlanOptions, userId)
        .then(async () => {
          if (AppState.user && AppState.user.Account && !updateByAdmin) {
            AppState.user.Account.renewal_cost = price;
          } else if (AppState.selectedAccount && updateByAdmin) {
            AppState.selectedAccount.renewal_cost = price;
          }

          showSnackbar(t("billing_info:update_plan.update_success"), "success");

          showAppModal(null);
          return refreshAppState();
        })
        .catch(() => {
          showSnackbar(t("billing_info:update_plan.update_error"), "error");
        });
    }
  };

  const formatPrice = (value: number | string | undefined) => {
    if (value === undefined) {
      return "";
    }
    if (typeof value === "string") {
      value = Number.parseFloat(value);
    }

    const currencySign = updateByAdmin ? AppState.selectedAccount?.Currency?.sign : getCurrencySign();

    return `${currencySign}${value.toFixed(2)}`;
  };

  return (
    <>
      <div>
        <div className="type-large-regular register-form-label">{t("billing_info:update_plan.review_your_information")}</div>
        <Column>
          <div className="type-large-regular register-form-label" style={{ paddingTop: "16px" }}>
            {t("billing_info:update_plan.plan_details")}
          </div>
          <Row className="confirmation-step-second-row-double-width">
            <Column>
              <p className="input-label">{t("billing_info:update_plan.subscription_price")}</p>
              <p>
                {formatPrice(price)} {t("common:monthly")}
              </p>
            </Column>
            <Column>
              <p className="input-label">{t("billing_info:update_plan.includes")}</p>
              <List className="container-full confirmation-step-list">
                <ListItem>
                  <div className="confirmation-step-list-item-content">
                    <span>{t("billing_info:update_plan.base_price")}</span>
                    <span>{formatPrice(planOptions?.basePrice.options[0].price)}</span>
                  </div>
                </ListItem>
                <ListItem>
                  <div className="confirmation-step-list-item-content">
                    <span>{t("billing_info:update_plan.number_of_devices", { count: numberOfDevices || undefined })}</span>
                    <span>
                      {numberOfDevices && planOptions ? formatPrice(planOptions?.numberOfDevices.options[0].price * numberOfDevices) : "-"}
                    </span>
                  </div>
                </ListItem>
                <ListItem>
                  <div className="confirmation-step-list-item-content">
                    <span>
                      {usersOption?.name} {t("billing_info:update_plan.users")}
                    </span>
                    <span>{usersOption?.price ? formatPrice(usersOption.price) : t("billing_info:update_plan.in_plan")}</span>
                  </div>
                </ListItem>
                <ListItem>
                  <div className="confirmation-step-list-item-content">
                    <span>
                      {t("billing_info:update_plan.transmission_rate")} {transmissionOption?.name}
                    </span>
                    <span>
                      {numberOfDevices && transmissionOption?.price ? formatPrice(transmissionOption.price * numberOfDevices) : "-"}
                    </span>
                  </div>
                </ListItem>
                <ListItem>
                  <div className="confirmation-step-list-item-content">
                    <span>{t("billing_info:update_plan.unlimited_email_notifications")}</span>
                    <span>{t("billing_info:update_plan.free")}</span>
                  </div>
                </ListItem>
                {smsOption?.price ? (
                  <ListItem>
                    <div className="confirmation-step-list-item-content">
                      <span>
                        {smsOption?.name} {t("billing_info:update_plan.sms_notifications")}
                      </span>
                      <span>{smsOption?.price ? formatPrice(smsOption.price) : t("billing_info:update_plan.free")}</span>
                    </div>
                  </ListItem>
                ) : null}
              </List>
            </Column>
          </Row>
        </Column>
      </div>
      <StepperActions
        onClickBack={onClickBack}
        onClickNext={submitUpdatePlan}
        lastStep={true}
        buttonDisabled={isConfirming}
        nextButtonMessage={t("common:update")}
      />
    </>
  );
};
