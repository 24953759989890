import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { URLS } from "../URLS";
import { PermissionBlocker } from "../../Components";
import { UserRoles } from "../../Enums";
import { useTranslation } from "react-i18next";

export const UserNav: React.FC<{ value?: "users" | "export" }> = ({ value = "users" }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("users");

  const handleNavigate = (val: string) => {
    if (val === value) {
      return;
    }

    navigate(val === "users" ? URLS.Users : URLS.ExportUsers);
  };

  return (
    <PermissionBlocker role={UserRoles.APP_ADMIN}>
      <ToggleButtonGroup
        exclusive={true}
        onChange={(_e, val) => handleNavigate(val)}
        value={value}
        color="primary"
        className="user-nav-toggle">
        <ToggleButton value="users" className="col-sm-6">
          {t("users:nav.details")}
        </ToggleButton>
        <ToggleButton value="export" className="col-sm-6">
          {t("users:nav.export")}
        </ToggleButton>
      </ToggleButtonGroup>
    </PermissionBlocker>
  );
};
