import { AppState } from "../AppState";

export const UserRoles = {
  APP_ADMIN: "APP_ADMIN",
  ADMIN: "ADMIN",
  GUEST: "Guest",
  USER: "USER",
};

export enum UserRolesHierarchy {
  APP_ADMIN = 0,
  ADMIN = 10,
  USER = 20,
  GUEST = 30,
}

export const getRoleHierarchy = (role: string) => Object.entries(UserRolesHierarchy).find(([key, _value]) => key === role)?.[1] ?? -1;

export const isUserRoleAllowed = (requiredRole: string) => {
  if (!AppState.user?.role) {
    return false;
  } else if (AppState.user.role === UserRoles.APP_ADMIN) {
    return true;
  }

  const userRoleValue = getRoleHierarchy(AppState.user?.role);
  const requiredRoleValue = getRoleHierarchy(requiredRole);

  return userRoleValue <= requiredRoleValue;
};
