import classNames from "classnames";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import ClickAwayListener from "react-click-away-listener";
import { deleteGroupConfig, updateGroup } from "../../Managers/DeviceGroupService";
import { GroupConfigCreateModal } from "./Modals/GroupConfigCreateModal";
import { hasPermission } from "../../Managers";
import { AppState, showAppModal, showSnackbar } from "../../AppState";
import { ConfirmModal } from "../../Modals";
import { useTranslation } from "react-i18next";

interface IGroupConfigProps {}

export const GroupConfig: React.FC<IGroupConfigProps> = observer(({}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);

  const { t } = useTranslation("dashboard");

  const hasEditGroupPermission = AppState.user ? hasPermission(AppState.user, "editDeviceGroup") : false;
  const deviceGroups = AppState.selectedLocation?.Device_groups || [];
  const selectedGroup = selectedGroupId ? deviceGroups.find((group) => group._id === selectedGroupId) : null;

  // TODO: I've seen active_configuration_id referred to as a string or number, which is it?
  const selectedConfig = selectedGroup
    ? selectedGroup.Device_group_alert_configurations.find((config) => config._id === selectedGroup.active_configuration_id)
    : null;

  const handleSelectConfig = (id: null | number) => {
    if (selectedGroup) {
      updateGroup({ ...selectedGroup, active_configuration_id: id })
        .then((r) => {
          showSnackbar(t("dashboard:group_config.update_success"), "success");
          console.log("Update result", r);
          setSelectedGroupId(id);
          // From old code:
          // TODO: update devices alerts state
        })
        .catch((e) => {
          console.log("Update error", e);
          showSnackbar(t("dashboard:group_config.update_error"), "error");

          // From old code:
          // TODO: system alert
        });
    }
  };

  const handleDeleteConfig = () => {
    if (selectedGroup && selectedConfig) {
      deleteGroupConfig(selectedGroup, selectedConfig)
        .then((r) => {
          console.log("Delete result", r);
          showAppModal(null);
          showSnackbar(t("dashboard:group_config.delete_success"), "success");
        })
        .catch((e) => {
          console.log("Delete error", e);
          showSnackbar(t("dashboard:group_config.delete_error"), "error");
          // TODO: The old app didn't catch this error
        });
    } else {
      // TODO: Just silently disappear?
      showAppModal(null);
    }
  };

  const handleConfirmDelete = () => {
    showAppModal(
      <ConfirmModal
        header={t("dashboard:group_config.delete_configuration_header")}
        children={<span>{t("dashboard:group_config.confirmation_message")}</span>}
        confirmText={t("dashboard:group_config.remove_configuration")}
        onConfirm={handleDeleteConfig}
      />,
    );
  };

  const handleCreateConfig = () => {
    if (selectedGroup) {
      showAppModal(<GroupConfigCreateModal selectedGroup={selectedGroup} />);
    }
  };

  if (!selectedGroup) {
    return <></>;
  }

  return (
    <div className="header-row-info">
      <h2 className="header-row-title mod-sub" style={{ overflow: "visible", width: "auto" }}>
        {t("dashboard:group_config.title")}
      </h2>
      <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
        <div className={classNames("dropdown dropdown-placement-right-bottom", { open: menuOpen })}>
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className={classNames("btn-link dropdown-toggle", { disabled: !hasEditGroupPermission })}
            style={{ padding: 0, lineHeight: "20px", display: "inline", letterSpacing: "1px" }}>
            {selectedConfig ? selectedConfig.name : " Not saved "}
            {hasEditGroupPermission ? <i className="fa fa-angle-down" /> : <></>}
          </button>

          <ul className={classNames("dropdown-menu dropdown-menu-right")} role="menu">
            <li>
              <p className="dropdown-menu-title">{t("dashboard:group_config.configurations")}</p>
            </li>

            <li role="menuitem">
              <button
                className={classNames("btn-link dropdown-menu-item", { active: !selectedGroup?.active_configuration_id })}
                onClick={() => handleSelectConfig(null)}>
                {t("dashboard:group_config.not_saved")}
              </button>
            </li>

            {selectedGroup?.Device_group_alert_configurations.map((config) => (
              <button
                key={config._id}
                className={classNames("btn-link dropdown-menu-item", { active: selectedGroup?.active_configuration_id === config._id })}
                onClick={() => handleSelectConfig(config._id)}>
                {config.name}
              </button>
            ))}

            <br />

            <li>
              <p className="dropdown-menu-title">{t("dashboard:group_config.settings")}</p>
            </li>

            <li role="menuitem">
              <button className={classNames("btn-link dropdown-menu-item", { disabled: !selectedConfig })} onClick={handleConfirmDelete}>
                {t("dashboard:group_config.delete")}
              </button>
            </li>

            <li role="menuitem">
              <button className="btn-link dropdown-menu-item" onClick={handleCreateConfig}>
                {t("dashboard:group_config.new")}
              </button>
            </li>
          </ul>
        </div>
      </ClickAwayListener>
    </div>
  );
});
