import React, { useEffect, useState } from "react";
import { isUserPermissionAllowed, isUserRoleAllowed } from "../Enums";

export const PermissionBlocker: React.FC<{
  permission?: string | Array<string>;
  role?: string;
  children: React.ReactNode;
}> = ({ children, permission, role }) => {
  const [roleAllowed, setRoleAllowed] = useState(!role);
  const [permissionAllowed, setPermissionAllowed] = useState(!permission);

  useEffect(() => {
    if (role) {
      setRoleAllowed(isUserRoleAllowed(role));
    }

    if (permission) {
      setPermissionAllowed(isUserPermissionAllowed(permission));
    }
  }, [permission, role]);

  return roleAllowed && permissionAllowed ? <>{children}</> : <></>;
};
