import classNames from "classnames";
import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "@emotion/styled/macro";
import ClickAwayListener from "react-click-away-listener";
import { useLatestNotifications, useLatestSystemNotifications } from "../Managers/NotificationService";
import { AccountMenu } from "./AccountMenu";
import { NotificationDropdown } from "../Views/Alerts";
import { AppState } from "../AppState";
import { Colors } from "../Theme";
import { Badge, Drawer, ListItemButton, ListItemText, Snackbar, Typography } from "@mui/material";
import { isUserRoleAllowed, UserRoles } from "../Enums";
import { URLS } from "../Views/URLS";
import { HelpDropdown } from "../Views/HelpTabs/HelpDropdown";
import { ManagementMenu } from "./ManagementMenu";
import { useTranslation } from "react-i18next";
import { ICON_TOOLTIP_DEFAULT_DELAY, StyledTooltip } from "./StyledTooltip";
import { List, ListItem } from "./List";
import SystemMessageModal from "../Modals/SystemMessageModal";
import { CookiePopup } from "./CookiePopup";

export const PageWrapper = styled.div`
  background-color: ${Colors.darkBlue};
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 64px; //size of a header
  justify-content: center;

  @media only screen and (max-width: 767px) {
    padding-top: 48px;
  }
`;

export const Column = styled.div`
  align-items: flex-start;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
`;

export const Filler = styled.div`
  flex: 1;
  display: flex;
`;

const HeaderLink = styled(NavLink)`
  color: #80c2ff;
  font-size: 16px;
  line-height: 18px;
  font-weight: 900;
  margin-right: 36px;
  position: relative;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;

  &.active,
  &:hover {
    color: #fff;
  }

  &::after {
    width: 100%;
    height: 1px;
    background-color: #eee;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scale(0);
    transform-origin: left;
    transition: transform 0.3s cubic-bezier(0.15, 0.82, 0.52, 1.1);
  }

  &.active::after {
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.15, 0.82, 0.52, 1.1);
  }
`;

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1400;
  background: #000000cc;
`;

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

// Splitting this out to its own component was just an easy way to prevent it from pulling alert data while not authenticated
const AlertsMenu: React.FC = observer(() => {
  const [showing, setShowing] = useState(false);
  const alerts = useLatestNotifications();
  const notifications = useLatestSystemNotifications();
  const totalAlerts =
    (alerts.data || []).filter((notification) => !notification.is_resolved).length + notifications?.filter((n) => !n.read).length;

  const { t } = useTranslation("common");

  return (
    <ClickAwayListener onClickAway={() => setShowing(false)}>
      <li className={classNames("bapi-header-nav-item dropdown", { open: showing })}>
        <StyledTooltip title={t("common:notification_menu")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
          <button className="bapi-header-nav-toggle btn btn-icon" onClick={() => setShowing(!showing)}>
            <span className="sr-only">{t("common:alert_log")}</span>
            <Badge color="warning" badgeContent={totalAlerts < 10 ? totalAlerts : "9+"}>
              <i className="fa fa-bell" />
            </Badge>
          </button>
        </StyledTooltip>

        <NotificationDropdown isOpen={showing} setIsOpen={setShowing} onClose={() => setShowing(false)} />
      </li>
    </ClickAwayListener>
  );
});

const HelpMenu: React.FC = observer(() => {
  const [showing, setShowing] = useState(false);
  const { t } = useTranslation("common");

  return (
    <ClickAwayListener onClickAway={() => setShowing(false)}>
      <li className={classNames("bapi-header-nav-item dropdown u-desktop-only", { open: showing })}>
        <StyledTooltip title={t("common:help_menu")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
          <button className="bapi-header-nav-toggle btn btn-icon" onClick={() => setShowing(!showing)}>
            <span className="sr-only">{t("common:help")}</span>
            <i className="fa fa-question-circle" />
          </button>
        </StyledTooltip>
        <HelpDropdown />
      </li>
    </ClickAwayListener>
  );
});

// Splitting this out to its own component was just an easy way to prevent it from pulling alert data while not authenticated
const ProfileMenu: React.FC = observer(() => {
  const [showing, setShowing] = useState(false);
  const { t } = useTranslation("common");

  return (
    <ClickAwayListener onClickAway={() => setShowing(false)}>
      <li className={classNames("bapi-header-nav-item dropdown", { open: showing })}>
        <StyledTooltip title={t("common:account_menu")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
          <button className="bapi-header-nav-toggle btn btn-icon" onClick={() => setShowing(!showing)}>
            <i className="fa fa-user-circle-o" />
            <span className="sr-only">{t("common:user_settings")}</span>
          </button>
        </StyledTooltip>

        <AccountMenu />
      </li>
    </ClickAwayListener>
  );
});

const AdminMenu: React.FC = observer(() => {
  const [showing, setShowing] = useState(false);
  const { t } = useTranslation("common");

  return (
    <ClickAwayListener onClickAway={() => setShowing(false)}>
      <li className={classNames("bapi-header-nav-item dropdown", { open: showing })}>
        <StyledTooltip title={t("common:management_menu")} enterDelay={ICON_TOOLTIP_DEFAULT_DELAY}>
          <button className="bapi-header-nav-toggle btn btn-icon" onClick={() => setShowing(!showing)}>
            <i className="fa fa-cogs" />
            <span className="sr-only">{t("common:admin_menu")}</span>
          </button>
        </StyledTooltip>
        <ManagementMenu />
      </li>
    </ClickAwayListener>
  );
});

export const Page: React.FC = observer(({ children }) => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const getSnackBarColor = () => {
    switch (AppState.snackBarMessage.type) {
      case "warning":
        return Colors.warning;
      case "success":
        return Colors.success;
      case "error":
      default:
        return Colors.alert;
    }
  };

  const navItems = [
    {
      url: URLS.Dashboard,
      name: t("common:dashboard"),
    },
    {
      url: URLS.Users,
      name: t("common:users"),
      block: !isUserRoleAllowed(UserRoles.ADMIN),
    },
    {
      url: URLS.Alerts,
      name: t("common:alerts"),
    },
    {
      url: URLS.AlertsHistory,
      name: t("common:alerts_history"),
    },
    { url: URLS.Watchlists, name: t("common:watchlists") },
  ];

  return (
    <PageWrapper className={classNames({ "nav-open": mobileNavIsOpen })}>
      <ClickAwayListener onClickAway={() => setMobileNavIsOpen(false)}>
        <header className={classNames("bapi-header", { unauthenticated: !AppState.isAuthenticated })}>
          {AppState.isAuthenticated ? (
            <div className="left-rail-toggle-wrapper">
              <div
                className={classNames("left-rail-toggler btn btn-icon", { open: mobileNavIsOpen })}
                onClick={() => setMobileNavIsOpen(!mobileNavIsOpen)}>
                <i className="icon" />
                <span className="sr-only">{t("common:menu")}</span>
              </div>
            </div>
          ) : null}

          <div className="bapi-logo-holder">
            <NavLink to={URLS.Dashboard} className="bapi-logo">
              <img src="/bapi-logo.png" alt="BAPI WAM" className="bapi-logo-image" />
            </NavLink>
          </div>

          {AppState.isAuthenticated ? (
            <nav className="bapi-header-nav">
              <ul className="bapi-header-nav-links u-desktop-only">
                {navItems.map((item, index) =>
                  !item.block ? (
                    <li className="bapi-header-nav-item" key={index}>
                      <HeaderLink to={item.url}>{item.name}</HeaderLink>
                    </li>
                  ) : null,
                )}
              </ul>
              <Drawer className="left-rail-drawer" open={mobileNavIsOpen} onBackdropClick={() => setMobileNavIsOpen(false)}>
                <List>
                  {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding sx={{ display: "block" }}>
                      <ListItemButton onClick={() => navigate(item.url)}>
                        <ListItemText primary={<Typography variant="h5">{item.name.toUpperCase()}</Typography>} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
                {AppState.selectedLocation ? (
                  <div style={{ padding: 20 }}>
                    <div className="u-text-bold">{AppState.selectedLocation.name}</div>
                    <div>{AppState.selectedLocation.address}</div>
                  </div>
                ) : null}
              </Drawer>

              <ul className="bapi-header-nav-toggles">
                <HelpMenu />
                {isUserRoleAllowed(UserRoles.APP_ADMIN) && <AdminMenu />}
                <AlertsMenu />
                <ProfileMenu />
              </ul>
            </nav>
          ) : null}
        </header>
      </ClickAwayListener>
      <Content>{children}</Content>

      {AppState.isAuthenticated && <SystemMessageModal />}
      {<CookiePopup />}

      <Snackbar
        color="error"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        ContentProps={{ sx: { background: getSnackBarColor() } }}
        open={!!AppState.snackBarMessage.message}
        autoHideDuration={5000}
        message={AppState.snackBarMessage.message}
        onClose={() => (AppState.snackBarMessage.message = "")}
      />
      {AppState.appModal ? <ModalOverlay>{AppState.appModal}</ModalOverlay> : <></>}
      {AppState.chipModal ? (
        <ModalOverlay className={classNames("modal-overlay", AppState.chipModalConfig?.hidden ? "u-display-none" : "")}>
          {AppState.chipModal}
        </ModalOverlay>
      ) : (
        <></>
      )}
    </PageWrapper>
  );
});
