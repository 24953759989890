import { observer } from "mobx-react-lite";
import React from "react";
import { URLS } from "../URLS";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserRoles } from "../../Enums";
import { PermissionBlocker } from "../../Components";

interface IMobileUserHeader {
  actionRow?: JSX.Element;
  currentPage: string;
}

export const MobileUserHeader: React.FC<IMobileUserHeader> = observer(({ actionRow, currentPage }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("users");

  return (
    <header className="u-mobile-only user-mobile-header">
      <PermissionBlocker role={UserRoles.APP_ADMIN}>
        <div className="users-tab-root">
          <div className="users-tab-scroller">
            <div className="users-tab-container">
              <button className={`users-tab-button ${currentPage === "users" ? "selected" : ""}`} onClick={() => navigate(URLS.Users)}>
                {" "}
                {t("users:nav.details")} <span className="users-tab-touch"></span>
              </button>
              <button
                className={`users-tab-button ${currentPage === "export" ? "selected" : ""}`}
                onClick={() => navigate(URLS.ExportUsers)}>
                {" "}
                {t("users:nav.export")} <span className="users-tab-touch"></span>
              </button>
            </div>
          </div>

          <span className="users-tab-indicator"></span>
        </div>
      </PermissionBlocker>
      <div className="mobile-control-buttons">{actionRow}</div>
    </header>
  );
});
