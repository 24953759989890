import { observer } from "mobx-react-lite";
import { Modal } from "../../../Components/Modal";
import { useLocations } from "../../../Managers/API";
import { useTranslation } from "react-i18next";
import { AppState, setSelectedLocation, showAppModal } from "../../../AppState";
import { Column, List, ListItem, Row } from "../../../Components";
import classNames from "classnames";
import React from "react";

export const PickLocationModal: React.FC = observer(() => {
  const { t } = useTranslation(["dashboard", "common"]);
  const locationsQuery = useLocations();

  return (
    <Modal
      title={t("dashboard:pick_location")}
      buttons={
        <>
          <button onClick={() => showAppModal(null)} className="btn btn-plain u-text-teal">
            {t("common:cancel")}
          </button>
        </>
      }>
      <List>
        {locationsQuery.data?.map((l) => (
          <ListItem
            onClick={() => {
              window.localStorage.setItem("lastVisited", "" + l._id);
              setSelectedLocation(l);
              showAppModal(null);
            }}
            className={classNames("selectable", l._id === AppState.selectedLocation?._id ? "selected" : "")}>
            <Row>
              <Column>
                <div>{l.name}</div>
                <div>{l.address ?? "-"}</div>
              </Column>
              {l._id === AppState.selectedLocation?._id ? <i className="fa fa-check u-text-teal" /> : null}
            </Row>
          </ListItem>
        ))}
      </List>
    </Modal>
  );
});
