import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import { Modal } from "../../../Components/Modal";
import { showAppModal, showSnackbar } from "../../../AppState";
import React from "react";
import { ValidationMessages } from "../../../Enums";
import { FormFieldFile } from "../../../Components/FileInput";
import { importFromXLSX } from "../../../Managers";
import { saveTranslations } from "../../../Managers/TranslationsService";
import { ITranslation } from "../../../Managers/Types";
import { reloadResources } from "i18next";

const getExtension = (filename?: string) => {
  const parts = filename?.split(".") ?? [];
  return parts[parts.length - 1];
};

export const UploadTranslationsModal: React.FC<{ language: string }> = ({ language }) => {
  const { t } = useTranslation(["translations", "common"]);

  const validationSchema = yup.object({
    file: yup
      .mixed()
      .required(t(ValidationMessages.REQUIRED))
      .test("file-type", t("common:file_type_error", { types: "XLSX" }), (file) => !!file && getExtension(file?.name) === "xlsx"),
  });

  const initialValues = { file: undefined };

  const handleSubmit = (values: { file: any }, actions: FormikHelpers<{ file: any }>) => {
    const reader = new FileReader();
    reader.readAsBinaryString(values.file);
    reader.onload = (event) => {
      try {
        const data = importFromXLSX(event.target?.result);
        const mappedData: ITranslation[] = data.map((entry: any) => {
          const entryKeys = Object.keys(entry);
          const [namespace, ...rest] = (entry as any)[entryKeys[0]].split(":");
          return { key: rest.join(":"), language, label: (entry as any)[entryKeys[2]], namespace };
        });
        saveTranslations(language, mappedData)
          .then(() => {
            showAppModal(null);
            showSnackbar(t("translations:upload_translations.upload_success"), "success");
            reloadResources();
          })
          .catch(() => {
            actions.setSubmitting(false);
            showSnackbar(t("translations:upload_translations.upload_error"), "error");
          });
      } catch (e) {
        actions.setSubmitting(false);
        showSnackbar(t("translations:upload_translations.parsing_error"), "error");
      }
    };
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ submitForm, isSubmitting }) => (
        <Modal
          title={t("translations:upload_translations.title")}
          buttons={
            <>
              <button className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                {t("common:cancel")}
              </button>
              <button className="btn btn-primary" disabled={isSubmitting} onClick={submitForm}>
                {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                {t("common:save_changes")}
              </button>
            </>
          }>
          <FormFieldFile accept=".xlsx" displayError={true} name="file" type="file" />
        </Modal>
      )}
    </Formik>
  );
};
