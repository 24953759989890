import React from "react";
import { observer } from "mobx-react-lite";
import { LeftRail, useScreenMode } from "../../Components";
import { Drawer, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { WindowSize } from "../../Enums";
import { useTranslation } from "react-i18next";
import {SetURLSearchParams} from "react-router-dom";
import {FirmwareTypeEnum} from "./FirmwareUploadPage";


interface IUploadLeftRail {
  firmwareType: FirmwareTypeEnum;
  setSearchParams: SetURLSearchParams;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UploadLeftRail: React.FC<IUploadLeftRail> = observer(({ firmwareType, setSearchParams, drawerOpen, setDrawerOpen }) => {
  const { t } = useTranslation("firmware_upload");

  const mode = useScreenMode();

  const leftRail = (
    <LeftRail
      header={
        <Stack direction="column" width="100%" paddingTop="0.5em">
          <h1 className="left-rail-title">{t("firmware_upload:left-rail.title")}</h1>
          <div className="drawer-header">{}</div>
          <ToggleButtonGroup
            exclusive={true}
            onChange={(_e, val) => {
              if (val) {
                setSearchParams({ type: val });
              }
            }}
            value={firmwareType}
            color="primary"
            className="user-nav-toggle">
            <ToggleButton value={FirmwareTypeEnum.GATEWAY} sx={{ width:"50%" }}>
              {t("firmware_upload:left_rail.type_button.gateway")}
            </ToggleButton>
            <ToggleButton value={FirmwareTypeEnum.DEVICE} sx={{ width:"50%" }}>
              {t("firmware_upload:left_rail.type_button.device")}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      }
    />
  );

  if (mode === WindowSize.MOBILE || mode === WindowSize.TABLET) {
    return (
      <Drawer onBackdropClick={() => setDrawerOpen(false)} open={drawerOpen}>
        {leftRail}
      </Drawer>
    );
  }

  return <>{leftRail}</>;
});
