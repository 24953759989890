import * as XLSX from "xlsx";

export const exportToXLSX = (
  filename: string,
  rows: Record<string, number | string | null | boolean | undefined>[],
  headers?: string[],
): void => {
  const workSheet = XLSX.utils.aoa_to_sheet([[]]);

  if (headers) {
    /* First row */
    XLSX.utils.sheet_add_aoa(workSheet, [headers], { origin: "A1" });
  }

  rows.forEach((row, idx) => {
    XLSX.utils.sheet_add_aoa(workSheet, [Object.values(row)], { origin: headers ? -1 : idx });
  });

  let workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, "sheet");
  XLSX.writeFile(workBook, `${filename}.xlsx`);
};

export const importFromXLSX = (
  data?: ArrayBuffer | string | null,
  type: "base64" | "binary" | "buffer" | "file" | "array" | "string" = "binary",
) => {
  const readData = XLSX.read(data, { type });
  return XLSX.utils.sheet_to_json(readData.Sheets[readData.SheetNames[0]]);
};
