import { List as MuiList, ListItem as MuiListItem, ListItemProps, ListProps } from '@mui/material';

export interface IListProps extends ListProps {
  listStyle?: 'disc' | 'circle' | string;
}

export const List: React.FC<IListProps> = (props) => {
  const style = props.style ?? {};

  if (props.listStyle) {
    style.listStyle = props.listStyle;
  } else {
    style.listStyle = 'disc';
  }
  
  return <MuiList {...props} style={style}/>;
};

export interface IListItemProps extends ListItemProps {}

export const ListItem: React.FC<IListItemProps> = (props) => {
  return <MuiListItem {...props}/>;
};
