import { showAppModal, showSnackbar } from "../../AppState";
import { ConfirmModal } from "../ConfirmModal";
import React, { useState } from "react";
import { sendNotificationEmails } from "../../Managers/ManageSystemMessageService";
import { useTranslation } from "react-i18next";
import { ISystemMessage } from "../../Managers/Types";

interface ISendEmailsButton {
  selectedSystemMessage: ISystemMessage;
}

export const SendEmailsButton: React.FC<ISendEmailsButton> = ({ selectedSystemMessage }) => {
  const { t } = useTranslation(["system_message"]);

  const [isSendingEmails, setIsSendingEmails] = useState(false);

  const handleSendSystemMessageMails = () => {
    setIsSendingEmails(true);
    sendNotificationEmails(selectedSystemMessage._id!)
      .then((r) => {
        if (r?.message) {
          showSnackbar(r.message, "success");
        } else {
          showSnackbar(t("system_message:emails_sent_success"), "success");
        }
        console.log("System message emails result", r);
        showAppModal(null);
      })
      .catch((e) => {
        console.log("Error sending system message emails", e);
        showSnackbar(t("system_message:emails_sent_error"), "error");
      })
      .finally(() => setIsSendingEmails(false));
  };

  return (
    <div className="row" style={{ marginTop: 20, paddingBottom: "1rem" }}>
      <p className="input-label">{t(`system_message:send_notification`)}</p>
      <button
        className="btn btn-primary"
        onClick={() => {
          showAppModal(
            <ConfirmModal
              header={t(`system_message:send_notification`)}
              children={<p>{t(`system_message:send_notification_body`)}</p>}
              confirmText={t(`system_message:send_notification_confirm`)}
              onConfirm={handleSendSystemMessageMails}
            />,
          );
        }}
        disabled={isSendingEmails || selectedSystemMessage._id === undefined}>
        {isSendingEmails ? <i className="fa fa-spin fa-circle-o-notch" /> : <></>}
        {t(`system_message:send_notification_confirm`).toUpperCase()}
      </button>
      <br />
    </div>
  );
};
