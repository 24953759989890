import { AppState } from "../AppState";
import { IAlertConfig } from "./Alert.model";
import * as UnitsService from "./UnitsService";
import * as MeasurementService from "./MeasurementService";
import { IAlert } from "./Types";
import { t } from "i18next";
import { isBatteryLowAlert } from "./AlertService";

export const transform = (value: IAlertConfig | IAlert | null): string => {
  if (!value) {
    // TODO: Discuss. I think Angular would have ended up blank
    return t("common:select_options.not_transmitting");
  }

  let condition: string = "";

  if (isBatteryLowAlert(value)) {
    return value.protocol;
  }

  const Sensor_type = AppState.sensorTypes.find((type) => type._id === value?.SensorTypeId || 0);
  const isContactSensor = Sensor_type?.name === "Contact";
  if (value.is_notify_on_non_transmitting && !isContactSensor) {
    return t("common:select_options.not_transmitting");
  }
  if (!Sensor_type) return "";

  if (Sensor_type.type === "RANGE") {
    let unit = UnitsService.transform(value.selected_units, []);

    if (value.min !== null) {
      let displayMin = MeasurementService.convertTemperature(+value.min, "celcius", value.selected_units);
      condition += t("common:value") + " < " + displayMin + " " + unit;
    }

    if (value.max !== null && value.min !== null) {
      condition += ` ${t("common:or")} `;
    }

    if (value.max !== null) {
      let displayMax = MeasurementService.convertTemperature(+value.max, "celcius", value.selected_units);
      condition += t("common:value") + " > " + displayMax + " " + unit;
    }
  } else if (Sensor_type.type === "BOOLEAN") {
    if (value.max !== null) {
      const maxRangeUnit = Sensor_type.ranges[Sensor_type.units[0]][+value.max];
      condition += t(`common:select_options.${maxRangeUnit.toLowerCase()}`);
    } else if (value.min !== null) {
      const minRangeUnit = Sensor_type.ranges[Sensor_type.units[0]][+value.min];
      condition += t(`common:select_options.${minRangeUnit.toLowerCase()}`);
    }
  }

  if (value.is_notify_on_non_transmitting && isContactSensor && condition.length) {
    return t("common:select_options.not_transmitting") + ` (${condition})`;
  }

  return condition;
};

export const alertConditionTransform = (value: IAlertConfig | IAlert | null) => transform(value);
