import { Modal } from "../Components/Modal";
import { showAppModal } from "../AppState";
import React from "react";
import "./ManageHelpModal.scss";
import { useTranslation } from "react-i18next";

const HelpTabModal: React.FC<{ children: React.ReactNode; title: string }> = ({ children, title }) => {
  const { t } = useTranslation("common");

  const buttons = (
    <button className="btn btn-primary" onClick={() => showAppModal(null)}>
      {t("common:ok")}
    </button>
  );

  return (
    <Modal title={title} buttons={buttons}>
      <div style={{ height: "30vh", overflowY: "auto" }} className="help-tab-modal">
        {children}
      </div>
    </Modal>
  );
};

export default HelpTabModal;
