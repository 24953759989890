import { Formik } from "formik";
import * as yup from "yup";
import React from "react";
import { setDeviceGroup, showAppModal, showSnackbar } from "../../../AppState";
import { FormFieldText } from "../../../Components";
import { updateGroup } from "../../../Managers/DeviceGroupService";
import { IDeviceGroup } from "../../../Managers/Types";
import { Modal } from "../../../Components/Modal";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  name: yup.string().max(25),
});

export const EditDeviceGroupModal: React.FC<{
  group: IDeviceGroup;
  setGroup: (group: IDeviceGroup, clearFilters: boolean) => void;
}> = ({ group, setGroup }) => {
  const { t } = useTranslation(["dashboard", "common"]);
  const handleSubmit = (values: any) => {
    const deviceGroup: IDeviceGroup = { ...group, name: values.name };

    updateGroup(deviceGroup)
      .then(() => {
        setDeviceGroup(deviceGroup);
        setGroup(deviceGroup, true);
        showAppModal(null);
        showSnackbar(t("dashboard:edit_device_group.update_success", { name: deviceGroup.name }), "success");
      })
      .catch((e) => {
        console.error(e);
        showSnackbar(t("dashboard:edit_device_group.update_error", { name: deviceGroup.name }), "error");
      });
  };

  const initialValues = {
    name: group.name,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
      {({ isSubmitting, isValid, submitForm }) => {
        return (
          <Modal
            className="modal-sm"
            title={t("dashboard:edit_device_group.title")}
            buttons={
              <>
                <button type="button" className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSubmitting}>
                  {t("common:cancel")}
                </button>
                <button type="button" className="btn btn-primary" onClick={submitForm} disabled={!isValid || isSubmitting}>
                  {isSubmitting ? <i className="fa fa-circle-o-notch fa-spin" /> : <></>}
                  {t("dashboard:edit_device_group.save_button")}
                </button>
              </>
            }>
            <form>
              <div className="row">
                <div className="col-xs-12">
                  <FormFieldText
                    name="name"
                    label={t("dashboard:edit_device_group.name")}
                    placeholder={t("dashboard:edit_device_group.name_placeholder")}
                  />
                </div>
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
};
