import React, { useState } from "react";
import { Button, Snackbar } from "@mui/material";
import { Modal } from "../Components/Modal";
import { closeNotification } from "../Managers/ManageSystemMessageService";
import { IDisplaySystemMessage } from "../Managers/Types";
import { Colors } from "../Theme";
import { ModalOverlay } from "../Components";
import { useTranslation } from "react-i18next";
import { AppState } from "../AppState";

const SystemMessageModal = () => {
  const user = AppState.user;

  const [messagesToShow, setMessagesToShow] = useState<IDisplaySystemMessage[]>(user?.System_messages ?? []);

  const { t } = useTranslation(["system_message", "common"]);

  const handleClose = (msg: IDisplaySystemMessage) => {
    if (!user) {
      return;
    }
    closeNotification(msg._id)
      .then(() => {
        const messagesCopy = messagesToShow.slice();
        const newMessages = messagesCopy.filter((message) => message._id !== msg._id);

        user.System_messages = newMessages;
        setMessagesToShow(newMessages);
      })
      .catch((error) => console.error("Error closing notification:", error));
  };

  return (
    <>
      {messagesToShow.map((msg) => {
        if (msg.name === "maintenance") {
          return (
            <Snackbar
              color="error"
              className="maintenance-notification-snackbar"
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              ContentProps={{ sx: { background: Colors.alert, fontSize: 15 } }}
              message={msg.message}
              action={
                <button className="close-button" onClick={() => handleClose(msg)}>
                  x
                </button>
              }
            />
          );
        }
        return (
          <ModalOverlay>
            <Modal
              title={msg.name}
              buttons={
                <Button variant="contained" onClick={() => handleClose(msg)}>
                  {t("common:ok")}
                </Button>
              }>
              <div dangerouslySetInnerHTML={{ __html: msg.message }} />
            </Modal>
          </ModalOverlay>
        );
      })}
    </>
  );
};

export default SystemMessageModal;
