import React, { forwardRef, useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./RichEditor.scss";

interface IRichEditorProps {
  className?: string;
  readonly?: boolean;
  defaultValue?: string;
  onTextChange: (text: string) => void;
}

// Editor is an uncontrolled React component
export const RichEditor = forwardRef<HTMLDivElement | undefined, IRichEditorProps>(
  ({ readonly, defaultValue, onTextChange, className = "" }, ref) => {
    const containerRef = useRef(null);
    const [quill, setQuill] = useState<Quill>();

    useEffect(() => {
      if (quill) {
        quill.setContents(quill.clipboard.convert({ html: defaultValue }));
      }
    }, [defaultValue, quill]);

    useEffect(() => {
      (ref as any)?.current?.enable(!readonly);
    }, [ref, readonly]);

    useEffect(() => {
      const container = containerRef.current;

      if (container) {
        const editorContainer = (container as any).appendChild((container as any).ownerDocument.createElement("div"));
        const q = new Quill(editorContainer, {
          theme: "snow",
          modules: {
            toolbar: [
              [{ header: [1, 2, 3, 4, false] }],
              ["bold", "italic", "underline"],
              [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
              [{ align: [] }, { color: [] }, { background: [] }],
              [{ script: "super" }, { script: "sub" }],
              ["link"],
              ["code-block", "clean"],
            ],
          },
        });

        (ref as any).current = q;

        q.on(Quill.events.TEXT_CHANGE, () => onTextChange(q.getSemanticHTML()));

        setQuill(q);
      }

      return () => {
        (ref as any).current = null;
        (container as any).innerHTML = "";
      };
    }, [ref]);

    return (
      <div className={className} ref={containerRef}></div>
    );
  },
);
