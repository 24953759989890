import React, { FormEvent, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Stack, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { RichEditor } from "../../Components/RichEditor";
import { showSnackbar } from "../../AppState";
import { useTranslation } from "react-i18next";
import { TOptionalMessage } from "../../Managers/Types";

interface DeviceFirmwareUploadFormProps {
  formTitle: string;
  uploadFirmwareAsync: (formData: FormData) => Promise<TOptionalMessage>;
  submitButtonText: string;
}

enum FileType {
  FIRMWARE = "firmware",
  SIGNATURE = "signature",
}

const ALLOWED_FIRMWARE_EXTENSIONS = "gbl";
const ALLOWED_SIGNATURE_EXTENSIONS = "txt,sig";

export const FirmwareUploadForm: React.FC<DeviceFirmwareUploadFormProps> = observer(
  ({ formTitle, uploadFirmwareAsync, submitButtonText }) => {
    const { t } = useTranslation(["firmware_upload"]);

    const [firmwareFile, setFirmwareFile] = useState<File | null>(null);
    const [signatureFile, setSignatureFile] = useState(null);

    const [changelogText, setChangelogText] = useState("");

    const quillRef = useRef();

    const clearFilesAfterSave = () => {
      console.log("clear files after save");

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    };

    const handleSubmit = async (e: FormEvent) => {
      e.preventDefault();

      if (!firmwareFile) {
        showSnackbar(t("firmware_upload:form_validation.no_firmware_file"), "warning");
        return;
      }

      if (!signatureFile) {
        showSnackbar(t("firmware_upload:form_validation.no_signature_file"), "warning");
        return;
      }

      const formData = new FormData();

      formData.append("changelog", changelogText);

      formData.append("firmwareFile", firmwareFile);
      formData.append("signatureFile", signatureFile);

      uploadFirmwareAsync(formData)
        .then((response) => {
          if (response?.message) {
            showSnackbar(t(response.message), "success");
          }
          clearFilesAfterSave();
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            showSnackbar(t(err.response.data.message), "error");
          } else {
            showSnackbar(t("firmware_upload:submit.unidentified_error"), "error");
          }
        });
    };

    const validateFile = (e: any, fileType: FileType) => {
      if (!e.target.files.length) {
        setFirmwareFile(null);
        return false;
      }

      const allowedExtensionsEnvVar = fileType === FileType.FIRMWARE ? ALLOWED_FIRMWARE_EXTENSIONS : ALLOWED_SIGNATURE_EXTENSIONS;

      const allowedExtensions = allowedExtensionsEnvVar.split(",");
      const file = e.target.files[0];
      const fileExtension = file.name.split(".")[1];

      if (!allowedExtensions.includes(fileExtension)) {
        e.target.value = null;
        setSignatureFile(null);
        showSnackbar(`Allowed extensions are: ${allowedExtensions}`, "warning");
        return false;
      }

      return true;
    };

    // TODO find event type for file change in input
    const handleSignatureFileChange = (e: any) => {
      if (validateFile(e, FileType.SIGNATURE)) {
        setSignatureFile(e.target.files[0]);
      } else {
        setSignatureFile(null);
      }
    };

    // TODO find event type for file change in input
    const handleFirmwareFileChange = (e: any) => {
      if (validateFile(e, FileType.FIRMWARE)) {
        setFirmwareFile(e.target.files[0]);
      } else {
        setFirmwareFile(null);
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <Typography variant="h6">{t(formTitle)}</Typography>
        </Stack>

        <Stack spacing={2}>
          <Typography>{t("firmware_upload:form_field_name.firmware_file")}</Typography>
          <TextField
            variant="standard"
            type="file"
            onChange={handleFirmwareFileChange}
            sx={{
              "& .MuiInputBase-input": { WebkitTextFillColor: "#0068b3 !important" },
            }}
          />
        </Stack>

        <Stack mt={5} mb={2}>
          <Typography mb={2}>{t("firmware_upload:form_field_name.file_signature")}</Typography>
          <TextField
            variant="standard"
            type="file"
            onChange={handleSignatureFileChange}
            sx={{
              "& .MuiInputBase-input": { WebkitTextFillColor: "#0068b3 !important" },
            }}
          />
        </Stack>

        <Stack spacing={2} mt={5} mb={2}>
          <Typography>{t("firmware_upload:form_field_name.changelog")}</Typography>
          <RichEditor className="text-editor" onTextChange={setChangelogText} ref={quillRef} />
        </Stack>

        <Button variant="contained" type="submit">
          {t(submitButtonText)}
        </Button>
      </form>
    );
  },
);
