import { observer } from "mobx-react-lite";
import React from "react";
import { DrawerIcon } from "../../icon";
import { useScreenMode } from "../../Components";
import { WindowSize } from "../../Enums";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";

interface IFirmwareUploadPageHeader {
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FirmwareUploadPageHeader: React.FC<IFirmwareUploadPageHeader> = observer(({ setDrawerOpen }) => {
  const { t } = useTranslation("firmware_upload");
  const mode = useScreenMode();

  const pageTitle = t('firmware_upload:page_title');

  if (mode === WindowSize.DESKTOP) {
    return <Box component="h1" className="header-row-title" padding="1em 1em 0">
      { pageTitle }
    </Box>;
  }

  return (
    <Box component="h1" className="header-row-title" paddingLeft="0.5em">
      <button onClick={() => setDrawerOpen((prevState) => !prevState)} className="btn btn-icon">
        <DrawerIcon />
      </button>

      { pageTitle }
    </Box>
  );
});
