import { ILineChartValue, ISetPoint, Stepper, TimeSeriesLineChart } from "../../Components";
import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "../../Components/Modal";
import { useTranslation } from "react-i18next";
import { ReportTypePicker } from "./ReportTypePicker";
import moment from "moment";
import { AppState, showAppModal, showSnackbar } from "../../AppState";
import { ReportLocationPicker } from "./ReportLocationPicker";
import { IExportPreset, ILocation, ISensor } from "../../Managers/Types";
import { ReportSensorPicker } from "./ReportSensorPicker";
import { IReportOptions, ReportOptions } from "./ReportOptions";
import classNames from "classnames";
import { ConfirmModal } from "../ConfirmModal";
import "./DeviceReportModal.scss";
import {
  addPreset,
  getConverts,
  getDeviceSensorData,
  getIntervalOptions,
  getNameSlug,
  myOrder,
  prepareChartDataSet,
  saveReports,
  updatePreset,
  useDevicesWithProps,
} from "../../Managers";
import { useFormik } from "formik";
import { IReportExportOptions, ReportExport } from "./ReportExport";
import * as yup from "yup";
import { isUserPermissionAllowed, PermissionEnum, ValidationMessages } from "../../Enums";
import { PointsOfInterestSetup } from "./PointsOfInterestSetup";
import { PresetPicker } from "./PresetPicker";
import { ReportRangePicker } from "./ReportRangePicker";
import { getLocations } from "../../Managers/API";
import { IReportModalStateProps, reportModalCleanup, ReportModalState, ReportMode, updateReportModalState } from "./ReportModalState";

export interface IChartDataProps {
  sensor: ISensor;
  data?: ILineChartValue[];
  setPoints?: ISetPoint[];
}

const IMAGE_DIMENSIONS = { width: 535, height: 200 };

export const DeviceReportModal: React.FC = () => {
  const [step, setStep] = useState(ReportModalState.step);
  const [mode, setMode] = useState<ReportMode>(ReportModalState.mode);
  const [startDate, setStartDate] = useState<Date>(ReportModalState.startDate);
  const [endDate, setEndDate] = useState<Date>(ReportModalState.endDate);
  const [location, setLocation] = useState<ILocation | undefined>(ReportModalState.location);
  const [selectedSensors, setSelectedSensors] = useState<IChartDataProps[]>(ReportModalState.sensors);
  const [options, setOptions] = useState<IReportOptions>(ReportModalState.options);
  const [renderedIds, setRenderedIds] = useState<number[]>([]);
  const [images, setImages] = useState<Record<string, string>>({});
  const [graphReadyToExport, setGraphReadyToExport] = useState(false);
  const [renderGraphs, setRenderGraphs] = useState(false);
  const [savingGraphs, setSavingGraphs] = useState(false);
  const [preset, setPreset] = useState<IExportPreset | undefined>(ReportModalState.preset);
  const [editPreset, setEditPreset] = useState(ReportModalState.editPreset);
  const [showLoader, setShowLoader] = useState(false);
  const [locations, setLocations] = useState<ILocation[]>(myOrder(AppState.user?.Locations ?? [], "name"));

  const updateModalState = () =>
    updateReportModalState({
      step,
      mode,
      startDate,
      endDate,
      location,
      sensors: selectedSensors,
      preset,
      editPreset,
      options,
    } as IReportModalStateProps);

  useEffect(() => () => updateModalState(), [step, mode, startDate, endDate, location, selectedSensors, preset, editPreset, options]);

  const deviceQuery = useDevicesWithProps(["_id", "serial_number", "name", "is_online", "GatewayId", "location_note"], true, true, true);

  useEffect(() => {
    if (mode === ReportMode.ALERTS) {
      setOptions({ ...options, include_alert_history: true, include_alert_notes: true, include_alert_protocol: true });
    }
  }, [mode]);

  useEffect(() => {
    if (isUserPermissionAllowed(PermissionEnum.EDIT_LOCATION)) {
      getLocations().then((res) => setLocations(myOrder(res, "name")));
    }
  }, []);

  useEffect(() => {
    console.warn("renderedIds", renderedIds);
    if (renderedIds.length === selectedSensors.length && !savingGraphs && selectedSensors.length) {
      setSavingGraphs(true);
      if (exportForm.values.saveAsPreset) {
        savePreset().then();
      }

      if (mode === ReportMode.POINTS_OF_INTEREST && !graphReadyToExport) {
        return;
      }

      setTimeout(() => doSaveReports(), 1000);
    }
  }, [renderedIds]);

  useEffect(() => {
    if (mode === ReportMode.POINTS_OF_INTEREST && graphReadyToExport) {
      doSaveReports();
    }
  }, [graphReadyToExport]);

  async function doSaveReports() {
    await saveReports(
      exportForm.values,
      selectedSensors.filter((d) => !!d.data),
      startDate,
      endDate,
      t,
      IMAGE_DIMENSIONS,
      options,
      images,
    ).then(() => {
      setShowLoader(false);
      reportModalCleanup();
    });
  }

  useEffect(() => {
    const { include_alert_notes, include_alert_protocol, include_alert_history, include_graph } = preset ?? {};
    setOptions({ include_graph, include_alert_notes, include_alert_history, include_alert_protocol });
    exportForm.setValues({
      filename: preset?.filename,
      fileFormat: preset?.fileType ?? "xlsx",
      lotCode: preset?.lotCode,
      productCode: preset?.productCode,
      average: preset?.average?.toString(),
      interval: preset?.interval ?? getIntervalOptions(t)[0].value,
      presetName: preset?.name,
    });

    setSelectedSensors(
      deviceQuery.data
        ?.map((d) => d.Sensors.map((s) => ({ ...s, Device: d })))
        .flat()
        .filter((s) => preset?.sensorIds.some((id) => id === s._id))
        .map((sensor) => ({ sensor })) ?? [],
    );
    setLocation(locations.find((l) => l._id === preset?.LocationId));

    setMode(preset?.mode ?? ReportMode.ALERTS);
  }, [preset]);

  const { t } = useTranslation(["export", "common"]);

  const validationSchema = yup.object({
    lotCode: yup.string(),
    filename: yup.string(),
    fileFormat: yup.string(),
    interval: yup.number(),
    average: yup.number(),
    saveAsPreset: yup.boolean(),
    presetName: yup
      .string()
      .nullable(true)
      .test("required", t(ValidationMessages.REQUIRED), (value, obj) => (obj.parent.saveAsPreset ? !!value : true)),
  });

  const exportForm = useFormik<IReportExportOptions>({
    initialValues: { fileFormat: "xlsx", interval: getIntervalOptions(t)[0].value },
    validationSchema,
    onSubmit: () => {},
  });

  const steps = useMemo(() => {
    const step = (key: string, labelClass?: string) => ({ key, label: t(`export:steps.${key}`), labelClass });
    const steps = [step("type")];

    if (mode === ReportMode.TEMPLATE || (!editPreset && preset && mode !== ReportMode.POINTS_OF_INTEREST)) {
      steps.push(step("preset"), step("date_range"), step("export"));
      return steps;
    } else if (!editPreset && preset && mode === ReportMode.POINTS_OF_INTEREST) {
      steps.push(step("preset"), step("date_range"), step("points_of_interest", "poi-step-label-class"), step("export"));
      return steps;
    }
    if (editPreset) {
      steps.push(step("preset"));
    }

    steps.push(...["date_range", "location"].map((key) => step(key)));
    steps.push(...["sensors", "optional"].map((key) => step(key, "two-line-steps-label-class")));

    if (mode === ReportMode.POINTS_OF_INTEREST) {
      steps.push(step("points_of_interest", "poi-step-label-class"));
    }

    steps.push(step("export"));

    return steps;
  }, [mode, preset, editPreset]);

  const nextStepDisabled = useMemo(() => {
    switch (steps[step].key) {
      case "sensors":
        return !selectedSensors.length;
      case "date_range":
        return mode === ReportMode.POINTS_OF_INTEREST && moment(endDate).diff(startDate, "day") > 1;
      case "location":
        return !location;
      default:
        return false;
    }
  }, [step, startDate, endDate, location, selectedSensors, preset, editPreset]);

  const getStep = () => {
    switch (steps[step].key) {
      case "type":
        return <ReportTypePicker mode={mode} changeType={setMode} />;
      case "date_range":
        return (
          <ReportRangePicker mode={mode} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
        );
      case "location":
        return (
          <ReportLocationPicker
            availableLocations={locations}
            location={location}
            changeLocation={(location) => {
              setLocation(location);
              setSelectedSensors([]);
            }}
          />
        );
      case "sensors":
        return (
          <ReportSensorPicker
            devices={deviceQuery.data ?? []}
            location={location!}
            sensors={selectedSensors}
            changeSensors={setSelectedSensors}
          />
        );
      case "optional":
        return <ReportOptions changeOptions={setOptions} options={options} mode={mode} />;
      case "points_of_interest":
        return (
          <PointsOfInterestSetup
            minDate={startDate}
            maxDate={endDate}
            selectedSensors={selectedSensors}
            saveSelectedSensors={setSelectedSensors}
          />
        );
      case "preset":
        return (
          <PresetPicker
            preset={preset}
            handleSelectPreset={(p, edit) => {
              setPreset(p);
              setEditPreset(edit ?? false);
            }}
          />
        );
      case "export":
        return (
          <ReportExport preset={preset} editPreset={editPreset} formConfig={exportForm} onSubmit={exportForm.handleSubmit} mode={mode} />
        );
    }
  };

  const onCancel = () => {
    showAppModal(
      <ConfirmModal
        onCancel={() => showAppModal(<DeviceReportModal />)}
        header={t("export:cancel_title")}
        confirmText={t("export:cancel_title")}
        onConfirm={() => {
          reportModalCleanup();
          showAppModal(null);
        }}>
        {t("export:cancel_content")}
      </ConfirmModal>,
    );
  };

  const getSensorData = () => {
    console.warn("selectedSensors", selectedSensors);
    if (!selectedSensors) {
      return [] as IChartDataProps[];
    }

    const promises: Promise<IChartDataProps>[] = [];
    selectedSensors.forEach((sensor) => {
      const device = sensor.sensor.Device;

      const { convertToRh, convertToTemp } = getConverts(device!);

      promises.push(
        getDeviceSensorData(
          sensor.sensor,
          startDate.toISOString(),
          endDate.toISOString(),
          true,
          exportForm.values.average ? `${exportForm.values.average}h` : undefined,
        ).then((r) => {
          const processed = prepareChartDataSet(r, sensor.sensor, sensor.sensor.is_imperial, convertToRh, convertToTemp);
          return { ...sensor, data: processed } as IChartDataProps;
        }),
      );
    });
    return Promise.all(promises).catch((e) => {
      const errorMessage = t("export:load_error");
      showSnackbar(errorMessage, "error");
      console.log(errorMessage, e);
    });
  };

  const exportData = async () => {
    setShowLoader(true);
    const chartsData = await getSensorData();

    if (!chartsData) {
      return;
    }

    setSelectedSensors(chartsData);
    if (options.include_graph && exportForm.values.fileFormat === "pdf") {
      setRenderGraphs(true);
    } else {
      if (exportForm.values.saveAsPreset) {
        await savePreset();
      }
      await saveReports(
        exportForm.values,
        chartsData.filter((d) => !!d.data),
        startDate,
        endDate,
        t,
        IMAGE_DIMENSIONS,
        options,
      );
      reportModalCleanup();
      setShowLoader(false);
    }
  };

  const setRendered = (id: number) => {
    if (!renderedIds.includes(id)) {
      setRenderedIds([...renderedIds, id]);
    }
  };

  const savePreset = async () => {
    const { average, presetName, interval, filename, fileFormat, lotCode, productCode } = exportForm.values;
    const body = {
      _id: preset?._id,
      name: presetName || "",
      UserId: AppState.user?._id!,
      sensorIds: selectedSensors.map((s) => s.sensor._id),
      mode: mode,
      average: Number(average),
      interval,
      filename,
      fileType: fileFormat,
      lotCode,
      productCode,
      LocationId: location?._id,
      ...options,
    };
    preset?._id ? await updatePreset(body) : await addPreset(body);
  };

  return (
    <Modal
      bodyClassName={"device-report-modal-body"}
      className="modal-lg full"
      buttons={
        <div className="report-footer-buttons">
          <div className="report-footer-buttons-main">
            <button
              className={classNames("btn", step === 0 ? "btn-info" : "btn-plain u-text-teal u-mobile-hide")}
              onClick={() => onCancel()}>
              {t("common:close")}
            </button>

            {step > 0 ? (
              <button className="btn btn-info" disabled={showLoader} onClick={() => setStep(step - 1)}>
                {t("common:back")}
              </button>
            ) : null}

            {step < steps.length - 1 ? (
              <button className="btn btn-primary" disabled={showLoader || nextStepDisabled} onClick={() => setStep(step + 1)}>
                {t("common:next")}
              </button>
            ) : null}

            {step === steps.length - 1 ? (
              <button className="btn btn-primary" onClick={() => exportData()} disabled={showLoader}>
                {showLoader ? <i className="fa fa-spinner fa-spin" /> : t("common:save")}
              </button>
            ) : null}
          </div>
          {step > 1 ? (
            <button type="button" className="btn btn-info btn-plain u-text-teal u-mobile-only" onClick={() => onCancel()}>
              {t("common:close")}
            </button>
          ) : null}
        </div>
      }
      title={
        <>
          <span className="pull-left modal-title">{t("export:export_title")}</span>
          <p className="pull-right">
            {t("export:step_of", {
              currentStep: step + 1,
              totalSteps: steps.length,
            })}
          </p>
        </>
      }>
      <Stepper activeStep={step} className="device-report-stepper" orientation={"horizontal"} steps={steps}>
        {getStep()}
      </Stepper>
      {renderGraphs &&
        selectedSensors.map((item, i) => {
          if (!renderedIds.includes(item.sensor._id)) {
            return (
              <div
                key={item.sensor._id}
                style={{
                  height: IMAGE_DIMENSIONS.height * 2,
                  position: "fixed",
                  width: IMAGE_DIMENSIONS.width * 2,
                  left: -9000 - 400 * i,
                }}>
                <div className="graph-holder">
                  <TimeSeriesLineChart
                    height={IMAGE_DIMENSIONS.height * 2}
                    width={IMAGE_DIMENSIONS.width * 2}
                    data={item.data ?? []}
                    bindToSuffix={`${item.sensor._id}`}
                    setPoints={item.setPoints}
                    onExport={(imgData) => {
                      images[item.sensor._id] = imgData;
                      setImages(images);
                      setGraphReadyToExport(true);
                    }}
                    title={t(`sensor_types:${getNameSlug(item.sensor.Sensor_type.name)}`)}
                    onRendered={() => setRendered(item.sensor._id)}
                  />
                </div>
              </div>
            );
          }
        })}
    </Modal>
  );
};
