import { useQuery } from "react-query";
import { Endpoint } from "./API";
import { IPermission, IUser } from "./Types";

const WAM_TYPE = process.env.WAM_TYPE || "";
const QK_PERMISSIONS = ["PERMISSIONS"];
const DEFAULT_PERMISSIONS = [
  { name: "editLocation", label: "Create, Edit & Remove Locations" },
  { name: "editAlerts", label: "Create, Edit & Remove Alerts" },
  { name: "editUsers", label: "Create, Edit & Remove Users" },
  { name: "editAlertRecipient", label: "Edit Alert Recipient" },
  { name: "editDeviceGroup", label: "Create, Edit & Remove Device Groups" },
] as IPermission[];

export const getPermissions = () => Endpoint.get<IPermission[]>("api/permissions").then((r) => r.data);

export const usePermissions = () => useQuery(QK_PERMISSIONS, getPermissions, { placeholderData: DEFAULT_PERMISSIONS });

export const hasPermission = (user: IUser, permission: string) => {
  if (!user || !user.Permissions) {
    return false;
  }

  return user.role.toLowerCase() === "admin" || user.Permissions.findIndex((p) => p.name === permission) > -1;
};

export const isLocalWAM = () => WAM_TYPE && WAM_TYPE === "local";
console.log("WAM TYPE", WAM_TYPE);
export const isMobile = () => {
  const width = window.innerWidth;
  // const width = window.innerWidth > 0 ? window.innerWidth : screen.width;

  if (window.matchMedia) {
    return window.matchMedia("(max-device-width: 768px)").matches;
  } else {
    return width <= 768;
  }
};
