import { IAlertConfig, ISensorType, IUserAlert } from "./Alert.model";
import { Endpoint, invalidateQuery } from "./API";
import { IAlert } from "./Types";
import { useQuery } from "react-query";
import { t } from "i18next";

const ALERT_PATH = "api/alerts";
const QK_ALERTCONFIGS = ["ALERTCONFIGS"];

export const getAlertConfigs = () => Endpoint.get<IAlertConfig[]>(`${ALERT_PATH}?devices=true`).then((r) => r.data);

export const useAlertConfigs = () => useQuery(QK_ALERTCONFIGS, getAlertConfigs, { refetchInterval: 30000 });

export const createAlertConfig = (alert: IAlertConfig) =>
  Endpoint.post(`${ALERT_PATH}`, alert).then((r) => {
    invalidateQuery(QK_ALERTCONFIGS);
    return r.data;
  });

export const deleteAlertConfig = (alertConfigId: number) =>
  Endpoint.delete(`${ALERT_PATH}/${alertConfigId}`).then((r) => {
    invalidateQuery(QK_ALERTCONFIGS);
    return r.data;
  });

export const updateAlertConfig = (alert: IAlertConfig) =>
  Endpoint.put(`${ALERT_PATH}/${alert._id}`, alert).then((r) => {
    invalidateQuery(QK_ALERTCONFIGS);
    return r.data;
  });

export const selectAlertConfig = (alert: IAlertConfig) =>
  Endpoint.get<IAlertConfig>(`${ALERT_PATH}/${alert._id}?devices=true`).then((r) => {
    invalidateQuery(QK_ALERTCONFIGS);
    return r.data;
  });

export const getDefaultConfig = () => {
  return {
    _id: 0,
    name: "",
    delay_seconds: 2 * 60,
    interval_seconds: 10 * 60,
    snooze_seconds: 2 * 60,
    protocol: "",
    threshold: 0,
    min: 0,
    max: 100,
    is_managed: false,
    is_configurable: false,
    SensorTypeId: 0,
    selected_units: "",
  } as IAlertConfig;
};

export const getSensorTypes = () => Endpoint.get("api/sensor_types").then((r) => r.data);

export const useSensorTypes = () => useQuery<ISensorType[]>(["SENSORTYPES"], getSensorTypes);

export const getAlertTypesForSelect = (sensorType: ISensorType) => {
  if (!sensorType || !sensorType.type) {
    return [];
  }
  if (sensorType.type.toLowerCase() === "range") {
    return [
      { type: "range", label: t("common:select_options.range") },
      { type: "max", label: t("common:select_options.above_value") },
      { type: "min", label: t("common:select_options.below_value") },
    ];
  } else if (sensorType.type.toLowerCase() === "boolean") {
    let ranges = sensorType.ranges;
    let key = Object.keys(ranges)[0];

    let alertType = ranges[key].map((range, index) => {
      let suffix = "";
      if (typeof range === "string") {
        suffix = range.toLowerCase();
      }
      return { type: index, label: t(`common:select_options.${suffix}`) };
    });

    return [...alertType, { type: "transmitting", label: t("common:select_options.not_transmitting") }];
  }
};

export const editRecipient = (alertConfigId: number, userAlertSetting: IUserAlert) =>
  Endpoint.put(`${ALERT_PATH}/${alertConfigId}/users/${userAlertSetting.UserId}`, userAlertSetting).then((r) => {
    invalidateQuery(QK_ALERTCONFIGS);
    return r.data;
  });

export const deleteRecipient = (alertConfigId: number, recipientId: number) =>
  Endpoint.delete(`${ALERT_PATH}/${alertConfigId}/users/${recipientId}`).then((r) => {
    invalidateQuery(QK_ALERTCONFIGS);
    return r.data;
  });

export const addRecipients = (selectedAlert: IAlertConfig, recipients: IUserAlert[]) =>
  Endpoint.post(`${ALERT_PATH}/${selectedAlert._id}/users`, recipients).then((r) => {
    invalidateQuery(QK_ALERTCONFIGS);
    return r.data;
  });

export const getDisplayAlertType = (
  alertConfig: IAlertConfig | undefined | null,
):
  | {
      type: string | number;
      label: string;
    }
  | undefined => {
  let alertType: string | number;

  if (!alertConfig || alertConfig._id === undefined) return { type: "", label: "" };

  if (alertConfig.is_notify_on_non_transmitting) {
    alertType = "transmitting";
  } else if (alertConfig.Sensor_type && alertConfig.Sensor_type.type === "BOOLEAN") {
    if (alertConfig.min === 0) {
      alertType = 0;
    } else if (alertConfig.max === 1) {
      alertType = 1;
    }
  } else if (alertConfig.Sensor_type && alertConfig.Sensor_type.type === "RANGE") {
    if (alertConfig.min === null) {
      alertType = "max";
    } else if (alertConfig.max === null) {
      alertType = "min";
    } else {
      alertType = "range";
    }
  }

  if (!alertConfig.Sensor_type) {
    return;
  }

  return alertConfig.Sensor_type
    ? (getAlertTypesForSelect(alertConfig.Sensor_type) || []).filter((type) => type.type === alertType)[0]
    : undefined;
};

export const setDefaultAlertValues = (storedValues: any) => Endpoint.post(`api/stored_values`, storedValues);

export const isGenericAlert = (alert: IAlert | IAlertConfig) =>
  ["not-transmitting-alert", "service-low-battery-alert", "service-not-transmitting-alert"].some((genericName) =>
    alert.name.includes(genericName),
  );

export const isBatteryLowAlert = (alert: IAlert | IAlertConfig) =>
  ["service-low-battery-alert"].some((genericName) => alert.name.includes(genericName));

export const getDisplayAlertName = (alert: IAlert | IAlertConfig): string => {
  if (alert.name.includes("not-transmitting-alert")) {
    return "";
  }

  if (alert.name.includes("service-low-battery-alert")) {
    return t("notification_dropdown:battery_is_low");
  }

  return alert.name || "";
};
