import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Page, Wrapper } from "../../Components";
import { Box } from "@mui/material";
import { DeviceFirmwareUpload } from "./DeviceFirmwareUpload";
import { GatewayFirmwareUpload } from "./GatewayFirmwareUpload";
import { UploadLeftRail } from "./UploadLeftRail";
import { FirmwareUploadPageHeader } from "./FirmwareUploadPageHeader";
import { useSearchParams } from "react-router-dom";

export enum FirmwareTypeEnum {
  GATEWAY = "gateway",
  DEVICE = "device",
}

export const FirmwareUploadPage: React.FC = observer(() => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const firmwareType = searchParams.get("type");

  return (
    <Page>
      <Wrapper>
        <UploadLeftRail
          firmwareType={firmwareType as FirmwareTypeEnum}
          setSearchParams={setSearchParams}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />

        <main className="bapi-main mod-dashboard">
          <FirmwareUploadPageHeader setDrawerOpen={setDrawerOpen} />

          <Box padding="1em">
            {firmwareType === "gateway" && <GatewayFirmwareUpload />}
            {firmwareType === "device" && <DeviceFirmwareUpload />}
          </Box>
        </main>
      </Wrapper>
    </Page>
  );
});
