import classNames from "classnames";
import { checkForWai418Devices, mySearch } from "../../Managers";
import { IDevice } from "../../Managers/Types";
import { NoResults } from "../../Components";
import { DeviceCard } from "./DeviceCard";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { DeviceSortEnum, OrderBy } from "../../Enums";

interface IDashboardContent {
  forceRefreshDevices: (newSortDirection?: OrderBy, newSortProperty?: DeviceSortEnum) => void;
  deviceList: IDevice[];
  searchString: string;
  loadMoreDevices: () => void;
  showLoadButton: boolean;
}

export const DashboardContent: React.FC<IDashboardContent> = observer(
  ({ forceRefreshDevices, deviceList, searchString, loadMoreDevices, showLoadButton }) => {
    const { t } = useTranslation(["dashboard"]);

    const searchResult = mySearch<IDevice>(deviceList, searchString);

    if (deviceList?.length > 0 && searchString && searchResult?.length === 0) {
      return (
        <div className={classNames("main-panel", "extended")}>
          <NoResults />
        </div>
      );
    }

    return (
      <div className={classNames("main-panel", "extended")}>
        <div style={{ display: "flex", flexWrap: "wrap", gap: 15 }}>
          {searchResult.map((device, index) => (
            <DeviceCard
              key={`${device._id}-${index}`}
              isWai418={checkForWai418Devices(device.serial_number)}
              cardDevice={device}
              refresh={forceRefreshDevices}
            />
          ))}
        </div>

        {showLoadButton && (
          <div className="load-more-button">
            <button className="btn btn-primary load-more-button" onClick={() => loadMoreDevices()}>
              {t("dashboard:load_more")}
            </button>
          </div>
        )}
      </div>
    );
  },
);
