import React, { useState } from "react";
import { useField } from "formik";
import classNames from "classnames";
import { IStandardInput } from "./Types";

export const FormFieldTextArea: React.FC<IStandardInput> = ({
  className = "",
  name,
  label,
  placeholder = "",
  required = false,
  readOnly = false,
  disabled,
  onChange,
  initialValue = "",
}) => {
  const [field, meta] = useField(name);
  const [value, setValue] = useState(initialValue);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    field.onChange(e);
    onChange && onChange(e);
  };

  return (
    <div className={classNames("form-group", className)}>
      <label htmlFor={name} className="input-label u-display-block">
        {label}
      </label>
      <div className={classNames("input-holder mod-block", { error: meta.touched && Boolean(meta.error) })}>
        <textarea
          id={name}
          disabled={disabled}
          required={required}
          readOnly={readOnly}
          value={field.value}
          style={{ backgroundColor: "#042b4a" }}
          onChange={handleInputChange}
          onBlur={field.onBlur}
          className={"input input-line"}
          placeholder={placeholder}
          name={field.name}
          data-lpignore="true"
          rows={6}
        />
      </div>
    </div>
  );
};
