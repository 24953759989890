import { DeviceReportModal, IChartDataProps } from "./DeviceReportModal";
import { Table, TableBody, TableCell, TableHead, TableRow } from "../../Components/Table";
import { Modal } from "../../Components/Modal";
import { useTranslation } from "react-i18next";
import { getUserDateFormat, showAppModal } from "../../AppState";
import { formatDateCustom } from "../../Managers";
import React from "react";

export const PointOfInterestTableModal: React.FC<{ sensors: IChartDataProps[] }> = ({ sensors }) => {
  const { t } = useTranslation(["common", "export"]);

  return (
    <Modal
      title={t("export:all_points")}
      buttons={
        <button className="btn btn-primary" onClick={() => showAppModal(<DeviceReportModal />)}>
          {t("common:back")}
        </button>
      }>
      <div className="export-modal-table-wrapper">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("export:sensor_type")}</TableCell>
              <TableCell>{t("export:device_name")}</TableCell>
              <TableCell>{t("export:points_number")}</TableCell>
              <TableCell>{t("export:points_range")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sensors
              .filter((sensor) => sensor.setPoints?.length)
              .map((sensor) => (
                <TableRow>
                  <TableCell>{sensor.sensor.Sensor_type?.name}</TableCell>
                  <TableCell>{sensor.sensor.Device?.name}</TableCell>
                  <TableCell>{sensor.setPoints?.length}</TableCell>
                  <TableCell>
                    {sensor.setPoints!.length > 1 ? (
                      <>
                        <span>{t("common:from")}</span> {formatDateCustom(sensor.setPoints![0].x, getUserDateFormat())}
                        <br />
                        <span>{t("common:to")}</span>{" "}
                        {formatDateCustom(sensor.setPoints![sensor.setPoints!.length - 1].x, getUserDateFormat())}
                      </>
                    ) : (
                      formatDateCustom(sensor.setPoints![0].x, getUserDateFormat())
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </Modal>
  );
};
