import { IGateway } from "./Types";
import { Endpoint, invalidateQuery } from "./API";
import { QK_LOCATIONS } from "./LocationService";
import { AppState, setSelectedLocation } from "../AppState";

const API_PATH = "api/gateways";

export const getGateways = () => Endpoint.get(`${API_PATH}`).then((r) => r.data);
// this._store.dispatch({ type: 'GET_GATEWAYS', payload });

// TODO: Invalidate the GATEWAYS and DEVICES queries
export const addGateway = (gateway: IGateway) =>
  Endpoint.post(`${API_PATH}`, gateway).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

export const replaceGateway = (gateway: IGateway, replacementSerialNumber: string, isMigrateData: boolean) =>
  Endpoint.put(`${API_PATH}/${gateway._id}/replace`, {
    replacement_serial_number: replacementSerialNumber,
    migrateMetaData: isMigrateData,
  }).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });

// TODO: Invalidate the GATEWAYS query
export const removeGateway = (gateway: IGateway) =>
  Endpoint.delete(`${API_PATH}/${gateway._id}`).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    if (AppState.selectedLocation) {
      setSelectedLocation(AppState.selectedLocation);
    }
    return r.data;
  });

// TODO: Invalidate the GATEWAYS query
export const editGateway = (gateway: IGateway) =>
  Endpoint.put(`${API_PATH}/${gateway._id}`, gateway).then((r) => {
    invalidateQuery(QK_LOCATIONS);
    return r.data;
  });
