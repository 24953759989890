export const URLS = {
  Accounts: "/accounts",
  Alerts: "/alerts",
  AlertsHistory: "/alerts-history",
  Dashboard: "/",
  ExportUsers: "/users/export",
  FinalizeRegistration: "/finalize-registration",
  ForgotPassword: "/auth/forget-password",
  LegalManagement: "/legal-management",
  Login: "/auth",
  PrivacyPolicy: "/privacy-policy/:country",
  Register: "/auth/register",
  RegisterConfirm: "/register-confirmed",
  SubscriptionIsExpired: "/subscription-expired",
  SubscriptionManagement: "/subscription-management",
  SupplementalPrivacyPolicy: "/supplemental-privacy-policy/:country",
  TermsAndPolicy: "/tos-and-policy",
  TermsOfService: "/terms-of-service/:country",
  Translations: "/translations",
  Users: "/users",
  FirmwareUpload: "/firmware-upload",
  Watchlists: "/watchlists",
};
