import { observer } from "mobx-react-lite";
import { FiltersIcon } from "../../icon";
import React, { useEffect } from "react";
import { AppState, showChipModal, setChipModalConfig } from "../../AppState";
import { IDeviceType } from "../../Managers/Types";
import classNames from "classnames";
import { DeviceSortEnum, ISort } from "../../Enums";
import { useDeviceTypeGroups } from "../../Managers";
import { DashboardFilters } from "./DashboardFilters";
import { ISensorType } from "../../Managers/Alert.model";
import { IDeviceFilter } from "../../Managers/LocationService";

export interface IMobileDashboardFilters {
  deviceTypes: IDeviceType[];
  sort: ISort<DeviceSortEnum>;
  setSort: (sort: ISort<DeviceSortEnum>) => void;
  deviceFilters: IDeviceFilter;
  setDeviceFilters: React.Dispatch<React.SetStateAction<IDeviceFilter>>;
  sensorTypes: ISensorType[];
}

export const MobileDashboardFilters: React.FC<IMobileDashboardFilters> = observer(({ ...rest }) => {
  const deviceTypeGroupsQuery = useDeviceTypeGroups([AppState.selectedGateway?._id!], AppState.selectedLocation?._id);

  useEffect(() => {
    showChipModal(<DashboardFilters enclosureTypes={deviceTypeGroupsQuery.data ?? []} {...rest} />, { hidden: true });
  }, []);

  return (
    <button
      key={deviceTypeGroupsQuery.data?.length}
      className={classNames("btn btn-icon u-text-teal")}
      onClick={() => setChipModalConfig(null)}>
      <FiltersIcon />
    </button>
  );
});
