import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import "./AccordionRow.scss";
import { ExpandIcon } from "../icon";
import classNames from "classnames";

type AccordionRowProps = {
  className?: string;
  style?: React.CSSProperties;
  title: React.ReactNode;
  body: React.ReactNode;
};

const AccordionRow: React.FC<AccordionRowProps> = ({ title, body, className, style }) => {
  return (
    <Accordion className={classNames(className)} style={style}>
      <AccordionSummary aria-controls="panel-content" id="panel-header" expandIcon={<ExpandIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{body}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionRow;
