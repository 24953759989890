import classNames from "classnames";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { HeaderRow, SwitchInput, useScreenMode } from "../../Components";
import { EditGatewayModal } from "./Modals/EditGatewayModal";
import { EditDeviceGroupModal } from "./Modals/EditDeviceGroupModal";
import { RemoveGroupModal } from "./Modals/RemoveGroupModal";
import { DeviceSortEnum, ISort, WindowSize } from "../../Enums";
import { GroupConfig } from "./GroupConfig";
import { IDevice, IDeviceGroup, IDeviceTypeGroup, IGateway } from "../../Managers/Types";
import { useTranslation } from "react-i18next";
import { DrawerIcon, FiltersIcon } from "../../icon";
import { SearchRow } from "../../Components/SearchRow";
import { AppState, showAppModal } from "../../AppState";
import { useDeviceTypeGroups, useGatewaySensorTypes } from "../../Managers";
import { DashboardFilters } from "./DashboardFilters";
import { IDeviceFilter } from "../../Managers/LocationService";

interface IHeaderRowProps {
  name: string;
  count?: number;
  selectedGateway?: IGateway | null;
  selectGateway: (gateway: IGateway | null, clearFilters: boolean) => void;
  sort: ISort<DeviceSortEnum>;
  setSort: (sort: ISort<DeviceSortEnum>) => void;
  deviceList: IDevice[];
  selectGroup: (group: IDeviceGroup, clearFilters: boolean) => void;
  selectedGroup?: IDeviceGroup | null;
  searchString: string;
  setSearchString: (search: string) => void;
  setDrawerOpen: (open: boolean) => void;
  setFiltersOpen: (open: boolean) => void;
  filtersOpen: boolean;
  deviceFilters: IDeviceFilter;
  setDeviceFilters: Dispatch<SetStateAction<IDeviceFilter>>;
}

export const DashboardHeaderRow: React.FC<IHeaderRowProps> = observer(
  ({
    selectGroup,
    selectedGroup,
    deviceList,
    sort,
    setSort,
    selectedGateway,
    selectGateway,
    name,
    count,
    searchString,
    setSearchString,
    setDrawerOpen,
    setFiltersOpen,
    filtersOpen,
    deviceFilters,
    setDeviceFilters,
  }) => {
    const [searchRowOpen, setSearchRowOpen] = useState(false);
    const [enclosureTypes, setEnclosureTypes] = useState<IDeviceTypeGroup[]>([]);

    const { t } = useTranslation(["dashboard", "common"]);
    const sensorTypes = useGatewaySensorTypes(selectedGateway?._id, selectedGroup?._id);
    const mode = useScreenMode();

    const deviceTypeGroupsQuery = useDeviceTypeGroups(
      AppState.selectedGateway?._id ? [AppState.selectedGateway?._id] : undefined,
      AppState.selectedLocation?._id,
    );

    useEffect(() => {
      deviceTypeGroupsQuery.refetch();
    }, [AppState.selectedGateway, AppState.selectedLocation]);

    useEffect(() => {
      if (deviceTypeGroupsQuery.data) {
        setEnclosureTypes(deviceTypeGroupsQuery.data);
      }
    }, [deviceTypeGroupsQuery.dataUpdatedAt]);

    const getGatewayStatus = useCallback(() => {
      return selectedGateway && !selectedGateway.is_online;
    }, [selectedGateway]);

    const actions = selectedGateway
      ? [
          {
            action: () => showAppModal(<EditGatewayModal setGateway={selectGateway} gateway={selectedGateway} mode="edit" />),
            icon: "fa-edit",
          },
          {
            action: () => showAppModal(<EditGatewayModal setGateway={selectGateway} gateway={selectedGateway} mode="delete" />),
            icon: "fa-trash-o",
          },
        ]
      : selectedGroup
      ? [
          {
            action: () => showAppModal(<EditDeviceGroupModal setGroup={selectGroup} group={selectedGroup} />),
            icon: "fa-edit",
          },
          {
            action: () => showAppModal(<RemoveGroupModal selectedGroup={selectedGroup} />),
            icon: "fa-trash-o",
          },
        ]
      : [];

    return (
      <>
        <HeaderRow
          key={name}
          className="extended"
          info={{
            width: mode === WindowSize.TABLET ? "100%" : "",
            is_offline: getGatewayStatus(),
            name: (
              <>
                <button onClick={() => setDrawerOpen(true)} className="btn btn-icon u-tablet-only">
                  <DrawerIcon />
                </button>
                {name + " (" + count + ")"}
              </>
            ),
          }}
          control={
            mode !== WindowSize.MOBILE ? (
              <DashboardFilters
                key={sensorTypes.data?.length}
                sort={sort}
                setSort={setSort}
                enclosureTypes={enclosureTypes}
                deviceTypes={AppState.selectedLocation?.filterOptions?.deviceTypes ?? []}
                sensorTypes={sensorTypes.data ?? []}
                filtersOpen={filtersOpen}
                setFiltersOpen={setFiltersOpen}
                deviceFilters={deviceFilters}
                setDeviceFilters={setDeviceFilters}
              />
            ) : (
              <></>
            )
          }
          infoControl={
            <div className="header-row-info-control">
              {actions.map((item, index) => (
                <div key={index}>
                  <button className="btn btn-icon" onClick={item.action}>
                    <i className={classNames("fa header-icon", item.icon)} />
                  </button>
                </div>
              ))}

              <button className="btn u-text-teal u-desktop-hide btn-icon" onClick={() => setSearchRowOpen(!searchRowOpen)}>
                <i className="fa fa-search header-icon" />
              </button>

              {selectedGateway ? (
                <SwitchInput
                  className="switch-gateway-mode"
                  onChange={() =>
                    showAppModal(
                      <EditGatewayModal
                        setGateway={selectGateway}
                        gateway={selectedGateway}
                        mode={selectedGateway?.is_online ? "offline" : "online"}
                      />,
                    )
                  }
                  checked={selectedGateway.is_online}
                  name="online"
                />
              ) : null}
            </div>
          }
          infoSub={<GroupConfig />}
          mainRight={
            <div className="u-flex-center">
              {!!count && (
                <div
                  className="filters-mobile-icon"
                  onClick={() => {
                    setFiltersOpen(!filtersOpen);
                  }}>
                  <button className={classNames("btn btn-icon", filtersOpen ? "active" : "")}>
                    <FiltersIcon />
                  </button>
                  <p className="filter-label">{t("dashboard:filters")}</p>
                </div>
              )}
              <div className="input-holder u-desktop-only">
                <input
                  type="text"
                  className="input input-default"
                  placeholder={t("common:find")}
                  onChange={(e) => setSearchString(e.target.value)}
                  disabled={!deviceList.length}
                  value={searchString}
                />
                <i className="fa fa-search input-holder-icon" />
              </div>
            </div>
          }
        />
        <SearchRow
          className="u-desktop-hide"
          value={searchString}
          onChange={setSearchString}
          open={searchRowOpen}
          onClose={() => setSearchRowOpen(false)}
        />
      </>
    );
  },
);
