import React, { useEffect } from "react";
import { ILocation } from "../../Managers/Types";
import { useTranslation } from "react-i18next";
import { SelectInput } from "../../Components";

interface IReportDateRangeProps {
  location?: ILocation;
  changeLocation: (location: ILocation) => void;
  availableLocations: ILocation[];
}

export const ReportLocationPicker: React.FC<IReportDateRangeProps> = ({ availableLocations, location, changeLocation }) => {
  const { t } = useTranslation("export");

  useEffect(() => {
    if (!location) {
      changeLocation(availableLocations[0]);
    }
  }, [availableLocations]);

  return (
    <div>
      <label className="input-label">{t("common:location")}</label>
      {availableLocations.length || location ? (
        <SelectInput
          onChange={changeLocation}
          value={location ?? availableLocations?.[0]}
          options={availableLocations?.map((l) => ({ value: l, label: l.name })) ?? [location]}
        />
      ) : null}
    </div>
  );
};
