import { useParams } from "react-router-dom";
import { getLegal, useLegal } from "../../Managers";
import { LegalType } from "../../Enums";
import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { ILegal } from "../../Managers/Types";

export const PolicyWindow: React.FC<{ type: LegalType }> = ({ type }) => {
  const { country } = useParams();
  const legal = useLegal(country?.toUpperCase() ?? "US", type);
  const [defaultLegal, setDefaultLegal] = useState<ILegal>();

  useEffect(() => {
    if ((legal.error as AxiosError)?.response?.status === 404) {
      getLegal("US", type).then((res) => setDefaultLegal(res));
    }
  }, [legal.status]);

  return (
    <div
      className="ql-content-display"
      style={{ padding: "24px" }}
      dangerouslySetInnerHTML={{ __html: `${legal.data?.content ?? defaultLegal?.content ?? ""}` }}></div>
  );
};
