import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";
import { Colors, Vars } from "../Theme";
import * as React from "react";
import classNames from "classnames";

export const PlusButton = styled.button`
  border: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  color: black;
  min-width: 0;
  display: flex;
  outline: none;
  flex: 0 0 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 900;
  background: #00d1a4;
  border-radius: 14px;
  align-items: center;
  justify-content: center;
` as typeof Button;

export const ShowHideButton = styled.button`
  border: 0;
  padding: 0;
  width: 12px;
  height: 12px;
  color: black;
  min-width: 0;
  display: flex;
  outline: none;
  flex: 0 0 12px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 900;
  background: #ffffff;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
` as typeof Button;

export const SmallIconButton = styled.button`
  color: ${Colors.blkBlue};
  display: inline-block;
  margin-bottom: 0;
  width: 13px;
  height: 13px;
  font-weight: 900;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0;
  // padding: ${Vars.paddingBaseVertical} 8px;
  font-size: 12px;
  line-height: 12px;
  //font-size: 1em;
  //line-height: ${Vars.lineHeightBase};
  border-radius: ${Vars.btnBorderRadiusBase};
  user-select: none;
  letter-spacing: 1px;
  position: relative;

  &:focus {
    outline: none;
  }

  &.readonly {
    cursor: text;
    padding: 4px 6px;
    background-color: transparent;
    color: ${Colors.white};
    border-color: ${Colors.white};
    box-shadow: none;

    @include link-active {
      background-color: transparent !important;
      box-shadow: none !important;
    }
  }
`;

export const InlineButton = ({ className, children, onClick, ...props }: ButtonProps) => (
  <button {...props} onClick={onClick} className={classNames(className, "inline-button")}>
    {children}
  </button>
);
