import { action, observable } from "mobx";
import { IChartDataProps } from "./DeviceReportModal";
import { IExportPreset, ILocation } from "../../Managers/Types";
import moment from "moment";
import { IReportOptions } from "./ReportOptions";

export enum ReportMode {
  ALERTS = "alerts",
  AVERAGE = "average",
  POINTS_OF_INTEREST = "points_of_interest",
  INTERVAL = "interval",
  TEMPLATE = "template",
}

export interface IReportModalStateProps {
  endDate: Date;
  location?: ILocation;
  mode: ReportMode;
  options: IReportOptions;
  preset?: IExportPreset;
  editPreset: boolean;
  startDate: Date;
  step: number;
  sensors: IChartDataProps[];
}

const baseState = {
  endDate: moment().toDate(),
  mode: ReportMode.ALERTS,
  options: {},
  editPreset: false,
  sensors: [],
  startDate: moment().subtract(1, "day").toDate(),
  step: 0,
};

export const ReportModalState: IReportModalStateProps = observable(baseState);

export const reportModalCleanup = action(() => {
  const currentState = {} as IReportModalStateProps;
  Object.keys(ReportModalState).forEach((k) => (currentState[k as keyof IReportModalStateProps] = undefined as never));
  updateReportModalState({ ...currentState, ...baseState });
});

export const updateReportModalState = action((obj: IReportModalStateProps) => {
  Object.entries(obj).forEach(([key, value]) => (ReportModalState[key as keyof IReportModalStateProps] = value as never));
});
