import React from 'react';
import {useField} from 'formik';
import classNames from 'classnames';
import {DatepickerDropdown} from './DatepickerDropdown';

export interface IDatePickerProps {
  name: string;
  id?: string;
  label?: string;
  type?: string;
  className?: string;
  placeholder?: string;
  dark?: boolean;
  disabled?: boolean;
  hasTimeSelector?: boolean;
  required?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  autoComplete?: string;
  readOnly?: boolean;
  onKeyDown?: (e: any) => void;
  onChange?: (e: any) => void;
}

export const FormFieldDate: React.FC<IDatePickerProps> = ({className = '', name, label, minDate, maxDate, hasTimeSelector, onChange}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <div className={classNames('form-group', className)}>
      <label htmlFor={name} className="input-label u-display-block">
        {label}
      </label>
      <div className={classNames('input-holder mod-block', {error: meta.touched && Boolean(meta.error)})}>
        <DatepickerDropdown
          // disabled={disabled}
          // required={required}
          // readOnly={readOnly}
          selectedDate={field.value}
          hasTimeSelector={hasTimeSelector}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          onSelectDate={(e) => {
            //console.log('date selected', typeof e, e);
            helpers.setValue(e);
            // field.onChange(e);
            onChange?.(e);
          }}
          // onBlur={field.onBlur}
          // className={'input input-line'}
          // placeholder={placeholder}
          name={field.name}
          data-lpignore="true"
        />
      </div>
    </div>
  );
};
