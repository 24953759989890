import React from 'react';
import {ModalDialog, IModalOptions} from './ModalDialog';

const ModalServiceContext = React.createContext<(options: IModalOptions) => Promise<void>>(Promise.reject);

export const useModal = () => React.useContext(ModalServiceContext);

export const ModalServiceProvider: React.FC = ({children}) => {
  const [ModalState, setModalState] = React.useState<IModalOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openModal = (options: IModalOptions) => {
    setModalState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = {resolve, reject};
    });
  };

  const handleCancel = () => {
    awaitingPromiseRef.current?.reject();
    setModalState(null);
  };

  const handleOk = () => {
    awaitingPromiseRef.current?.resolve();
    setModalState(null);
  };

  return (
    <>
      <ModalServiceContext.Provider value={openModal} children={children} />

      <ModalDialog open={Boolean(ModalState)} onOk={handleOk} onCancel={handleCancel} {...(ModalState || {title: '', message: ''})} />
    </>
  );
};
