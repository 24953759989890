import { Card, Page, Stepper } from "../../Components";
import React from "react";
import "./Register.scss";
import { GetActiveSteps, RegistrationSteps } from "../../Enums";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { Colors } from "../../Theme";

export const RegisterCompleted = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["register", "common"]);

  return (
    <Page>
      <Card className="register-card" label={t("register:register_completed.title")}>
        <div className="header-absolute-title">{t("register:register_completed.title")}</div>
        <Stepper
          activeStep={RegistrationSteps.Confirmation}
          className="register-stepper"
          steps={GetActiveSteps()}
          sx={{
            "& .MuiStepLabel-root .MuiStepLabel-iconContainer > svg ": {
              fill: Colors.teal,
            },
          }}>
          <div className="register-success-wrapper">
            <CheckCircleIcon style={{ width: "64px", marginBottom: "-8px", color: Colors.teal }} />
            <h2 className="register-success-message">{t("common:success")}</h2>
            <span className="register-note">{t("register:register_completed.first_line")}</span>
            <span className="register-note">{t("register:register_completed.second_line")}</span>
            <div className="register-complete-button">
              <Button onClick={() => navigate("/auth")} variant="contained" className="sign-in-to-wam-button">
                {t("register:register_completed.sign_in")}
              </Button>
            </div>
          </div>
        </Stepper>
      </Card>
    </Page>
  );
};
