import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Colors, Vars } from "../Theme";

const Section = styled.section`
  padding: 16px 32px;
  text-align: center;
`;

const Header = styled.div`
  font-weight: ${Vars.boldFontWeight};
`;

const Explanation = styled.div`
  color: ${Colors.lightGray};
`;

export const NoResults: React.FC = observer(() => {
  const { t } = useTranslation("common");

  return (
    <Section>
      <Header>{t("common:no_results")}</Header>
      <Explanation>{t("common:no_results_hint")}</Explanation>
    </Section>
  );
});
