export const formatPhone = (value: string | any): string => {
  if (!value || typeof value !== 'string') return value;

  const pattern1 = /(\d{3})(\d{3})(\d{4})/;
  const pattern2 = /(\d{1})(\d{3})(\d{3})(\d{4})/;

  if (value.match(pattern1)) {
    return value.replace(pattern1, '$1-$2-$3');
  } else if (value.match(pattern2)) {
    return value.replace(pattern2, '+$1-($2)-$3-$4');
  }

  return value;
};
