import React, { FormEvent, useMemo } from "react";
import { CheckboxInput, SelectInput, TextInput } from "../../Components";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { getTransmitIntervalOptions } from "../../Managers";
import { IExportPreset } from "../../Managers/Types";
import { ReportMode } from "./ReportModalState";

export interface IReportExportOptions {
  average?: string;
  lotCode?: string;
  productCode?: string;
  interval?: number;
  filename?: string;
  fileFormat?: "pdf" | "xlsx";
  saveAsPreset?: boolean;
  presetName?: string;
}

interface IReportExportProps {
  onSubmit: (values: FormEvent<HTMLFormElement> | undefined) => void;
  formConfig: FormikProps<IReportExportOptions>;
  preset?: IExportPreset;
  editPreset?: boolean;
  mode: ReportMode;
}

export const ReportExport: React.FC<IReportExportProps> = ({ onSubmit, formConfig, preset, mode, editPreset }) => {
  const { t } = useTranslation("export");

  const intervalOptions = useMemo(() => getTransmitIntervalOptions(t), []);

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6">
          <TextInput
            value={formConfig.values.filename || ""}
            onChange={formConfig.handleChange}
            name="filename"
            label={t("export:file_name")}
            placeholder={t("export:file_name_placeholder")}
          />
        </div>
        <div className="col-md-6">
          <SelectInput
            value={formConfig.values.fileFormat}
            onChange={(value) => formConfig.setFieldValue("fileFormat", value)}
            options={[
              { value: "pdf", label: "PDF" },
              { value: "xlsx", label: "XLSX" },
            ]}
            name="fileFormat"
            label={t("export:file_format")}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <TextInput
            value={formConfig.values.lotCode || ""}
            onChange={formConfig.handleChange}
            name="lotCode"
            label={t("export:lot_code")}
            placeholder={t("export:lot_code_placeholder")}
          />
        </div>
        <div className="col-md-6">
          <TextInput
            value={formConfig.values.productCode || ""}
            onChange={formConfig.handleChange}
            name="productCode"
            label={t("export:product_code")}
            placeholder={t("export:product_code_placeholder")}
          />
        </div>
      </div>

      <div className="row">
        {mode === ReportMode.INTERVAL ? (
          <div className="col-md-6">
            <SelectInput
              onChange={(value) => formConfig.setFieldValue("interval", value)}
              value={formConfig.values.interval}
              style={{ flex: 1 }}
              options={intervalOptions}
              name="interval"
              label={t("export:interval")}
            />
          </div>
        ) : null}

        {mode === ReportMode.AVERAGE ? (
          <div className="col-md-6">
            <SelectInput
              onChange={(value) => formConfig.setFieldValue("average", value)}
              value={formConfig.values.average}
              style={{ flex: 1 }}
              options={Array.from(Array(12)).map((_, i) => ({
                value: (i + 1).toString(),
                label: t("common:hours", { count: i + 1 }),
              }))}
              displayError={true}
              name="average"
              label={t("export:average")}
            />
          </div>
        ) : null}
      </div>
      {!preset || editPreset ? (
        <CheckboxInput
          value={formConfig.values.saveAsPreset}
          label={t("export:save_preset")}
          checked={formConfig.values.saveAsPreset || false}
          name="saveAsPreset"
          onChange={(e) => {
            formConfig.setFieldValue("saveAsPreset", e.target.checked);
          }}
        />
      ) : null}

      <div className="row">
        <div className="col-md-6">
          {formConfig.values.saveAsPreset ? (
            <TextInput
              onChange={formConfig.handleChange}
              value={formConfig.values.presetName || ""}
              hint={
                preset
                  ? {
                      message: t("export:overwrite_warning"),
                      className: "u-text-warning",
                    }
                  : undefined
              }
              name="presetName"
              displayError={true}
              placeholder={t("export:preset_name_placeholder")}
            />
          ) : null}
        </div>
      </div>
    </form>
  );
};
