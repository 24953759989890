import { Collapse } from "react-collapse";
import { FormFieldCheckbox, FormFieldSelect } from "../../Components";
import { UserRoles } from "../../Enums";
import { IPermission, ISystemMessage } from "../../Managers/Types";
import { getNameSlug, usePermissions } from "../../Managers";
import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FormikErrors, FormikValues } from "formik";
import { Stack } from "@mui/material";

interface IManageSystemMessagePermissions {
  systemMessage: ISystemMessage;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<FormikValues>>;
}

export const SystemMessagePermissions: React.FC<IManageSystemMessagePermissions> = observer(({ systemMessage, setFieldValue }) => {
  const { t } = useTranslation(["common", "system_message", "users"]);

  const [permissionsOpen, setPermissionsOpen] = useState(false);

  const permissions = usePermissions();
  const groupedPermissions = useMemo(
    () =>
      permissions.data?.reduce((response, permission) => {
        response[permission.access_group] = response[permission.access_group] || [];
        response[permission.access_group].push(permission);
        return response;
      }, {} as Record<string, IPermission[]>) ?? {},
    [permissions.dataUpdatedAt],
  );

  return (
    <Stack direction="column">
      <label className="input-label row" onClick={() => setPermissionsOpen(!permissionsOpen)}>
        {t("system_message:limitations")}
        <i className={classNames("fa fa-chevron-down fa-rotate u-text-small pull-right u-text-teal", { rotated: permissionsOpen })} />
      </label>
      <Collapse theme={{ collapse: "permissions-collapsible" }} isOpened={permissionsOpen}>
        <div>
          <FormFieldSelect
            options={[
              { value: undefined, label: t("common:select") },
              ...[UserRoles.USER, UserRoles.ADMIN, UserRoles.APP_ADMIN].map((role) => ({
                value: role,
                label: t(`common:${role.toLowerCase()}`),
              })),
            ]}
            label={t("system_message:role")}
            displayError={false}
            inputClassName="locations-select-mobile"
            name="role"
          />
          <label className="input-label">{t("system_message:permissions")}</label>
          {Object.keys(groupedPermissions).map((key) => {
            return (
              <>
                <div className="col col-xs-12 col-md-12 modal-row-title">{t("common:" + key)}</div>
                {groupedPermissions[key].map((permission: IPermission) => (
                  <div className="col col-xs-12 col-md-6 user-permission" key={permission._id}>
                    <FormFieldCheckbox
                      style={{ display: "flex" }}
                      name="permission"
                      label={t(`users:permission.${getNameSlug(permission.label)}`)}
                      checked={systemMessage.Permissions.some((perm) => perm._id === permission._id)}
                      onChange={(e) =>
                        setFieldValue(
                          "Permissions",
                          e.target.checked
                            ? [...systemMessage.Permissions, permission]
                            : systemMessage.Permissions.filter((perm) => perm._id !== permission._id),
                        )
                      }
                    />
                  </div>
                ))}
              </>
            );
          })}
        </div>
      </Collapse>
    </Stack>
  );
});
