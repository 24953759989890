export enum DeviceSortEnum {
  Name = "name",
  Updated = "last_report_time",
  Warning = "warning",
  Alert = "alert",
}

export type DeviceSort = DeviceSortEnum | string;

export enum OrderBy {
  ASC = "ASC",
  DESC = "DESC",
}

export interface ISort<T = string> {
  prop: T;
  dir: OrderBy;
}

export interface IMenuSort extends ISort {
  label: string;
}
