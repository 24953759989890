import { Endpoint } from "./API";
import {
  IMessageResponse,
  ISelectedPlanOption,
  ITemporaryUser,
  ITemporaryUserVerification,
  ITemporaryUserVerificationResendCode,
  IUserCreatingFromTemporary,
} from "./Types";

const API_PATH = "temporary_user";

export const CreateTemporaryUser = (user: ITemporaryUser) => Endpoint.post<string | undefined>(`${API_PATH}/create`, user);

export const VerifyEmail = (user: ITemporaryUserVerification) => Endpoint.post<IMessageResponse>(`${API_PATH}/verifyEmail`, user);

export const ResendCode = (user: ITemporaryUserVerificationResendCode) => Endpoint.post<IMessageResponse>(`${API_PATH}/resend`, user);

export const CreateUserFromTemporary = (user: IUserCreatingFromTemporary, selectedPlanOptions: ISelectedPlanOption[]) =>
  Endpoint.post<IMessageResponse>(`${API_PATH}/confirm`, {
    user,
    selectedPlanOptions,
  });
