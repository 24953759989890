import { IDeviceGroup } from './Types';
import { Endpoint, invalidateQuery } from './API';
import { useQuery } from 'react-query';
import { IAlertConfig } from './Alert.model';

const API_PATH = 'api/device_groups';
const QK_DEVICE_GROUPS = ['DEVICE_GROUPS'];

export const getDeviceGroups = (deep: boolean = false, locationId?: number) =>
  Endpoint.get<IDeviceGroup[]>(`${API_PATH}`, { params: { deep, locationId } }).then((r) => r.data);

export const useDeviceGroups = (deep: boolean, locationId: number) =>
  useQuery([...QK_DEVICE_GROUPS, locationId], () => getDeviceGroups(deep, locationId));

export const createGroup = (group: IDeviceGroup) =>
  Endpoint.post(`${API_PATH}`, group).then((r) => {
    invalidateQuery(QK_DEVICE_GROUPS);
    return r.data;
  });

export const updateGroup = (group: IDeviceGroup) =>
  Endpoint.put(`${API_PATH}/${group._id}`, group).then((r) => {
    invalidateQuery(QK_DEVICE_GROUPS);
    return r.data;
  });

export const deleteGroup = (group: IDeviceGroup) =>
  Endpoint.delete(`${API_PATH}/${group._id}`).then((r) => {
    invalidateQuery(QK_DEVICE_GROUPS);
    return r.data;
  });

export const createGroupConfig = (group: IDeviceGroup, config: any) =>
  Endpoint.post(`${API_PATH}/${group._id}/alert_configurations`, config).then((r) => {
    invalidateQuery(QK_DEVICE_GROUPS);
    return r.data;
  });

export const deleteAllGroupConfigs = (group: IDeviceGroup) =>
  Endpoint.delete(`${API_PATH}/${group._id}/alert_configurations`).then((r) => {
    invalidateQuery(QK_DEVICE_GROUPS);
    return r.data;
  });

export const deleteGroupConfig = (group: IDeviceGroup, config: IAlertConfig) =>
  Endpoint.delete(`${API_PATH}/${group._id}/alert_configurations/${config._id}`).then((r) => {
    invalidateQuery(QK_DEVICE_GROUPS);
    return r.data;
  });

export const addToDeviceGroup = (groupId: number, devices: any[]) =>
  Endpoint.post(`${API_PATH}/${groupId}/devices`, devices).then((r) => {
    invalidateQuery(QK_DEVICE_GROUPS);
    return r.data;
  });

export const deleteFromDeviceGroup = (groupId: number, devices: any[]) =>
  Endpoint.put(`${API_PATH}/${groupId}/devices`, devices).then((r) => {
    invalidateQuery(QK_DEVICE_GROUPS);
    return r.data;
  });
