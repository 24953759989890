import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { deleteGroup } from "../../../Managers/DeviceGroupService";
import { AppState, setSelectedLocation, showAppModal, showSnackbar } from "../../../AppState";
import { IDeviceGroup } from "../../../Managers/Types";
import { Modal } from "../../../Components/Modal";
import { useTranslation } from "react-i18next";

interface IAddToGroupProps {
  selectedGroup: IDeviceGroup;
}

export const RemoveGroupModal: React.FC<IAddToGroupProps> = observer(({ selectedGroup }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { t } = useTranslation(["dashboard", "common"]);

  const submitForm = () => {
    setIsSaving(true);

    deleteGroup(selectedGroup)
      .then(() => {
        showAppModal(null);
        showSnackbar(t("dashboard:remove_group.remove_success", { name: selectedGroup.name }), "success");

        if (AppState.selectedLocation) {
          setSelectedLocation(AppState.selectedLocation);
        }
      })
      .catch((e) => {
        console.error(e);
        showSnackbar(t("dashboard:remove_group.remove_error", { name: selectedGroup.name }), "error");
        setIsSaving(false);
      });
  };

  return (
    <Modal
      className="modal-sm"
      title={t("dashboard:remove_group.title")}
      buttons={
        <>
          <button className="btn btn-info" onClick={() => showAppModal(null)} disabled={isSaving}>
            {t("common:cancel")}
          </button>
          <button className="btn btn-primary" onClick={submitForm} disabled={isSaving || !selectedGroup}>
            {isSaving ? <i className="fa fa-spin fa-circle-o-notch" /> : <></>}
            <span>{t("common:continue")}</span>
          </button>
        </>
      }>
      {t("dashboard:remove_group.warning", { name: selectedGroup.name })}
    </Modal>
  );
});
