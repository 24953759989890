import { IDeviceSettings } from "./Types";
import { Endpoint } from "./API";
import { AppState } from "../AppState";
import { isUserRoleAllowed, UserRoles } from "../Enums";

const API_PATH = "api/device_settings";

export const updateDeviceSettings = async (deviceId: number, deviceSettings: IDeviceSettings) => {
  const skipLimits = isUserRoleAllowed(UserRoles.APP_ADMIN) || AppState.user?.Account?.is_demo;
  const transmissionRateMinutesLimit = AppState.user?.Limits?.limits.TRANSMISSION;
  const transmitIntervalSeconds = deviceSettings.settings_data.transmitInterval;
  if (skipLimits || (transmissionRateMinutesLimit && parseInt(transmitIntervalSeconds.toString()) >= transmissionRateMinutesLimit * 60)) {
    const payload = {
      deviceId,
      settings: {
        applied: deviceSettings.applied,
        settings_crc: deviceSettings.settings_crc,
        settings_data: deviceSettings.settings_data,
      },
      low_battery_alarm: deviceSettings.batteryAlarm,
      not_transmitting: deviceSettings.notTransmitting,
    };

    return Endpoint.post(`${API_PATH}`, payload);
  } else {
    throw new Error("Transmit interval is below the transmission rate limit.");
  }
};

export const transformSensorTypeId = (sensorTypeId: number) => {
  switch (sensorTypeId) {
    case 1:
    case 2:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
      return 1; //temperature
    case 35:
      return 2; // differential pressure
    case 3:
      return 3; // humidity
    case 8:
      return 4; // lightLevel
    case 7:
      return 5; // barometric pressure
    case 13:
      return 6; // CO2
    case 12:
      return 7; // VOC
    case 15:
      return 8; // NO2
    case 14:
      return 9; // CO
    case 40:
      return 10; // CO2E
    default:
      return -1; // Unknown?
  }
};
