import React from "react";

export const Flag: React.FC<{
  countryCode?: string;
}> = ({ countryCode }) => {
  return (
    <img
      style={{ marginRight: "4px" }}
      loading="lazy"
      width="20"
      alt=""
      src={`https://flagcdn.com/w20/${countryCode?.toLowerCase()}.png`}
    />
  );
};
